import axios from "axios"
import { getConfig, getHeader  } from "../config/config";


export async function insertUser(data: any, token: string) 
{

  try 
  {
    const response = await axios.post(
      getConfig().API_URL + "/insereUsuario",
      data,
      getHeader(true,`Bearer ${token}`)
    )
    return {success: true, message:response.data.usuarioInserido, result: ''};


  } 
  catch (error: any) 
  {
    console.log(error);
    if(error)
      return {success: false, message: error.response.data};
  }

  return {success:false, message: 'Erro desconhecido. Tente novamente mais tarde.', result: ''};

}
