import React, { useEffect, useState } from "react";
import { recoverPassword } from "../../services/recoverPassword";
import { showError, showWait, dismissToast, showSucess } from '../../services/toast';
import UseTermsFirstAccess from '../UseTermsFirstAccess';
import { changePass } from "../../services/changePass";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { resetPass } from "../../services/resetPass";
import { hasSelectionSupport } from "@testing-library/user-event/dist/utils";
import { firstAccess } from "../../services/firstAccess";
import { getUseTerm } from "../../services/getUseTerm";
import { TRUE } from "sass";

interface IDefinePassword {
  mode: string
  token: string
  msgTitulo: string
  cancel(): void
}


const DefinePassword: React.FC<IDefinePassword> = ({ mode, token, msgTitulo, cancel }) => {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [showUseTerms, setShowUseTerms] = useState(false);
  const navigate = useNavigate();
  const buttonSend = (mode == "first" ? "Criar" : "Salvar")
  const [useTerms, setUseTerms] = useState("");
  const [isShowIcon1, setIsSowIcon1] = useState(true)
  const [isShowIcon2, setIsSowIcon2] = useState(true)

  const [errorField, setErrorField] = useState({ old: false, current: false, new: false });
  const [errorFieldMessage, setErrorFieldMessage] = useState('')
  const [marginLeft, setMarginLeft] = useState(275);



  useEffect(() => {
    getUseTerm().then(
      (retorno) => {
        setUseTerms(retorno.result.termoUso)
      }
    )

    const handleResize = () => {
      const newMarginLeft = Math.min(Math.max(window.innerWidth - 1000, 220), 275);
      setMarginLeft(newMarginLeft);
    };

    window.addEventListener('resize', handleResize);

    // Set initial margin value
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  

  async function send() {


    if (password == "") {
      showError('Digite a senha.');
      setErrorField({ old: false, current: true, new: false });
      setErrorFieldMessage('');
      return;
    }

    if (oldPassword == password) {
      showError('A nova senha deve ser diferente da atual.');
      setErrorField({ old: false, current: true, new: false });
      setErrorFieldMessage('');
      return;
    }


    if (password != password2) {
      showError('A confirmação da senha não é igual a senha informada.');
      setErrorField({ old: false, current: true, new: true });
      setErrorFieldMessage('A confirmação da senha não é igual a senha informada.');
      return;
    }

    if (password.length > 15) {
      showError('A senha não pode ter mais de que 15 caracteres.');
      setErrorField({ old: false, current: true, new: false });
      setErrorFieldMessage('');
      return;
    }

    if (password.length < 6) {
      showError('A senha não pode ter menos de que 6 caracteres.');
      setErrorField({ old: false, current: true, new: false });
      setErrorFieldMessage('');
      return;
    }

    const globalRegex = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})", 'g');

    if (!globalRegex.test(password)) {
      showError('A senha deve conter de 6 a 15 caracteres, com números, letras maiúsculas, símbolos e sem espaços.');
      setErrorField({ old: false, current: true, new: false });
      setErrorFieldMessage('');
      return;
    }

    if (mode == "first") {
      setShowUseTerms(true);
    }
    else if (mode == "perfil") {
      cancel();

      let waitId = showWait('Aguarde, enviando dados.');

      let data =
      {
        senhaAntiga: oldPassword,
        novaSenha: password
      }

      const response: any = await changePass(data, token);

      dismissToast(waitId);

      if (!response.error) {
        showSucess(response.msg);
      }
      else {
        showError(response.msg);
      }

    } else if (mode == "reset") {
      let waitId = showWait();
      let retorno: any = await resetPass(token, password);
      dismissToast(waitId);

      if (retorno.sucesso) {
        showSucess(retorno.mensagem)
        setTimeout(function () {
          navigate('/');
        }, 5000);
      } else {
        showError(retorno.mensagem)
      }
    }

  }


  function dismiss() {
    if (mode == "first") {
      setShowUseTerms(false);
    }
  }


  function cancelButton() {
    if (mode == "first") {
      navigate('/home');
    } else if (mode == "perfil") {
      cancel();
    } else if (mode == "reset") {
      navigate('/home');
    } else {
      navigate('/home');
    }

  }

  function acceptTerm() {
    let waitId = showWait();
    firstAccess(password, token).then((retorno) => {
      dismissToast(waitId);

      if (retorno.sucesso) {
        showSucess(retorno.mensagem)
        setTimeout(function () {
          navigate('/');
        }, 4000);

      } else {
        showError(retorno.mensagem)
      }
    })
  }


  return (
    <>
      <div className="mx-2">

        {mode === 'perfil' &&
          <div className="form-group">
            <span className="form-label">Senha antiga</span>
            <input
              onChange={({ target }) => setOldPassword(target.value)}
              type="password"
              name="password"
              className={`form-control ${(errorField.current) ? 'input errorinput' : 'input'}`}
              style={{ width: '100%' }}
              maxLength={128}
            />

          </div>
        }
        <div className="">
          <div className="ms-webpart-zone ms-fullWidth">
            <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
              <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                <div className="ms-WPBody">
                  <div className="">
                    <div className="">
                      <div className="wpLogin">
                        <h6 className="title mb-4" style={{ whiteSpace: 'nowrap', marginTop: '0' }}>{msgTitulo}</h6>
                        <div className="borderBox">
                          <div className="form-group">
                            <span className="form-label">Senha</span>
                            <div style={{display:'flex', alignItems:'center'}}>
                            <input
                              onChange={({ target }) => setPassword(target.value)}
                              type= {isShowIcon1 ? 'text' : 'password'}
                              name="password"
                              style={{ width: '100%' }}
                              className={`form-control ${(errorField.current) ? 'input errorinput' : 'input'}`}
                              maxLength={128}
                            />

                            <span style={ {marginLeft:'-30px',cursor:'pointer'}}  onClick={() => { setIsSowIcon1(!isShowIcon1) }}>
                                {isShowIcon1 ? <IoIosEye /> : <IoIosEyeOff />}
                            </span>
                            </div>

                          </div>
                          <div className="form-group">
                            <span className="form-label">Confirmação</span>
                            <div style={{display:'flex', alignItems:'center'}} >
                              <input
                                onChange={({ target }) => setPassword2(target.value)}
                                type={isShowIcon2 ? 'text' : 'password'}
                                name="password"
                                style={{width: '100%' }}
                                className={`form-control ${(errorField.current) ? 'input errorinput' : 'input'}`}
                                maxLength={128}
                              />

                              <span style={ {marginLeft:'-30px',cursor:'pointer'}} onClick={() => { setIsSowIcon2(!isShowIcon2) }}>
                                  {isShowIcon2 ? <IoIosEye /> : <IoIosEyeOff />}
                              </span>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '28px' }}>
        <button className="btn-primary" type="button" value="Enviar" onClick={send}>Salvar</button>
        <button className="btn-primary-blue" type="button" onClick={cancelButton}>Cancelar</button>
      </div>
      <UseTermsFirstAccess display={showUseTerms} hideModal={() => dismiss()} accept={() => acceptTerm()} senha={password} token={token} termoUso={useTerms} />
    </>
  );
};

export default DefinePassword;