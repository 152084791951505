import axios from "axios"
import { getConfig } from "../config/config";


export async function consumptionCompanyPerDay( token: string,id: string, filterDate : string) : Promise<any>{

  try 
  {
    const response = await axios.get(
      getConfig().API_URL + `/consumoDiaPorEmpresa/${id}/${filterDate}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return {success: true, message:'', result: response.data};

  } 
  catch (error: any) 
  {
    if(error)
      return {success: false, message: error.message};
  }

  return {success:false, message: 'Erro desconhecido. Tente novamente mais tarde.', result: ''};

}