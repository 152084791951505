import axios from "axios"
import { getConfig, getHeader } from "../config/config";


export async function getUserGS1Companies(token: string, name : string) : Promise<any> {
  try 
  {
    const response = await axios.get(
      getConfig().API_URL + "/listagemEmpresas/" + name,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return {success: true, message:'', result: response.data.empresas};

  } catch (error: any) 
  {
    if(error)
      return {success: false, message: error.message};
  }

  return {success:false, message: 'Erro desconhecido. Tente novamente mais tarde.', result: ''};

}


export async function getUserGS1CompaniesById(token: string, id : string) : Promise<any> {
  try 
  {
    const response = await axios.get(
      getConfig().API_URL + "/empresaPorID/" + id,
      getHeader(true,`Bearer ${token}`)
    )

    return {success: true, message:'', result: response.data.empresas};

  } catch (error: any) 
  {
    if(error)
      return {success: false, message: error.message};
  }

  return {success:false, message: 'Erro desconhecido. Tente novamente mais tarde.', result: ''};

}
