import { ReactNode, useRef, useState } from "react"
import { BiChevronUp, BiChevronDown } from "react-icons/bi"
import { CSSTransition } from "react-transition-group"
import Loading from "../Loading";
import { paintStatus, traduzirStatus, traduzirTextoFuncao, traduzirTipoFuncao} from "../../services/gln_process";
import React, { forwardRef, useImperativeHandle } from 'react';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip"



export const AccordionGlnRecord = 


React.forwardRef((props:any, ref) => {


// ({
//   props, ref,
//   key,
//   data,
//   msgDate,
//   onClickItem
// }: {
//   props: any, ref: any, 
//   data?: any
//   msgDate?: any,
//   key?: any,
//   onClickItem(item: any):void
// }) => {
  
  const [active, setActive] = useState(false);


  const closeOtherItems = (e:any, item:any) => {
    // setActive(!active)
    props.onClickItem(item)
  }
  
  
  useImperativeHandle(ref, () => ({

      close(tipoGlnTraduzido:any) {
        if(props.data.tipoGlnTraduzido!=tipoGlnTraduzido){
          setActive(false)
        }else
          setActive(true)
      }

    })
  )

  const hasContact = (item:any) => {
   if(item!=undefined && item.length>0){
      return true;
   }
   if(item==undefined || item.length==0){
    return false;
   }
   return false;
  };
  return (


    
    <li className={(active === true) ? 'liGLN leftborder accordionGLN' : 'liGLN cardGLN '}>
      <div className="card-headerGLN">
      
        <div onClick={(e) => closeOtherItems(e, props.data)} className="child_li">
        
          <h5 style={{ maxWidth: '55rem' }} className="text">{props.data.tipoGlnTraduzido}</h5>
          {active==true ? <BiChevronUp size={20} fill={'#002c6c'} /> : <BiChevronDown size={20} fill={'#002c6c'} />}
        
        </div>
      </div>

      {active && (
        <CSSTransition
          in={active}
          timeout={300}
          classNames="alert"
          unmountOnExit
        >
          
            <div className="accordionGLN">

              <div className='col-12 col-md-12 mb-5'>


                <h4 className='titleProductDetailGlnMultiple'>{props.data.nameTitle}</h4>
                <p className="color-ui-6 body-sm glnfntw " style={{marginTop:"15px"}}>
                  <>
                    {traduzirTextoFuncao(props.data.tipoGln, props.data.status==undefined?props.status:props.data.status)} 
                    <span className={paintStatus(props.data.status==undefined?props.status:props.data.status)}>
                      {traduzirStatus(props.data.status==undefined?props.status:props.data.status)}
                      </span>

                  </>
                </p>
                <div className='row section'>

                  <div className='col-12 col-md-12 table-responsive'>
                    <table className='table'>
                      <tbody>

                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            GLN
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                            {props.data.gln}
                          </td>
                        </tr>
                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Tipo de GLN <AiOutlineInfoCircle className="glntype"/>
                            
                            <Tooltip anchorSelect=".glntype" place="top">
                            Identificação de qual é o tipo de GLN utilizado (Entidade legal, Função, Localização física, Localização móvel ou Localização digital)
                            </Tooltip>

                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                            {props.data.tipoGlnTraduzido}
                          </td>
                        </tr>

                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            {props.data.textoNomeFuncao}
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                            {props.data.name}
                          </td>
                        </tr>
                        {(props.data.tipoGln!= "PHYSICAL_LOCATION" 
                        && props.data.tipoGln != "FIXED_PHYSICAL_LOCATION"
                         )
                        &&
                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Entidade Relacionada <AiOutlineInfoCircle className="entity"/>
                            
                            <Tooltip anchorSelect=".entity" place="top">
                              Identifica qual é o GLN do tipo Entidade legal que se relaciona com o GLN buscado.
                            </Tooltip>
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                            {props.data.entidadeRelacionada}
                          </td>
                        </tr>
                        } 
                        {
                        props.data.tipoGln == "PHYSICAL_LOCATION" 
                        &&
                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Contido no endereço físico <AiOutlineInfoCircle className="contido"/>
                            <Tooltip anchorSelect=".contido" place="top">
                            É o GLN de localização física que contém o GLN que foi buscado
                            </Tooltip>
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                            {props.data.containedInPlace.gln}
                          </td>
                        </tr>
                        } 
                      


                       
                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Área de atuação <AiOutlineInfoCircle className="areaatuacao"/>
                            <Tooltip anchorSelect=".areaatuacao" place="top">
                            Funções e cargos relacionados ao GLN buscado
                            </Tooltip>
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                            {props.data.areaAtuacao}
                          </td>
                        </tr>
                        

                       
                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Número de registro legal <AiOutlineInfoCircle className="registrolegal"/>
                            <Tooltip anchorSelect=".registrolegal" place="top">
                            CNPJ (para pessoa juridica) ou CPF (para pessoa fisica) ao qual o GLN é relacionado
                            </Tooltip>
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                            {props.data.nroRegistroLegal}
                          </td>
                        </tr>


                      </tbody>
                    </table>
                    <div style={{ border: 'none' }}>
                      <h4 style={{ fontSize: '18px' }} className="titleProductDetailGlnMultipleSub">Localização da empresa</h4>
                    </div>
                    <table className='table'>
                      <tbody>
                      {
                        props.data.tipoGln == "MOBILE_PHYSICAL_LOCATION" 
                        &&
                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Localização base (GLN)
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                            {props.data.baseLocation.gln}
                          </td>
                        </tr>
                        } 


                        {
                        props.data.tipoGln == "DIGITAL_LOCATION" 
                        &&
                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Endereço eletronico digital
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                            {props.data.digitalAddress}
                          </td>
                        </tr>
                        } 
                       
                       
                        {props.data.tipoGln != "DIGITAL_LOCATION" && props.data.tipoGln != "MOBILE_PHYSICAL_LOCATION" 
                        &&
                                                    
                          props.data.endereco.map((endereco: any) => 
                          
                            <>
                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Endereço
                          </td>
                          {(endereco != "Não cadastrado") &&
                            <>
                              <td className="propertyvalue borderbottom" style={{ border: 'none', lineHeight: '3px' }}>
                                {(endereco.postalName != undefined) &&
                                  <>
                                    <p>{endereco.postalName.value}</p>
                                  </>}
                                {(endereco.streetAddress != undefined) &&
                                  <>
                                    <p>{endereco.streetAddress.value}</p>
                                  </>}
                                {(endereco.streetAddressLine2 != undefined) &&
                                  <>
                                    <p>{endereco.streetAddressLine2.value}</p>
                                  </>}
                                {(endereco.addressLocality != undefined) &&
                                  <>
                                    <p>{endereco.addressLocality.value}</p>
                                  </>
                                }
                                {(endereco.addressRegion != undefined) &&
                                  <>
                                    <p>{endereco.addressRegion.value}</p>
                                  </>
                                }
                                {(endereco.postalCode != undefined) &&
                                  <>
                                    <p>{endereco.postalCode}</p>
                                  </>
                                }
                                {(endereco.countryCode != undefined) &&
                                  <>
                                    <p>{endereco.countryCode}</p>
                                  </>
                                }

                              </td>
                            </>}
                          {(props.data.endereco == "Não cadastrado") &&
                            <>
                              <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                                Não cadastrado
                              </td>
                            </>}
                        </tr>

                                </>
                          )
                        
                        }


                        {props.data.geoShape
                        &&
                        <>
                          {
                            
                            props.data.geoShape.map((shape: any) => 
                            
                              <>
                                <tr>
                                  <td colSpan={2} style={{ border: 'none' }}>
                                    <h3 style={{ fontSize: '18px' }}>Geoshape</h3>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                                    Tipo
                                  </td>
                                  <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                                    {Object.keys(shape)[0]}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                                    Cordenadas
                                  </td>
                                  <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                                    {shape[Object.keys(shape)[0]]}
                                  </td>
                                </tr>
                              </>
                            )
                          }


                          
                        </>
                      }

                      {props.data.geoCoordinates
                        &&
                        <>
                          
                              <>
                                <tr>
                                  <td colSpan={2} style={{ border: 'none' }}>
                                    <h3 style={{ fontSize: '18px' }}>Geocordenadas</h3>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                                    Latitude
                                  </td>
                                  <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                                    {props.data.geoCoordinates.latitude}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                                    Longitude
                                  </td>
                                  <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                                    {props.data.geoCoordinates.longitude}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                                    Elevação
                                  </td>
                                  <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                                    {props.data.geoCoordinates.elevation}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                                    Sistema de coordenadas referência
                                  </td>
                                  <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                                    {props.data.geoCoordinates.coordinateReferenceSystem}
                                  </td>
                                </tr>
                               
                              </>
                           </>
                      }

                     
                      </tbody>
                    </table>


                    {props.data.containedInPlace
                      &&
                      <>
                      <table className='table'>
                      <tbody>
                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Contido no endereço físico<AiOutlineInfoCircle className="contido"/>
                            <Tooltip anchorSelect=".contido" place="top">
                            É o GLN de localização física que contém o GLN que foi buscado
                            </Tooltip>
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                            {props.data.containedInPlace.gln +" "}                        
                            {"("+traduzirTipoFuncao(props.data.containedInPlace.glnType)+")"}
                          
                          </td>
                        </tr>
                        </tbody>
                        </table>
                      </>
                      }

                    <div style={{ border: 'none' }}>
                      <h4 style={{ fontSize: '18px' }} className="titleProductDetailGlnMultipleSub">Contatos</h4>
                    </div>
                    <table className='table'>
                      <tbody>

                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Email
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {
                            hasContact(props.data.contatos.emails) && props.data.contatos!="Não cadastrado"  &&
                            <>
                            {
                              props.data.contatos.emails.map((item: any) =>{
                                return <>
                                {item!="Não cadastrado" && <><a href={"mailto:"+item}>{item}</a><br></br></>}
                                {item=="Não cadastrado" && <>{item}<br></br></>}
                              
                                
                                </>  
                              })
                            }
                            </>
                          }
                          {
                            props.data.contatos=="Não cadastrado" || props.data.contatos.emails.length==0  &&
                            <>{"Não cadastrado"}</>
                          }
                          </td>
                        </tr>
                          
                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Telefone
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {
                            hasContact(props.data.contatos.phones) && props.data.contatos!="Não cadastrado" &&
                            <>
                            {
                              props.data.contatos.phones.map((item: any) =>{
                                return <>{item}<br></br></>  
                              })
                            }
                            </>
                          }
                          {
                           props.data.contatos=="Não cadastrado" || props.data.contatos.phones.length==0  &&
                           <>{"Não cadastrado"}</>
                          }
                          </td>
                        </tr>
                         


                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Site
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {
                            hasContact(props.data.contatos.sites) && props.data.contatos!="Não cadastrado"  &&
                            <>
                            {
                              props.data.contatos.sites.map((item: any) =>{
                                return <>
                               {item!="Não cadastrado" && <><a href={item}>{item}</a><br></br></>}
                               {item=="Não cadastrado" && <>{item}<br></br></>}
                              
                                </>  
                              })
                            }
                            </>
                          }
                          {
                            props.data.contatos=="Não cadastrado" || props.data.contatos.sites.length==0  &&
                            <>{"Não cadastrado"}</>
                          }
                          </td>
                        </tr>
                              

                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Idiomas disponiveis
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {
                            hasContact(props.data.contatos.idiomas) && props.data.contatos!="Não cadastrado" &&
                            <>
                            {
                              props.data.contatos.idiomas.map((item: any) =>{
                                return <>{item}<br></br></>  
                              })
                            }
                            </>
                          }
                          {
                           props.data.contatos=="Não cadastrado" || props.data.contatos.idiomas.length==0  &&
                           <>{"Não cadastrado"}</>
                          }
                          </td>
                        </tr>



                        {/* <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Idiomas disponíveis
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>

                          </td>
                        </tr> */}
                      </tbody>
                    </table>

                  </div>
                </div>
                <div className='row pt-3 mb-2 pl-3 pr-3'>
                  <span className="lineInfo">
                    {props.msgDate}
                  </span>
                </div>

              </div>
            </div>



        </CSSTransition>
      )}
    </li>
  )
});
