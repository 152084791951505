import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import { IoMdClose } from "react-icons/io"
import {showError, showWait, dismissToast, showSucess} from '../../services/toast';

import "./style.scss";


interface IUseTermsFirstAccess {
  display : boolean;
  hideModal() : void;
  accept() : void;
  token: string;
  senha: string;
  termoUso: string;
}


const UseTermsFirstAccess: React.FC<IUseTermsFirstAccess> = ({display, hideModal, accept, token, senha, termoUso}) => { 

  const [open, setOpen] = useState(false)
  
  useEffect( () => {
    setOpen(display)
  }, [display])
  
  function close() {
    hideModal();
  }


  async function acceptTerm() {
    hideModal();
    accept();

  }

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={close}
        ariaHideApp={false}
        contentLabel="Selected Option"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#262626CC",
            zIndex: 1000
          },
          content: {
            width: "70vw",
            height: "89vh",
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",

            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            rowGap: "1.9rem",
          }}
        >
          
          <div>
            <span
              style={{ position: "absolute", right: 0, top: 0, padding: "0px" }}
            >
              <IoMdClose onClick={close} />
            </span>
          </div>

          <div
            style={{
              width: "100%",
              paddingBottom: "1.4rem",
              borderBottom: "1px solid #D8D8D8",
            }}
          >

            <span style={{ fontSize: "500", color: "#002C6C" }} dangerouslySetInnerHTML={{__html:termoUso}}></span>

            <div className="line end" style={{gap:"0.9rem", marginTop:"40px", display:"flex", justifyContent:"flex-end", padding:"20px 0 0 0", borderTop:"solid 1px #ebebeb"}}>

              <button 
              className="btn-primary "
              onClick={acceptTerm}
              >
                Aceitar
              </button>

              <button
                  className="btn-outline-primary"
                  onClick={hideModal}
              >
                Recusar
              </button>

            </div>

          </div>
          
        </div>

      </Modal>

  </>
  );
};
  
export default UseTermsFirstAccess;