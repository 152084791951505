import React, { useState, useEffect } from 'react';
import About from '../../components/About';
import Login from '../../components/Login';
import Forget from '../../components/Forget';
import { useLocation, useNavigate } from "react-router-dom";
import { useReducer } from 'react';




const Home = () => {




  return (
    <>
      <Login  />

      <About />
    </>
  );
 
 
};
  
export default Home;