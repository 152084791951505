import axios from "axios"
import { getConfig } from "../config/config";

export async function resetPass(token: string, senha: string) {

  try 
  {
    const response = await axios.post(
      getConfig().API_URL + "/novaSenha",
      {
        token:token,
        novaSenha: senha
      },
    )

    return {sucesso:true, mensagem:'Sua senha foi atualizada com sucesso. Por favor, faça login com sua nova senha.'};

  } catch (error: any) 
  {
    if  (error && error.response && error.response.data && error.response.data.erro)
      return {sucesso: false, mensagem: error.response.data.erro}

  }
  return {sucesso: false, mensagem: 'Erro desconhecido, tente novamente mais tarde.'};
  
}
