import axios from "axios"
import { getConfig } from "../config/config";


export async function companyActiveUsers(token: string, id: string, ) : Promise<any>{

  try 
  {
    const response = await axios.get(
      getConfig().API_URL + `/usuariosAtivosPorEmpresa/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return {success: true, message:'', result: response.data.usuarioAtivoEmpresa};

  } 
  catch (error: any) 
  {
    if(error)
      return {success: false, message: error.message};
  }

  return {success:false, message: 'Erro desconhecido. Tente novamente mais tarde.', result: ''}

}