import axios from "axios"
import { getConfig, getHeader  } from "../config/config";


export async function getUseTerm() : Promise<any>{

  try 
  {
    const response = await axios.get(
      getConfig().API_URL + "/consultaTermoUso",
      getHeader(false,"")
    )

    return {success: true, message:'', result: response.data};

  } 
  catch (error: any) 
  {
    if(error)
      return {success: false, message: error.message};
  }

  return {success:false, message: 'Erro desconhecido. Tente novamente mais tarde.', result: ''};

}
