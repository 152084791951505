import { ReactNode } from "react";
import MenuBackend from "../MenuBackend";


const TemplateBackend = ({ children }: { children: ReactNode }) => {
    return (
        <>
            <div className="container mt-5 single">
                <div className="row">
                    <div className="gs1-nav-left col-12 col-md-3 col-lg-3 col-sm-12 col-xs-12 d-none d-md-none d-lg-block">
                        <MenuBackend type="vertical"/>
                    </div>
                    <div className="col-12 d-block d-lg-none" style={{paddingTop:"120px"}}>
                        <MenuBackend type="horizontal"/>
                    </div>
                    <div className="col-12 col-md-12 col-lg-9 col-sm-12 col-xs-12">
                       
                        {children}
                          
                    </div>
                </div>
                <hr className="hrBackend" />
            </div>
        </>
    )
}

export default TemplateBackend