import { useState, useContext, useEffect } from "react";
import { BsStarFill, BsStar } from "react-icons/bs"
import { LoginContext } from "../../contexts/login_context";
import { setAcurracy } from "../../services/setAccuracy";


interface StarRatingProps {
  toAssess?: any
  star?: number
  gtin?: string
}

export function StartRating({ toAssess, star = 0, gtin }: StarRatingProps) 
{
  
  const { jwt } = useContext(LoginContext);

  let data = {
    gtin:  gtin,
    acuracidadeValue: star
  }

  const votation = (value:number) =>
  {
    data =
    {
      gtin: gtin,
      acuracidadeValue:value
    }
  }


  const votar = async () =>
  {
    const response: any = await setAcurracy(data, jwt); 
  }
 

  return (
    <div className="containerRadio">
      {[...Array(5)].map((s, i) => {
        const value = i + 1
        const index = i + 1
        
        return (
          <label key={new Date().getDate() + i}>
            <input
              type="radio"
              name="rating"
              value={value}
              onClick={() => {toAssess(value); votation(value); votar()}}
            />
            { value <= Number(star) &&
            <BsStarFill
              className="star1"
              size={20}
            />
            }
            { value > Number(star) &&
            <BsStar
            className="star2"
            size={20}
            />
            }
          
            
          </label>
        )
      })}
    </div>
  )
}
