import axios from "axios";
import { Buffer } from "buffer";
import { getConfig, getHeader  } from "../config/config";


export async function doAuth(email: string, password: string, aceito: string|null, token:string) : Promise<any>
{
  try 
  {
    const hash = Buffer.from(`${email}:${password}:${token}`).toString("base64")
    
    // const response = await axios.post(
    //   getConfig().API_URL + "/login",
    //   {"termoUso": aceito},
    //   {
    //     headers: {
    //       Authorization: `Basic ${hash}`,
    //     },
    //   }
      
    // )

    const response = await axios.post(
      getConfig().API_URL + "/login",
      {"termoUso": aceito},
      getHeader(true, `Basic ${hash}`)
      
    )

    // console.log(response.data)
    
    if (response.data.termoUso)
      return {auth: false, termoUso: response.data.termoUso};

    return {auth: true, token:response.data.token, perfil:response.data.perfil, nome: response.data.nome, empresa: response.data.empresaNome, empresaId: response.data.empresaId, popupVbg: response.data.popupVbg};

  } 
  catch (error: any) 
  {

    if (error && error.response && error.response.data && error.response.data.message)
      return {auth: false, mensagem:error.response.data.message};

  }
  return {auth: false, mensagem:"Erro desconhecido. Tente novamente mais tarde."}

}
