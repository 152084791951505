import React, { useState,KeyboardEvent } from 'react';
import { useSearchParams } from 'react-router-dom';
import About from '../../components/About';
import DefinePassword from '../../components/DefinePassword';
import { useNavigate } from "react-router-dom";
import { requestAccess } from '../../services/requestAccess';
import { showError, showWait, dismissToast, showSucess } from '../../services/toast';
import Forget from '../../components/Forget';

const RequestAccess = () => {

  const [showLogin, setShowLogin] = useState(true);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  
  function switchForm() {
    setShowLogin(!showLogin);
  }

  const [searchParams] = useSearchParams();
  let disableButton  = false
  const token = searchParams.get("token");
  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!disableButton){
      send()
    }
  }
  async function send() {
    disableButton = true
    let retorno = await requestAccess(email);

    if (retorno.sucesso) {
      showSucess(retorno.mensagem);
      setTimeout(function () {
        navigate('/');
      }, 5000);
    } else {
      showError(retorno.mensagem);
    }

    disableButton=false
  }

  function cancelButton() {
    navigate('/');
  }

const handleKeyboardEvent = (e: KeyboardEvent<HTMLInputElement>) => {
  setTimeout(function(){
    (e.target as HTMLInputElement).value=(e.target as HTMLInputElement).value.toLowerCase();
}, 1);
  
};

  return (
    <>

      {token &&
        <div className="access">
          <div className="c_access">
            <div className="login_stage">
              <div className="content_c">
                <form className="boxForget">
                  <h3>Solicitar acesso</h3>
                    <DefinePassword msgTitulo='Definir minha senha' mode="first" token={token} cancel={() => { }} />
                </form>
              </div>
            </div>
          </div>
        </div>
      }

      {!token &&
        <div className="access">
        <div className="c_access">
          <div className="login_stage">
            <div className="content_c">
              <form className="boxForget" onSubmit={onFormSubmit}>
                <h3>Solicitar acesso</h3>
                <div className="row">
                  <div className="col-md-12">
                    <div className="ms-webpart-zone ms-fullWidth">
                      <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                        <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                          <div className="ms-WPBody">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="wpLogin">
                                  <h6 className="title mb-4" style={{ whiteSpace: 'nowrap', marginTop: '0' }}>Insira o endereço de e-mail cadastrado:</h6>
                                  <div className="borderBox">
                                    <div className="form-group">
                                      <span className="form-label">E-mail</span>
                                      <input
                                      onKeyDown={handleKeyboardEvent}
                                        onChange={({ target }) => setEmail(target.value)}
                                        value={email}
                                        type="input"
                                        placeholder="exemplo@gs1.com"
                                        maxLength={128}
                                        className="form-control" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '28px' }}>
                  <button className="btn-primary" type="submit" value="Enviar" >Enviar</button>
                  <button className="btn-primary-blue" type="button" onClick={cancelButton}>Cancelar</button>
                </div>
                
              </form>
            </div>
          </div>
        </div>
      </div>
      }

      <About />
    </>
  );
};

export default RequestAccess;