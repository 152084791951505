import React, { useContext, useEffect, useState, KeyboardEvent } from "react";
import Modal from "react-modal";
import { IoIosEye, IoMdClose, IoIosEyeOff} from "react-icons/io";
import { NavLink } from "react-router-dom";
import { showError, showWait, dismissToast, showSucess } from '../../services/toast';
import { doAuth } from '../../services/login';
import { LoginContext } from '../../contexts/login_context'
import { useNavigate, useLocation} from "react-router-dom";
import UseTermsFirstAccess from '../UseTermsFirstAccess';
import Cookies from 'universal-cookie';
import { Buffer } from "buffer";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import RequestAccess from '../../pages/request-access/';
import { useSearchParams } from 'react-router-dom';
import DefinePassword from '../../components/DefinePassword';
import { recoverPassword } from "../../services/recoverPassword";
import { requestAccess } from '../../services/requestAccess';
import { useSelector } from "../../native-state.dev";

let disableButton = false


const handleKeyboardEvent = (e: KeyboardEvent<HTMLInputElement>) => {
  setTimeout(function(){
    (e.target as HTMLInputElement).value=(e.target as HTMLInputElement).value.toLowerCase();
}, 1);
  
};


const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  }
}




const Login = () => {
const [modalObje,setState] = useSelector((s: { searchDone: any; })=>s.searchDone);  
  

  setState({searchDone:false});

  
  const [searchParams] = useSearchParams();
  let disableButton  = false
  const token = searchParams.get("token");
  let mode:any = searchParams.get("mode");
  
  let _email = "";
  let _password = "";
  let _remember = false;
  /*
  if( cookies["rememberPassword"] ) {
    if (cookies["rememberPassword"] != null && cookies["rememberPassword"].indexOf(":") > 0) {
      _email = (cookies["rememberPassword"].split(":")[0])
      _password = (cookies["rememberPassword"].split(":")[1])
      _remember = true;
    }
  }
  */
  var location:any = useLocation();

  function checkOpenLogin(){
    if((location.state!=null)&&(location.state.showModalLogin)){
      openLogin();
      location.state.showModalLogin = false;
    }
  }

  const interval = setInterval(function() {
    checkOpenLogin();
  },10);
 
  const eyeoff = "IoIosEyeOff"
  const eyeon = "IoIosEye"
  const handleToggle = () => {
    if (type==='password'){
       setIcon(eyeon);
       setType('text')
    } else {
       setIcon(eyeoff)
       setType('password')
    }
 }
  const [email, setEmail] = useState(_email)
  const [modes, setmodes] = useState("")
  const [msgTitulo, setmsgTitulo] = useState("")
  const [msgTituloF, setmsgTituloF] = useState("")
  const [password, setPassword] = useState(_password)
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeoff);
  const [rememberPassword, setRememberPassword] = useState(_remember)
  const [stateModal, setStateModal] = useState(false)
  const [stateModalLogin, setStateModalLogin] = useState(false)
  const [stateModalForget, setStateModalForget] = useState(false)
  const [stateModalRequest, setStateModalRequest] = useState(false)
  const [stateModalResetPassword, setStateResetPassword] = useState(false)
  const [showUseTerms, setShowUseTerms] = useState(false);
  const [termoUsoText, setTermoUsoText] = useState("");
  const [modalDimensions, setModalDimensions] = useState({ w: '450px', h: '350px' });
  const [modalDimensionsLogin, setModalDimensionsLogin] = useState({ w: '500px', h: '600px' });
  const [modalDimensionsForget, setModalDimensionsForget] = useState({ w: '450px', h: '350px' });
  const [modalDimensionsRequest, setmodalDimensionsRequest] = useState({ w: '450px', h: '400px' });
  const [modalDimensionsResetPassword, setmodalDimensionsResetPassword] = useState({ w: '450px', h: '400px' });
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
  const [cssEmail, setCssEmail] = useState('input');
  const [cssPassword, setCssPassword] = useState('input');

  const { setLogin } = useContext(LoginContext);
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const cookies = new Cookies();
    let c = cookies.get('gs1');

    if (c) {
      let dcrp = Buffer.from(c, 'base64').toString("utf-8");
      setEmail(dcrp.split(":")[0]);
      setPassword(dcrp.split(":")[1]);
      setRememberPassword(true);
    }
    closeRequest();
    if(token){
      if(mode == "reset"){
        setmodes(mode);
        setmsgTitulo("Esqueci minha senha");
        setmsgTituloF("Recuperar Senha");
      }
      if(mode == "first"){
        setmodes(mode);
        setmsgTitulo("Definir minha senha");
        setmsgTituloF("Solicitar Acesso");
      }
      if(mode == "firstAcess"){
        setmodes("first");
        setmsgTitulo("Definir minha senha");
        setmsgTituloF("Primeiro Acesso");
      }
      
      
      

      setRememberPassword(false);
      openResetPassword();
    }
  }, []);

  useEffect(() => {
   
    function handleResize() {
      setWindowDimensions(getWindowDimensions)
    }

    window.addEventListener("resize", handleResize);


    if (windowDimensions.width > 280 && windowDimensions.width < 420) {
      setModalDimensions({ w: '100%', h: '60vh' });
    }
    else if (windowDimensions.width < 576 && windowDimensions.width > 420) {
      setModalDimensions({ w: '80%', h: '74vh' });
    }
    else if (windowDimensions.width < 768 && windowDimensions.width > 576) {
      setModalDimensions({ w: '450px', h: '70vh' });
    }
    else {
      setModalDimensions({ w: '550px', h: '56vh' });
    }
    
    return () => window.removeEventListener("resize", handleResize);


  }, [windowDimensions.height, windowDimensions.width]);


  function open() {
    setStateModal(true);
    closeLogin();
  }

  function close() {
    setStateModal(false);
    setShowUseTerms(false);
  }




  function closeLogin() {
    setStateModalLogin(false);
    navigate("/",{replace: false, state: { showModalLogin: false } })
  }
  function openLogin() {
    setStateModalLogin(true);
  }
  function openForget() {
    setStateModalForget(true);
    closeLogin();
  }

  function closeForget() {
    setStateModalForget(false);
  }
  function openRequest() {
    close();
    setStateModalRequest(true);
    closeLogin();
  }

  function closeRequest() {
    setStateModalRequest(false);
  }

  function openResetPassword(){
    setStateResetPassword(true);
  }

  
  function closeResetPassword(){
    setStateResetPassword(false);
  }
  function validateEmail(_email: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(_email))
      return (true)

    return (false)
  }
  const onFormSubmitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!disableButton) {
      singIn(null)
    }
  }

  const onFormSubmitRequest = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!disableButton){
      sendRequest()
    }
  }
  async function sendRequest() {
    disableButton = true
    let retorno = await requestAccess(email);

    if (retorno.sucesso) {
      showSucess(retorno.mensagem);
      setTimeout(function () {
        closeRequest();
      }, 5000);
    } else {
      showError(retorno.mensagem);
    }

    disableButton=false
  }

  const onFormSubmitForget = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!disableButton){
      send()
    }
  }
  async function send() {
    disableButton =  true
    let waitId = showWait();
    let retorno: any = await recoverPassword(email);
    dismissToast(waitId);

    if (retorno.erro == false) {
      showSucess(retorno.mensagem);
      closeForget();
    } else {
      showError(retorno.mensagem);
    }
    disableButton = false
  }

  async function singIn(aceito: string | null) {
    disableButton = true

    if (!executeRecaptcha) {
      disableButton = false
      return;
    }

    const token = await executeRecaptcha('yourAction');

    if (email.trim().length == 0) {
      showError('Preencha seu nome de usuário.');
      setCssEmail('input-error')
      disableButton = false
      return;
    }

    if (password.trim().length == 0) {
      showError('Preencha sua senha.');
      setCssPassword('input-error')
      disableButton = false
      return;
    }


    if (!validateEmail(email)) {
      showError('E-mail ou Senha inválido.');
      setCssEmail('input-error')
      disableButton = false
      return;
    }


    if (rememberPassword) {
      const cookies = new Cookies();
      let crp = Buffer.from(`${email}:${password}`).toString("base64");
      //let dcrp = Buffer.from(crp, 'base64').toString("utf-8");

      cookies.set('gs1', crp);

    }


    let toastId = showWait();
    let retorno = await doAuth(email, password, aceito, token, );
    dismissToast(toastId);


    if (!retorno.auth && retorno.termoUso) {
      closeLogin();
      setTermoUsoText(retorno.termoUso);
      setShowUseTerms(true);
      
      disableButton = false
      return;

    }
    else if (!retorno.auth) {
      showError(retorno.mensagem);
      disableButton = false
      return;
    }

    await setLogin(retorno.nome, retorno.perfil, retorno.token, retorno.empresa, email, retorno.empresaId, false, retorno.popupVbg);

    navigate('/homeApi');
    disableButton = false
  }

  return (
    <>
      <Modal
        isOpen={stateModal}
        onRequestClose={close}
        ariaHideApp={false}
        contentLabel="Selected Option"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#262626CC",
            zIndex: 1000
          },
          content: {
            maxWidth: modalDimensions.w,
            height: modalDimensions.h,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 1000,
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div style={{ padding: "0.5rem" }}>
          <span
            onClick={() => close()}
            style={{ position: "absolute", right: "1%", top: "1%" }}
          >
            <IoMdClose />
          </span>
          <div
            style={{ display: "flex", flexDirection: "column", rowGap: "0.5rem" }}
          >
            <span
              style={{
                color: "#002C6C",
                fontSize: "1.375rem",
              }}
            >
              Solicitar acesso ao Verified by GS1
            </span>
            <p>
              O acesso ao Verified by GS1 é um benefício para os associados da GS1
              Brasil. Entre em contato com a nossa equipe de atendimento para
              solicitar seu acesso pelo telefone (11) 4000-1936, pelo e-mail <a href="mailto:atendimento@gs1br.org?subject=VerifiedBy" >
                atendimento@gs1br.org</a> ou via <a href="https://chat.gs1br.org/" target="_blank" rel="noreferrer">chat online</a>.
            </p>
            <p>
              Para saber mais, <a href="https://gs1br.org/hub-de-dados/bancos-de-dados-de-produtos/verified-by-gs1" target="_blank" rel="noreferrer">
                clique aqui</a>.
            </p>
            <span
              style={{
                paddingBottom: "0rem",
                borderBottom: "1px solid #d8d8d8",
              }} />
            <p>
              Caso você seja o representante legal da empresa e já possua acesso
              ao Painel do Associado,{" "}
              <a onClick={openRequest} style={{color: "#00799e", textDecoration:"underline"}}> clique aqui </a>
               para definir sua senha de acesso ao Verified by GS1.
            </p>
            <span
              style={{
                paddingBottom: "0rem",
                borderBottom: "1px solid #d8d8d8",
              }} />
            <span style={{ textAlign: "right" }}>
              <button type="button" className="button" onClick={() => close()}>Ok</button>
            </span>
          </div>
        </div>
      </Modal>



      <UseTermsFirstAccess display={showUseTerms} hideModal={() => close()} accept={() => singIn("aceito")} senha={password} token={''} termoUso={termoUsoText} />

      <Modal
        isOpen={stateModalLogin}
        onRequestClose={closeLogin}
        ariaHideApp={false}
        contentLabel="Login"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#262626CC",
            zIndex: 1000
          },
          content: {
            maxWidth: modalDimensionsLogin.w,
            height: modalDimensionsLogin.h,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 1000,
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <span
            onClick={() => closeLogin()}
            style={{ position: "absolute", right: "1%", top: "1%" }}
          >
            <IoMdClose />
          </span>
        <div className="">
          <div className="c_access">
            <div className="login_stage">
              <div className="contentLogin">

                <form id="boxLogin" className="boxLogin" onSubmit={onFormSubmitLogin}>
                  <h1 className="mb-4">Login</h1>
                  <h3>Entre com seus dados GS1</h3>

                  <div className="">
                    <div className="">
                      <div className="ms-webpart-zone ms-fullWidth">
                        <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                          <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                            <div className="ms-WPBody ">
                              <div className="">
                                <div className="">
                                  <div className="wpLogin">
                                    <div className="borderBox">
                                      <div className="form-group">
                                        <span className="form-label">Usuário</span>

                                        <input
                                          onKeyDown={handleKeyboardEvent}
                                          id="login"
                                          onChange={({ target }) => { setCssEmail('input'); setEmail(target.value) }}
                                          value={email}
                                          type="text"
                                          name="user"

                                          placeholder="exemplo@gs1.com"
                                          maxLength={128}
                                          className={`form-control ${cssEmail}`} />
                                      </div>
                                      <div className="mt-10">
                                        <span className="form-label">Senha</span>

                                        <div  style={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
                                          <input 
                                            type={type}
                                            name="password"
                                            id="password"
                                            onChange={({ target }) => { setCssPassword('input'); setPassword(target.value) }}
                                            value={password}
                                            maxLength={100}
                                            className={`form-control ${cssPassword}`}
                                          />

                                          <span style={{marginLeft:'-30px', cursor:'pointer' }} onClick={handleToggle}>
                                            {(type=="password") &&
                                              <IoIosEye />
                                            }
                                            {(type!="password") &&
                                              <IoIosEyeOff />
                                            }
                                          </span>
                                        </div>
                                        

                                      </div>
                                     
                                      <div className="gs1-little">
                                        <label>
                                          <input type="checkbox" style={{ transform: 'scale(1.7)', marginRight: '8px', marginLeft: '4px' }} checked={rememberPassword} name="remember" id="remember" onChange={() => { }} onClick={() => setRememberPassword(!rememberPassword)} />  Lembrar senha
                                        </label>
                                      </div>
                                      <button id="entrarButton" style={{ marginBottom: '13px' }} type="submit" value="Entrar" >Entrar</button>
                                      <script>

                                      </script>
                                      <ul className="list-unstyled lstLinks">
                                        <li>
                                          <a href="javascript:;" style={{ textDecoration: 'underline' }} onClick={() => openForget()}>Esqueci a senha</a>
                                        </li>
                                        <li>
                                          <a href="javascript:;" style={{ textDecoration: 'underline' }} onClick={() => open()}>Não tenho acesso ao Verified by GS1</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </Modal>


      <Modal
        isOpen={stateModalForget}
        onRequestClose={closeForget}
        ariaHideApp={false}
        contentLabel="Login"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#262626CC",
            zIndex: 1000
          },
          content: {
            maxWidth: modalDimensionsForget.w,
            height: modalDimensionsForget.h,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 1000,
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <span
            onClick={() => closeForget()}
            style={{ position: "absolute", right: "1%", top: "1%" }}
          >
            <IoMdClose />
          </span>
        
              <form id="boxforget" className="boxForget" onSubmit={onFormSubmitForget}>
                <h3>Solicitar Acesso</h3>
                <div className="row">
                  <div className="col-md-12">
                    <div className="ms-webpart-zone ms-fullWidth">
                      <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                        <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                          <div className="ms-WPBody">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="wpLogin">
                                  <h6 className="title mb-4" style={{ whiteSpace: 'nowrap', marginTop: '0' }}>Insira o endereço de e-mail cadastrado:</h6>
                                  <div className="borderBox">
                                    <div className="form-group">
                                      <span className="form-label">E-mail</span>
                                      <input
                                      onKeyDown={handleKeyboardEvent}
                                        onChange={({ target }) => setEmail(target.value)}
                                        value={email}
                                        type="text"
                                        placeholder="exemplo@gs1.com"
                                        maxLength={128}
                                        className="form-control" />
                                        
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '28px' }}>
                  <button className="btn-primary" type="submit" value="Enviar" >Enviar</button>
                  <button className="btn-primary-blue" type="button" onClick={() => {closeForget();}}>Cancelar</button>
                </div>
                
              </form>
         
      

      </Modal>


      <Modal
        isOpen={stateModalRequest}
        onRequestClose={closeRequest}
        ariaHideApp={false}
        contentLabel="Login"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#262626CC",
            zIndex: 1000
          },
          content: {
            maxWidth: modalDimensionsRequest.w,
            height: modalDimensionsRequest.h,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 1000,
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <span
            onClick={() => closeRequest()}
            style={{ position: "absolute", right: "1%", top: "1%" }}
          >
            <IoMdClose />
          </span>
        
        
          
            <form id="boxrequest" className="boxForget" onSubmit={onFormSubmitRequest}>
            <h3>Solicitar acesso</h3>
            <div className="row">
              <div className="col-md-12">
                <div className="ms-webpart-zone ms-fullWidth">
                  <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                    <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                      <div className="ms-WPBody">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="wpLogin">
                              <h6 className="title mb-4" style={{ whiteSpace: 'nowrap', marginTop: '0' }}>Insira o endereço de e-mail cadastrado:</h6>
                              <div className="borderBox">
                                <div className="form-group">
                                  <span className="form-label">E-mail</span>
                                  <input
                                  onKeyDown={handleKeyboardEvent}
                                    onChange={({ target }) => setEmail(target.value)}
                                    value={email}
                                    type="input"
                                    placeholder="exemplo@gs1.com"
                                    maxLength={128}
                                    className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '28px' }}>
              <button className="btn-primary" type="submit" value="Enviar" >Enviar</button>
              <button className="btn-primary-blue" type="button" onClick={closeRequest}>Cancelar</button>
            </div>
            
          </form>
          
      </Modal>
      <Modal
        isOpen={stateModalResetPassword}
        onRequestClose={closeResetPassword}
        ariaHideApp={false}
        contentLabel="Login"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#262626CC",
            zIndex: 1000
          },
          content: {
            maxWidth: modalDimensionsResetPassword.w,
            height: modalDimensionsResetPassword.h,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 1000,
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <span
            onClick={() => closeResetPassword()}
            style={{ position: "absolute", right: "1%", top: "1%" }}
          >
            <IoMdClose />
          </span>
        
          
          {token &&
            <form id="boxrequestToken" className="boxForget">
            <h3 style={{marginTop:"0px"}}>{msgTituloF}</h3>
              <DefinePassword msgTitulo={msgTitulo} mode={modes} token={token} cancel={() => { }} />
          </form>
          }
          
          
      </Modal>




    </>
  );
};

export default Login;