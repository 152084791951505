import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginContext } from '../../contexts/login_context';
import ModalChangeScreen from "../ModalChangeScreen";
import { useSelector } from "../../native-state.dev";


const Usernav =() =>
{
    const [searchDone, setState] = useSelector((s: { searchDone: boolean }) => s.searchDone);

    const [isOpenModalRequest, setIsOpenModalRequest] = useState(false);
    const [isAuth, setAuth] = useState(false);
    const [getLocation, setGetLocation] = useState('front');
    const {jwt, setLogout, name} = useContext(LoginContext) 
    const navigate = useNavigate();
    const location = useLocation();
    
    const closeModal = () => setIsOpenModalRequest(false);
    const exportB = () => {
        setIsOpenModalRequest(false);
        const exportButton = document.getElementsByName("buttonExport")[0] as HTMLButtonElement | undefined;
        exportButton?.click();
      };
    const handleModalClick = () => {
        if (searchDone) {
          setIsOpenModalRequest(true);
        }else{
            navigate('/dashboard')
        }
      };

      const handleChangeScreen = () => {
        setState({searchDone:false});
    
        setIsOpenModalRequest(false);
        navigate('/dashboard')
      };

    useEffect( () => 
    {
        if (jwt && jwt !== "") 
        {
            setAuth(true);
        } 
        else 
        {
            setAuth(false);
        }

        let back = ['/profile', '/dashboard', '/user', '/terms'];

        if(back.indexOf(location.pathname) >= 0)
        {
            setGetLocation('back');
        }
        else
        {
            setGetLocation('front');
        }

    }, [jwt, location.pathname]);


    useEffect( () => {
        if( (jwt == null || jwt === "") && 
        location.pathname !== "/faq" && 
        location.pathname !== "/first-access" && 
        location.pathname !== "/reset-password" && 
        location.pathname !== "/request-access" && 
        location.pathname !== "/faq" && 
        location.pathname !== "/"
        )
        navigate("/");
    })

    async function logout() {
        setLogout();
    }
    function scroolto (idElement:string)  {
        const element = document.getElementById(idElement);
        // console.log(element);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    return(
        <>
            {isAuth &&
                <>
                {(getLocation === 'back') &&
                    <span>
                    Olá, {" "} {name}
                    </span>
                }

                {(getLocation === 'front') &&
                    <button className="btn btn-primary btn-sm" title="Minha conta" onClick={() => {handleModalClick()}}>
                    Minha conta
                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person" fill="currentColor">
                        <path fillRule="evenodd" d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"></path>
                    </svg>
                    </button>
                }

                <button className="btn btn-outline-primary btn-sm ml-2" title="Sair" onClick={() => logout()}>
                    Sair
                </button>
                </>
            }
            {!isAuth && (location.pathname=="/") &&

                <button id="entrarButton" style={{ marginRight: '24px', fontSize: "0.95rem" }} type="submit" value="Entrar" onClick={() => scroolto('formLeads')}>Quero contratar!</button>

                    

                } 
            {!isAuth &&

                <button className="btn btn-outline-primary btn-sm" title="Sair" onClick={() => {
                    navigate("/",{replace: false, state: { showModalLogin: true } });
                    }}>
                Login
                </button>

                

            }   
            <ModalChangeScreen
        isOpen={isOpenModalRequest}
        onClose={closeModal}
        onChangeScreen={handleChangeScreen}
        onExport={exportB}
      />
              
        </>   
    )

}

export default Usernav;