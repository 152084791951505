import { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { LoginContext } from '../../contexts/login_context';

const MenuBackend = (props:any) => 
{

  const [activetab, setActiveTab] = useState(0);
  const { perfil } = useContext(LoginContext);


  return (
    <>
    {(props.type === 'vertical') && 

      <nav className="navbar navbar-default navbar-expand-lg">
        <div className="collapse navbar-collapse">
          <div className="nav navbar-nav">
            <div className=" noindex ms-core-listMenu-verticalBox">
              <ul className="root ms-core-listMenu-root static">
                <li className="static selected">
                  <span className="textMenu">Menu</span>
                  <ul className="static">
                    <li className="static dynamic-children">
                      <NavLink to={"/profile"}
                        className={`menuNavLink ${(activetab === 1) ? 'a active' : 'a'}`}
                        onClick={() => setActiveTab(1)}
                      >
                        <span className="additional-background ms-navedit-flyoutArrow dynamic-children">
                          <span className="menu-item-text">Perfil</span>
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                  <ul className="static">
                    <li className="static dynamic-children">
                      <NavLink to={"/dashboard"} className={`menuNavLink ${(activetab === 2) ? 'a active' : 'a'}`} onClick={() => setActiveTab(2)}>
                        <span className="additional-background ms-navedit-flyoutArrow dynamic-children">
                          <span className="menu-item-text">Dashboard</span>
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                  {(perfil.toUpperCase().indexOf("ADMINISTRADOR") >= 0 || perfil.toUpperCase().indexOf("GS1") >= 0 ) &&
                    <ul className="static">
                      <li className="static dynamic-children">
                        <NavLink to={"/user"} className={`menuNavLink ${(activetab === 3) ? 'a active' : 'a'}`} onClick={() => setActiveTab(3)}>
                          <span className="additional-background ms-navedit-flyoutArrow dynamic-children">
                            <span className="menu-item-text">Usuários</span>
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  }
                  <ul className="static">
                    <li className="static dynamic-children">
                      <NavLink to={"/terms"} className={`menuNavLink ${(activetab === 4) ? 'a active' : 'a'}`} onClick={() => setActiveTab(4)}>
                        <span className="additional-background ms-navedit-flyoutArrow dynamic-children">
                          <span className="menu-item-text">Termos de uso</span>
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    }

    {(props.type === 'horizontal') &&
      <nav className="navbar navbar-expand navbar-default yamm gs1MenuNav py-0">

          <div id="navbar-collapse-grid" className="navbar-collapse collapse mb-4" style={{flexGrow:1}}>
              <ul className="navbar-nav" style={{borderBottom:'solid 1px #D8D8D8', width:'100%', justifyContent:'space-between'}}>
                <li className="static dynamic-children">
                  <NavLink to={"/profile"}
                    className={`menuhorizontal ${(activetab === 1) ? 'a active' : 'a'}`}
                    onClick={() => setActiveTab(1)}
                  >
                    <span className="additional-background ms-navedit-flyoutArrow dynamic-children">
                      <span className="menu-item-text">Perfil</span>
                    </span>
                  </NavLink>
                </li>
                <li className="static dynamic-children">
                  <NavLink to={"/dashboard"} className={`menuhorizontal ${(activetab === 2) ? 'a active' : 'a'}`} onClick={() => setActiveTab(2)}>
                    <span className="additional-background ms-navedit-flyoutArrow dynamic-children">
                      <span className="menu-item-text">Dashboard</span>
                    </span>
                  </NavLink>
                </li>
                <li className="static dynamic-children">
                  <NavLink to={"/user"} className={`menuhorizontal ${(activetab === 3) ? 'a active' : 'a'}`} onClick={() => setActiveTab(3)}>
                    <span className="additional-background ms-navedit-flyoutArrow dynamic-children">
                      <span className="menu-item-text">Usuários</span>
                    </span>
                  </NavLink>
                </li>
                <li className="static dynamic-children">
                      <NavLink to={"/terms"} className={`menuhorizontal ${(activetab === 4) ? 'a active' : 'a'}`} onClick={() => setActiveTab(4)}>
                        <span className="additional-background ms-navedit-flyoutArrow dynamic-children">
                          <span className="menu-item-text">Termos de uso</span>
                        </span>
                      </NavLink>
                    </li>
              </ul>
          </div>

      </nav>
    }
    
    </>
  );
}

export default MenuBackend