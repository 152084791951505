export const attributes = [
  "Nome da Empresa",
  "GTIN",
  "GTIN Origem",
  "Quantidade GTIN Origem",
  "Marca",
  "Descrição do Produto",
  "URL da Imagem",
  "Categoria global produto (GCP)",
  "Conteúdo líquido e unidade de medida",
  "País de venda",
  "Descrição adicional",
  "Marca adicional",
  "Url da imagem adicional",
  "Peso bruto e Unidade de medida",
  "NCM",
  "CEST",
  "Sincronizado CCG",
  "Status"
]
export const problems = [
  "Informação não disponível",
  "Informação incorreta – não corresponde com o que vejo no produto",
  "Informação não está clara ou tradução errada",
  "Outra sugestão para melhorar o conteúdo",
]
export const me = [
  "Fabricante de produto",
  "Varejista",
  "Marketplace (lugar de venda)",
  "Distribuidor ou Revendedor deste produto",
  "E-commerce",
  "Provedor de solução",
  "Consumidor final",
  "Outro",
]
