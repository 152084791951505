import axios from "axios"
import { getConfig, getHeader  } from "../config/config";

export async function setContestation(data: any, token: string) 
{
  try 
  {
    const response = await axios.post(
      getConfig().API_URL + "/contestacao",
      data,
      getHeader(true,`Bearer ${token}`)
    )
    return response;

  } catch (error: any) 
  {
      console.log(error);
  }
  return null;

}
