export function getUnitName(unitCode:string) : string {

    let c = units.find( (e) => {
        return e.code === unitCode.toLocaleUpperCase();
    });

    if (c !== undefined)
        return c.name;

    return unitCode;
}

interface IUnit {
    code: string;
    name: string;
}

const units : Array<IUnit> = [
    { code: "BLL", name: "barrel (US)"},
    { code: "J57", name: "bbl (UK liq.)"},
    { code: "BLD", name: "bbl (US)"},
    { code: "BUA", name: "bu (US)"},
    { code: "BUI", name: "bushel (UK)"},
    { code: "BP", name: "BP"},
    { code: "MLT", name: "ml"},
    { code: "CLT", name: "cl"},
    { code: "G24", name: "tablespoon (US)"},
    { code: "G25", name: "teaspoon (US)"},
    { code: "DMA", name: "dam³"},
    { code: "A44", name: "dal"},
    { code: "DLT", name: "dl"},
    { code: "LTR", name: "l"},
    { code: "MAL", name: "Ml"},
    { code: "DMQ", name: "dm³"},
    { code: "DRA", name: "DRA"},
    { code: "DRI", name: "DRI"},
    { code: "Q32", name: "fl"},
    { code: "GLL", name: "gal (US)"},
    { code: "GLI", name: "gal (UK)"},
    { code: "GLD", name: "dry gal (US)"},
    { code: "HLT", name: "hl"},
    { code: "H19", name: "hm³"},
    { code: "YDQ", name: "yd³"},
    { code: "LPA", name: "l alc 100%"},
    { code: "4G", name: "µl"},
    { code: "D40", name: "1000 l"},
    { code: "G23", name: "pk (US)"},
    { code: "L43", name: "pk (UK)"},
    { code: "Q34", name: "nl"},
    { code: "OZA", name: "fl oz (US)"},
    { code: "OZI", name: "fl oz (UK)"},
    { code: "FTQ", name: "ft³"},
    { code: "5I", name: "std"},
    { code: "MMQ", name: "mm³"},
    { code: "BFT", name: "fbm"},
    { code: "CMQ", name: "cm³"},
    { code: "Q33", name: "pl"},
    { code: "MTQ", name: "m³"},
    { code: "L61", name: "pt (US dry)"},
    { code: "PT", name: "pt (US)"},
    { code: "PTI", name: "pt (UK)"},
    { code: "PTL", name: "liq pt (US)"},
    { code: "PTD", name: "dry pt (US)"},
    { code: "INQ", name: "in³"},
    { code: "L62", name: "qt (US dry)"},
    { code: "QT", name: "qt (US)"},
    { code: "QTI", name: "qt (UK)"},
    { code: "QTL", name: "liq qt (US)"},
    { code: "QTD", name: "dry qt (US)"},
    { code: "K6", name: "kl"},
    { code: "H20", name: "km³"},
    { code: "G21", name: "cup (US)"},
    { code: "D43", name: "amu"},
    { code: "CGM", name: "cg"},
    { code: "A43", name: "dwt"},
    { code: "DJ", name: "dag"},
    { code: "DG", name: "dg"},
    { code: "DTN", name: "dt or dtn"},
    { code: "GRN", name: "gr"},
    { code: "GRM", name: "g"},
    { code: "GFI", name: "gi F/S"},
    { code: "GL", name: "g/l"},
    { code: "23", name: "g/cm³"},
    { code: "E4", name: "GKG"},
    { code: "HGM", name: "hg"},
    { code: "CWA", name: "cwt (US)"},
    { code: "CWI", name: "cwt (UK)"},
    { code: "KGM", name: "kg"},
    { code: "KDW", name: "kg/net eda"},
    { code: "KHY", name: "kg H₂O₂"},
    { code: "KMA", name: "kg met.am."},
    { code: "KNI", name: "kg N"},
    { code: "KPP", name: "KPP"},
    { code: "KPH", name: "kg KOH"},
    { code: "KPO", name: "kg K₂O"},
    { code: "KSH", name: "kg NaOH"},
    { code: "KSD", name: "kg 90 % sdt"},
    { code: "KUR", name: "kg U"},
    { code: "KTN", name: "kt (Massa)"},
    { code: "2U", name: "Mg"},
    { code: "CTM", name: "ct"},
    { code: "MC", name: "µg"},
    { code: "FH", name: "µmol"},
    { code: "MGM", name: "mg"},
    { code: "C18", name: "mmol"},
    { code: "C34", name: "mol"},
    { code: "X_NGM", name: "ng"},
    { code: "58", name: "kg/net"},
    { code: "ONZ", name: "oz"},
    { code: "X_DWT", name: "pwt"},
    { code: "LBR", name: "lb"},
    { code: "STI", name: "st (Massa)"},
    { code: "LTN", name: "ton (UK)"},
    { code: "STN", name: "ton (US)"},
    { code: "TNE", name: "t"},
    { code: "APZ", name: "tr oz"},
    { code: "AWG", name: "AWG"},
    { code: "A11", name: "Å"},
    { code: "CMT", name: "cm"},
    { code: "A45", name: "dam"},
    { code: "DMT", name: "dm"},
    { code: "AK", name: "fth"},
    { code: "A71", name: "fm"},
    { code: "FOT", name: "ft"},
    { code: "H79", name: "Fg"},
    { code: "HMT", name: "hm"},
    { code: "X_HIN", name: "X_HIN"},
    { code: "INH", name: "in"},
    { code: "KTM", name: "km"},
    { code: "LF", name: "LF"},
    { code: "LM", name: "LM"},
    { code: "MAM", name: "Mm"},
    { code: "MTR", name: "m"},
    { code: "M7", name: "µin"},
    { code: "4H", name: "µm"},
    { code: "SMI", name: "mile"},
    { code: "77", name: "mil"},
    { code: "MMT", name: "mm"},
    { code: "C45", name: "nm"},
    { code: "C52", name: "pm"},
    { code: "X_UIN", name: "X_UIN"},
    { code: "X_SIN", name: "X_SIN"},
    { code: "YRD", name: "yd"},
    { code: "CEL", name: "ºC"},
    { code: "FAH", name: "ºF"},
    { code: "KEL", name: "K"},
    { code: "J39", name: "Btu"},
    { code: "J75", name: "cal"},
    { code: "JOU", name: "J"},
    { code: "K51", name: "kcal"},
    { code: "KJO", name: "kJ"},
    { code: "C15", name: "mJ"},
    { code: "Q30", name: "pH"},
    { code: "4N", name: "MBq"},
    { code: "BB", name: "BB"},
    { code: "CMK", name: "cm²"},
    { code: "DMK", name: "dm²"},
    { code: "FTK", name: "ft²"},
    { code: "INK", name: "in²"},
    { code: "MTK", name: "m²"},
    { code: "MIK", name: "mi²"},
    { code: "MMK", name: "mm²"},
    { code: "YDK", name: "yd²"},
    { code: "G26", name: "st"},
    { code: "AS", name: "var"},
    { code: "5B", name: "lt"},
    { code: "BPM", name: "bpm"},
    { code: "BQL", name: "Bq"},
    { code: "D63", name: "liv"},
    { code: "CG", name: "cg (Contagem)"},
    { code: "X_CHD", name: "dhc"},
    { code: "CFU", name: "CFU"},
    { code: "X_CFG", name: "CFU/g"},
    { code: "X_CFP", name: "CFU/lb"},
    { code: "1N", name: "cont"},
    { code: "DD", name: "º"},
    { code: "E27", name: "cont"},
    { code: "E39", name: "dpi"},
    { code: "DZN", name: "DOZ"},
    { code: "SET", name: "conj"},
    { code: "ELU", name: "elu"},
    { code: "GBQ", name: "GBq"},
    { code: "GRO", name: "gr (Contagem)"},
    { code: "HD", name: "peh"},
    { code: "HEP", name: "dic"},
    { code: "HC", name: "cont cent"},
    { code: "X_IUK", name: "IU/kg"},
    { code: "KIU", name: "kiu"},
    { code: "2Q", name: "kBq"},
    { code: "KT", name: "kt"},
    { code: "X_KVN", name: "k"},
    { code: "LR", name: "lr"},
    { code: "LK", name: "lk"},
    { code: "X_MPG", name: "dlc"},
    { code: "X_MLM", name: "lm (Contagem)"},
    { code: "MEQ", name: "meq"},
    { code: "MIU", name: "nie"},
    { code: "MPN", name: "mpn"},
    { code: "X_MTC", name: "mtc"},
    { code: "NCL", name: "ncl"},
    { code: "NIU", name: "niu"},
    { code: "NPR", name: "npr"},
    { code: "OPM", name: "opm"},
    { code: "PD", name: "pd"},
    { code: "QB", name: "qb"},
    { code: "PR", name: "pr"},
    { code: "H87", name: "pç"},
    { code: "E37", name: "px"},
    { code: "X_PPC", name: "ppc"},
    { code: "X_PPI", name: "ppi"},
    { code: "PFU", name: "dgc"},
    { code: "PNT", name: "pnt"},
    { code: "PTN", name: "por"},
    { code: "PRS", name: "prs"},
    { code: "X_RAE", name: "era"},
    { code: "XRE", name: "re"},
    { code: "XRO", name: "rol"},
    { code: "X_SPS", name: "sps"},
    { code: "EA", name: "un"},
    { code: "XST", name: "fol"},
    { code: "SX", name: "emb"},
    { code: "FJ", name: "fj"},
    { code: "SQE", name: "sqe"},
    { code: "U2", name: "tab"},
    { code: "TPI", name: "tpi"},
    { code: "KO", name: "ko"},
    { code: "R9", name: "mmc"},
    { code: "T3", name: "mpç"},
    { code: "E55", name: "uso"},
    { code: "GM", name: "g/m²"},
    { code: "ON", name: "oz/yd²"},
    { code: "AMP", name: "A "},
    { code: "AMH", name: "A·h"},
    { code: "D70", name: "calIT"},
    { code: "X_CCA", name: "X_CCA"},
    { code: "GWH", name: "GW·h"},
    { code: "D03", name: "kW.h/h"},
    { code: "KWH", name: "kW.h"},
    { code: "MWH", name: "MW.h"},
    { code: "E09", name: "mA·h"},
    { code: "OHM", name: "Ω"},
    { code: "C75", name: "pW"},
    { code: "D30", name: "TJ"},
    { code: "D32", name: "TW.h"},
    { code: "VLT", name: "V"},
    { code: "WHR", name: "W.h"},
    { code: "B37", name: "kgf"},
    { code: "A86", name: "GHz"},
    { code: "HTZ", name: "Hz"},
    { code: "KHZ", name: "kHz"},
    { code: "MHZ", name: "MHz"},
    { code: "D29", name: "THz"},
    { code: "AD", name: "byte"},
    { code: "E34", name: "Gbyte"},
    { code: "2P", name: "kbyte"},
    { code: "4L", name: "Mbyte"},
    { code: "E36", name: "Pbyte"},
    { code: "E35", name: "Tbyte"},
    { code: "B62", name: "lm.s"},
    { code: "B61", name: "lm/W"},
    { code: "B64", name: "lx·s"},
    { code: "A24", name: "cd/m²"},
    { code: "LUM", name: "lm"},
    { code: "B60", name: "lm/m²"},
    { code: "LUX", name: "lx"},
    { code: "F27", name: "g/h"},
    { code: "NA", name: "mg/kg"},
    { code: "K30", name: "gal (US liq.)/s"},
    { code: "B47", name: "kN"},
    { code: "NEW", name: "N"},
    { code: "G2", name: "gal (US) /min"},
    { code: "NU", name: "N.m"},
    { code: "K43", name: "eletric hp"},
    { code: "KWT", name: "kW"},
    { code: "MAW", name: "MW"},
    { code: "WTT", name: "W"},
    { code: "BAR", name: "bar"},
    { code: "X_DBA", name: "dbar"},
    { code: "D5", name: "kg/cm²"},
    { code: "28", name: "kg/m²"},
    { code: "KNM", name: "kN/m²"},
    { code: "KPA", name: "kPa"},
    { code: "MBR", name: "mbar"},
    { code: "PAL", name: "Pa"},
    { code: "FP", name: "lb/ft²"},
    { code: "80", name: "lb/in²"},
    { code: "64", name: "PSIG"},
    { code: "PS", name: "lbf/in²"},
    { code: "ATM", name: "atm"},
    { code: "ATT", name: "atm"},
    { code: "UA", name: "Torr"},
    { code: "59", name: "ppm"},
    { code: "P1", name: "% or pct"},
    { code: "RPM", name: "r/min"},
    { code: "2N", name: "dB"},
    { code: "D55", name: "W/(m²·K)"},
    { code: "D19", name: "m².K/W"},
    { code: "DAY", name: "d"},
    { code: "HUR", name: "h"},
    { code: "C26", name: "ms"},
    { code: "MIN", name: "min"},
    { code: "MON", name: "mo"},
    { code: "C47", name: "ns"},
    { code: "SEC", name: "s"},
    { code: "WEE", name: "wk"},
    { code: "ANN", name: "y"},
    { code: "B10", name: "bit/s"},
    { code: "H49", name: "cm/h"},
    { code: "2M", name: "cm/s"},
    { code: "2L", name: "ft³/min"},
    { code: "K14", name: "ft/h"},
    { code: "FR", name: "ft/min"},
    { code: "FS", name: "ft/s"},
    { code: "M63", name: "in/min"},
    { code: "IU", name: "in/s"},
    { code: "KMH", name: "km/h"},
    { code: "M62", name: "km/s"},
    { code: "KNT", name: "kn"},
    { code: "M60", name: "m/h"},
    { code: "MTS", name: "m/s"},
    { code: "HM", name: "mile/h"},
    { code: "M57", name: "mi/min"},
    { code: "M58", name: "mi/s"},
    { code: "H67", name: "mm/h"},
    { code: "H81", name: "mm/min"},
    { code: "C16", name: "mm/s"},
    { code: "S4", name: "m²/s"},
    { code: "M66", name: "yd/h"},
    { code: "M65", name: "yd/min"},
    { code: "M64", name: "yd/s"},
    { code: "C65", name: "Pa.s"},
    { code: "MQH", name: "m³/h"},
    { code: "LD", name: "l/d"},
    { code: "E32", name: "l/h"},
    { code: "2X", name: "m/min"},
]