import axios from "axios"
import { getConfig, getHeader  } from "../config/config";


export async function hitsPerDay(token: string, filterDate: string) : Promise<any>{

  try 
  {
    const response = await axios.get(
      getConfig().API_URL + `/acessosDia/${filterDate}`,
      getHeader(true,`Bearer ${token}`)
    )

    return {success: true, message:'', result: response.data.acessosDia};

  } catch (error: any) 
  {
    if(error)
      return {success: false, message: error.message};
  }
  
  return {success:false, message: 'Erro desconhecido. Tente novamente mais tarde.', result: ''};

}