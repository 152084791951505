import React from "react";
import Modal from "react-modal";
import { IoMdClose } from "react-icons/io";

interface ModalChangeScreenProps {
  isOpen: boolean;
  onClose: () => void;
  onChangeScreen: () => void;
  onExport: () => void;
}

const ModalChangeScreen: React.FC<ModalChangeScreenProps> = ({
  isOpen,
  onClose,
  onChangeScreen,
  onExport,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Selected Option"
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "#262626CC",
          zIndex: 1000,
        },
        content: {
          width: "650px",
          height: "410px",
          marginTop: "5%",
          marginBottom: 0,
          marginLeft: "auto",
          marginRight: "auto",
          zIndex: 1000,
          border: "1px solid #ccc",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "20px",
          outline: "none",
          padding: "10px",
        },
      }}
    >
      <div style={{ padding: "0.5rem" }}>
        <span
          onClick={onClose}
          style={{ position: "absolute", right: "7px", top: "7px" }}
        >
          <IoMdClose />
        </span>
        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "2rem" }}
        >
          <div
            style={{
              color: "rgb(105, 109, 115)",
              marginBottom: "-15px",
            }}
          >
            <div className="section">
              <div
                className="col-12 col-md-12"
                style={{ display: "flex", fontSize: "17px" }}
              >
                <div
                  className="col-6 col-md-12 table-responsive"
                  style={{ textAlign: "left", lineHeight: "1.3" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "258px",
                      textAlign: "justify",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "20px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        Está tentado mudar de tela?
                      </span>
                      <span style={{ fontWeight: "bold" }}>
                        Cuidado para não perder suas consultas!
                      </span>
                    </div>
                    <div className="flex">
                      <div>
                        Recomendamos exportar as informações da sua consulta
                        para evitar a perda de dados.
                      </div>
                      <div>
                        Pressione <strong>MUDAR DE TELA</strong> para sair ou{" "}
                        <strong>EXPORTAR</strong> para salvar seus dados.
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div style={{ textAlign: "right", marginTop: "-15px" }}>
                      <button
                        style={{ width: "170px", marginRight: "10px" }}
                        className="btn btn-outline-primary"
                        onClick={onChangeScreen}
                      >
                        Mudar de tela
                      </button>
                    </div>

                    <div style={{ textAlign: "right", marginTop: "-15px" }}>
                      <button
                        style={{ width: "170px" }}
                        className="btn btn-primary"
                        onClick={onExport}
                      >
                        Exportar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalChangeScreen;
