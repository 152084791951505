import React, { MouseEventHandler } from 'react';
import "./style.scss"


const Warning = (
    {
        type = 'success', 
        showpick = true, 
        title = 'Os dados do produtao estão completos', 
        text = '' ,
        handleCLick
    }:
    {
        type?:string, 
        showpick?:boolean, 
        title?:string, 
        text:string,
        handleCLick?: any
    }  

) =>
{
    const txtremove = "Clique aqui e envie seu feedback."
    let button = false
    if(text.includes(txtremove)){
        button=true
        text = text.replaceAll(txtremove,"")
    }
   
    // button=true
    // text = text.replaceAll(txtremove,"")
    let message_type = (type === 'success') ? 'alert alert-success my-4' : 'alert alert-danger my-4';
    if(type==='success' || type==='OK'){
        message_type = 'alert alert-success my-4';
        showpick = true;
    }
    if(type==='INACTIVE'){
        message_type = 'alert alert-danger my-4';
        showpick = false;
    }
    if(type==='DISCONTINUED'){
        message_type = 'alert alert-warning my-4';
        showpick = true;      
    }
    if(type==='INFO'){
        message_type = 'alert alert-info my-4';
        showpick = false;      
    }
    

    return(

        <div className={message_type}>

            {showpick === true &&(
            <div className='box-icon-alert mr-2 pt-2'>
                <img src='/vbg1.png' alt='vbg1'/>
            </div>
            )}

            <div className='text-alert'>
                {(title !== null && title !== '') &&(
                    <>
                    <h5 className='mt-0'>
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                    </h5>
                    <p style={{display:"flex"}}>
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                    <div style={{paddingLeft:"5px"}}>
                        {button && (
                            <a href="javascript:;" onClick={handleCLick}> {" "+txtremove}</a>
                        )}
                    </div>
                    
                    
                    </p>
                    </>
                )}
                {(title === null || title === '') &&(
                    <>
                    <h5 className='mt-0' style={{paddingTop: "21px" }}>
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                    </h5>
                    </>
                )}

                <p>
                </p>
            </div>
        </div>
             
    )

}

export default Warning