import axios from "axios"
import { getConfig, getHeader } from "../config/config";


export async function getUsers(token: string, nome: string | null, email: string| null, status: string| null, tipoUsuario: string| null, perfil: string| null, empresa: string| null) : Promise<any> {
  try 
  {
    const response = await axios.post(
      getConfig().API_URL + "/consultaUsuario",
      {
        nome: nome, 
        email: email, 
        status:status, 
        tipoUsuario: tipoUsuario, 
        perfil: perfil, 
        empresa: empresa
      },
      
      getHeader(true,`Bearer ${token}`)
      
    )

    return {success: true, message:'', result: response.data};

  } 
  catch (error: any) 
  {
    if(error)
      return {success: false, message: error.message};
  }

  return {success:false, message: 'Erro desconhecido. Tente novamente mais tarde.', result: ''};

}
