import axios from "axios";
import { Buffer } from "buffer";
import { getConfig } from "../config/config";


export async function firstAccess(senha: string, token: string) : Promise<any>
{

  try 
  {
    const response : any = await axios.post(
      getConfig().API_URL + "/primeiroAcesso",
      {
        token:token,
        senha: senha
      },
    )

    return {sucesso: true, mensagem: response.data.primeiroAcesso};

  } 
  catch (error: any) 
  {
    if(error && error.response && error.response.data && error.response.data.erro)
        return {sucesso:false, mensagem:error.response.data.erro}

  }
  return {sucesso:false, mensagem:"Erro desconhecido. Tente novamente mais tarde."}

}
