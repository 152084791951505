import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { LoginContext } from '../../contexts/login_context';
import MenuSearch from "../MenuSearch";
import Usernav from "../usernav";
import ModalChangeScreen from "../ModalChangeScreen";
import { useSelector } from "../../native-state.dev";

const NavBar = () =>
{
    const [searchDone, setState] = useSelector((s: { searchDone: boolean }) => s.searchDone);

    const [isOpenModalRequest, setIsOpenModalRequest] = useState(false);
    const [isAuth, setAuth] = useState(false);
    const [getLocation, setGetLocation] = useState('front');
    const {jwt, setLogout, name} = useContext(LoginContext) 
    const navigate = useNavigate();
    const location = useLocation();

    const [openMenuSandwiche, setOpenMenuSandwiche] = useState('fechado');
    const [openMenu, setOpenMenu] = useState('collapse');

    const closeModal = () => setIsOpenModalRequest(false);
    const exportB = () => {
        setIsOpenModalRequest(false);
        const exportButton = document.getElementsByName("buttonExport")[0] as HTMLButtonElement | undefined;
        exportButton?.click();
      };
      const handleModalClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        openMenuMobile('close')
        if (searchDone) {
            event.preventDefault();
            event.stopPropagation();
            setIsOpenModalRequest(true);
        }else{
            setState({searchDone:false});

            navigate('/faq')
        }
      };

      const handleChangeScreen = () => {
        setState({searchDone:false});
    
        setIsOpenModalRequest(false);
        navigate('/faq')
      };


    useEffect( () => 
    {
        if (jwt && jwt !== "") 
        {
            setAuth(true);
        } 
        else 
        {
            setAuth(false);
        }

        let back = ['/profile', '/dashboard', '/user', '/terms'];

        if(back.indexOf(location.pathname) >= 0)
        {
            setGetLocation('back');
        }
        else
        {
            setGetLocation('front');
        }

    }, [jwt, location.pathname]);


    useEffect( () => {
        if( (jwt == null || jwt === "") && 
        location.pathname !== "/faq" && 
        location.pathname !== "/first-access" && 
        location.pathname !== "/reset-password" && 
        location.pathname !== "/request-access" && 
        location.pathname !== "/faq" && 
        location.pathname !== "/"
        )
        navigate("/");
    })

    const openMenuMobile = (status:string = 'open') => 
    {
        if(status === 'open')
        {
            (openMenuSandwiche === 'fechado') ? setOpenMenuSandwiche('aberto') : setOpenMenuSandwiche('fechado');
            (openMenu === 'collapse') ? setOpenMenu('show') : setOpenMenu('collapse');
        }
        else
        {
            setOpenMenuSandwiche('fechado');
            setOpenMenu('collapse');
        }
        
    }

    async function logout() {
        setLogout();
    }


    return(

        <header>
            <div id="s4-titlerow" className="ms-dialogHidden s4-titlerowhidetitle noindex">
                <div id="titleAreaBox" className="container">
                    <div id="titleAreaRow" className="row">

                        <div className="nav-mobile col-12 d-block d-md-none">
                            <nav className="navbar navbar-default" role="navigation">
                                <div className="container-fluid_">
                                
                                    <div className="navbar-header">
                                    {(getLocation === 'back') &&
                                        <span 
                                            className="d-block d-md-none rounded-0 pt-2 pr-2 pl-4"
                                            style={{width:'200px', textAlign:'right'}}
                                        >
                                            Olá, {" "} {name}
                                        </span>
                                    }

                                        <button type="button" onClick={() => openMenuMobile()} className={"navbar-toggle " + openMenuSandwiche} data-toggle="collapse" data-target="#gs1-top-navbar-mobile">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-list" fill="currentColor">
                                                <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
                                            </svg>
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-x" fill="currentColor">
                                                <path fillRule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div id="gs1-top-navbar-mobile" className={"navbar-collapse " + openMenu}>
                                        {isAuth &&
                                            <button className="btn d-block btn-outline-primary rounded-0" title="Sair" onClick={() => {openMenuMobile(); logout(); }} style={{width:'100%'}}>
                                                Sair
                                            </button>
                                        }
                                        <ul className="nav navbar-nav">
                                        {isAuth &&
                                            <>
                                                <li className="hovernav">
                                                    <NavLink to={"/homeApi"} className="dropdown-toggle" onClick={() => {openMenuMobile('close'); }}>
                                                        Pesquisa
                                                    </NavLink>
                                                </li>
                                                <li className="hovernav" >
                                                    <NavLink to={"/search-many"} className="dropdown-toggle" onClick={() => {openMenuMobile('close'); }}>
                                                        Pesquisa Múltipla
                                                    </NavLink>
                                                </li>
                                            </>
                                        } 
                                        {!isAuth &&
                                            //pôr info aqui para usuários não logados
                                            <>
                                            </>
                                        }

                                        </ul>
                                       
                                        <div className="global-zone">
                                            <div className="global-links">
                                                <ul className="global-links-list">
                                                    <li>
                                                        <a href="https://gs1br.org/sobre-a-gs1/Paginas/default.aspx" target={'_blank'} rel="noreferrer">
                                                            Sobre a GS1
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <NavLink to={"/faq"} title="Perguntas Frequentes" onClick={() => {openMenuMobile('close'); }}>Perguntas Frequentesa</NavLink>
                                                    </li>
                                                    <li>
                                                        <a href="https://chat.gs1br.org/" target={'_blank'} rel="noreferrer">
                                                            Atendimento
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="global-language">A Linguagem Global dos Negócios</div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>


                        <div id="siteIcon" className="col-md-2 col-lg-4">
                            
                            <div id="DeltaSiteLogo">

                            {isAuth &&
                                <NavLink to={"/homeApi"} onClick={() => {openMenuMobile('close'); }}>
                                    <img src="/logogs1.png" alt="GS1" style={{width:"20rem"}} className="d-md-block d-none"/>
                                    <img src="/logo3.svg" alt="GS1" style={{height:"70px"}} className="d-md-none pb-2"/>
                                </NavLink>
                                }

                            {!isAuth &&
                                <NavLink to={"/home"} onClick={() => {openMenuMobile('close'); }}>
                                    <img src="/logogs1.png" alt="GS1" style={{width:"20rem"}} className="d-md-block d-none"/>
                                    <img src="/logo3.svg" alt="GS1" style={{height:"70px"}} className="d-md-none pb-2"/>
                                </NavLink>
                            }
                            
                            </div>
                            
                        </div>


                        <div className="col-md-10 col-lg-8 d-none d-md-block">

                            <ul className="links-top d-none d-md-block">
                                <li className="global-language">
                                    A Linguagem Global dos Negócios
                                </li>
                                <li>
                                    <a href="https://gs1br.org/sobre-a-gs1/Paginas/default.aspx" target={'_blank'} rel="noreferrer">
                                        Sobre a GS1
                                    </a>
                                </li>
                                <li>
                                    <NavLink to={"/faq"} onClick={handleModalClick}  title="Perguntas Frequentes">Perguntas Frequentes</NavLink>
                                </li>
                                <li>
                                    <a href="https://chat.gs1br.org/" target={'_blank'} rel="noreferrer">
                                        Atendimento
                                    </a>
                                </li>
                            </ul>
                            
                            <div className="btns-header"> 
                                <Usernav/>
                            </div>

                        </div>


                    </div>

                    {!isAuth &&
                        <a href="javascript:;" className="btn btn-primary d-block d-md-none rounded-0 linkBtnSecondary" onClick={() => {openMenuMobile('close'); navigate("/",{replace: false, state: { showModalLogin: true } });}} style={{}}>
                            Login
                        </a>
                    }

                    {!isAuth &&
                        <a href="javascript:;" className="btn btn-secondary d-block d-md-none rounded-0 linkBtnPrimary" onClick={() => {openMenuMobile('close'); navigate("/",{replace: false, state: { gotoForm: true } });}}>
                            Quero contratar!
                        </a>
                    }

                    {isAuth &&

                        <>

                            {(getLocation === 'front') &&
                                <button className="btn btn-primary d-block d-md-none rounded-0" title="Minha conta" onClick={() => {openMenuMobile('close'); navigate('/dashboard')}} style={{width:'100%'}}>
                                    Minha conta
                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"></path>
                                    </svg>
                                </button>
                            }

                        </>
                    }

                </div>
            </div>

            {isAuth &&
                <div className="container-fluid gs1Menu ms-dialogHidden d-none d-md-block noindex" style={{paddingTop:"120px"}}>
                    <div className="row">
                        <div className="container gs1MenuContainer">
                            <MenuSearch />
                        </div>
                    </div>
                </div>
            }
 <ModalChangeScreen
        isOpen={isOpenModalRequest}
        onClose={closeModal}
        onChangeScreen={handleChangeScreen}
        onExport={exportB}
      />

        </header>
    )
}

export default NavBar;