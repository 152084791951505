import axios from "axios"
import { getConfig, getHeader  } from "../config/config";


export async function getUserType(token: string) : Promise<any> {
  try 
  {
    const response = await axios.get(
      getConfig().API_URL + "/consultaTipoUsuario",
      getHeader(true,`Bearer ${token}`)
    )

    return {success: true, message:'', result: response.data.tipoUsuario};

  } 
  catch (error: any) 
  {
    if(error)
      return {success: false, message: error.message};
  }

  return {success:false, message: 'Erro desconhecido. Tente novamente mais tarde.', result: ''};

}
