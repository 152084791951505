import React from "react"
import { data as dataTable } from '../../pages/faq'


interface ITable {
    data?: any[]
}


const Row = ({ record }: any) => {
    const keys = Object.keys(record)

    return (
        <tr key={record.id}>
            {
                keys.map(key => <td key={key}>{record[key]}</td>)
            }
        </tr>
    )
}

const Head = ({ keys }: any) => {
    return (
        <thead className="faqThead">
            <tr className="faqRow">
                {
                    keys.map((key: any) => <th key={key}> {key} </th>)
                }
            </tr>
        </thead>
    )

}

const Table = ({ data = dataTable }: ITable) => {
    const keys = Object.keys(data[0])
    return (
        <div className="container">
            <div className="row">
                <table className="table">
                    <Head keys={keys} />
                    <tbody className="faqTbody">
                        {
                            data.map((record: any) => <Row record={record} />)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Table;