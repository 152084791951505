import React from 'react';
import "./style.scss"


const NewWarning = (
    {
        type = 'success', 
        showpick = true, 
        title = 'Os dados do produto estão completos', 
        text = '' 
    }:
    {
        type?:string, 
        showpick?:boolean, 
        title?:string, 
        text:string
    }
) =>
{

    let message_type = (type === 'success') ? 'alert alert-success my-4' :  (type === 'error') ? 'alert alert-danger my-4': 'alert alert-warning my-4';

    return(

        <div className={message_type}>

            {showpick === true &&(
            <div className='box-icon-alert mr-2 pt-2'>
                <img src='/vbg1.png' alt='vbg1'/>
            </div>
            )}

            <div className='text-alert'>
                {(title !== null && title !== '') &&(
                    <>
                    <h5 className='mt-0'>
                    {title}
                    </h5>
                    <p>
                    {text}
                    </p>
                    </>
                )}
                {(title === null || title === '') &&(
                    <>
                    <h5 className='mt-0' style={{paddingTop: "15px" }}>
                    <div dangerouslySetInnerHTML={{ __html: text }} />

                    </h5>
                    </>
                )}

                <p>
                </p>
            </div>
        </div>
             
    )

}

export default NewWarning