import axios from "axios"
import { getConfig } from "../config/config";


export async function deleteUser(token: string, id:string) : Promise<any> 
{
  try 
  {

    const response = await axios.delete(
      getConfig().API_URL + "/excluiUsuario",{
        headers:{
          Authorization: `Bearer ${token}`,
        },
        data:{
          usuarioID: id
        }  
      }
    )

    return {success: true, message:response.data.usuarioExcluido, result: ''};

  } 
  catch (error: any) 
  {
    if(error)
      return {success: false, message: error.data.emailExistente};
  }

  return {success:false, message: 'Erro desconhecido. Tente novamente mais tarde.', result: ''};

}
