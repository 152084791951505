import React, { useCallback, useContext, useEffect, useState } from "react";
import TemplateBackend from "../../components/TemplateBackend";
import DataTable, { TableColumn } from 'react-data-table-component';
import Modal from "react-modal";
import { LoginContext } from '../../contexts/login_context';

import { insertUser } from "../../services/insertUser";
import {showError, showWait, dismissToast, showSucess} from '../../services/toast';
import { termsOfUse } from "../../services/termsOfUse";
import Loading from "../../components/Loading";
import AsyncSelect from 'react-select/async';

import { IoMdClose } from "react-icons/io";
import BootyPagination from "../../components/Pagination";
import { getUserGS1Companies } from "../../services/getUserGS1Companies";


interface TermUser 
{
    titulo: string;
    usuario: string;
    email: string;
    empresa: string;
    status: string;
    data: string;
    htmltermo: string;
}



const Terms = () => 
{
    const { jwt, perfil } = useContext(LoginContext);
    const [stateModal, setStateModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalTitle, setModalTitle] = useState('');

    const [hideUser, setHideUser] = useState(false);
    const [hideCompany, setHideCompany] = useState(false);
    const [hideStatus, setHideStatus] = useState(false);
    const [hideData, setHideData] = useState(false);

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
    const [data, setData] = useState([]);

    const [companyId, setCompanyId] = useState('');
    const [email, setEmail] = useState('');


    //se houver necessidade de por modal, só descomentar configurações da coluna da tabela. Lina 120 Está pronto
    const openModal = (content:any, title:string) =>
    {
        setStateModal(true);
        setModalTitle(title);
        setModalContent(content)
    }
    

    const fetchTerms = useCallback(async (page: number, _perPage : number, companyId : string, email : string) => 
    {
        if (perfil.toUpperCase().indexOf("GS1") >= 0 && companyId == '') {
            return;
        }


        if (page === 0) {
            return;
        }

        setLoading(true);
        const response = await termsOfUse(jwt, page, _perPage, companyId, email);
        let terms:any = [];
        let res = response.result.data;


        res.data[0].forEach((element:any, index:any) => 
        {
            if(
                element.tituloTermo !== '' 
                && element.usuario !== ''
                && element.email !== ''
                && element.empresa !== ''
                && element.data !== ''
                && element.htmlTermo !== ''
            ){
                let date = new Date(element.data);

                terms.push(
                {
                    titulo:element.tituloTermo,
                    usuario:element.usuario,
                    email:element.email,
                    empresa:element.empresa,
                    status:'Aceito',
                    data:date.toLocaleDateString('pt-BR'),
                    htmltermo:element.htmlTermo
                });
                
            }
        });


        setData(terms);
        setTotalRows(response.result.data.total);
        setLoading(false);

    },[jwt]);


    const getCompaniesByName = (name : string, callback: (options : []) => void) => {
        if (name == null || name.length < 3)
            callback([]);


        getUserGS1Companies(jwt, name).then(
            (responseUserGS1Companies: any) => {
                let companies: any = [];

                if (responseUserGS1Companies.success) {
                    responseUserGS1Companies.result.forEach((element: any, index: any) => {
                        companies.push({ value: element.id, label: element.nome });
                    })

                    callback(companies);

                }
                else {
                    showError(responseUserGS1Companies.message);
                }
            }
        )
    }



    const handlePageChange = (page : number, totalRows:number) => 
    {

       fetchTerms(page, perPage, companyId, email);
    };

    const handlePerRowsChange = async (_perPage: any, page : number) => 
    {

        setPerPage(_perPage)

        fetchTerms(1, _perPage, companyId, email);
	};

    useEffect(() => 
    {
        


        if(perfil.toUpperCase().indexOf("GS1") >= 0 && perfil.toUpperCase().indexOf("COLABORADOR") >= 0)
        {
            setHideCompany(false);
        }

        if(perfil.toUpperCase().indexOf("GS1") < 0 && perfil.toUpperCase().indexOf("ADMINISTRADOR") >= 0)
        {
            setHideCompany(true);
            setHideStatus(true);
            setHideUser(true);
        }


    }, [perfil]);

    function close() 
    {
        setStateModal(false)
    }


    const customStyles = {
        rows: {
            style: {
                minHeight: '42px', // override the row height
                fontSize:"12px",
                fontFamily: "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
            },
        },
        headCells: {
            style: {
                paddingLeft: '6px', // override the cell padding for head cells
                paddingRight: '6px',
                fontFamily: "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
                fontSize:"0.92rem",
                color:"#002c6c"
            },
        },
        cells: {
            style: {
                paddingLeft: '6px', // override the cell padding for data cells
                paddingRight: '6px',
            },
        },
      
    }; 
    

    const columns: TableColumn<TermUser>[] = React.useMemo(
        () =>[
            {
                name: 'Título',
                selector: row => row.titulo,
                cell: (row: any) => <OpenTermsModal {...row} />,
                grow:2,
                sortable: false,
                width:"300px"
            },
            {
                name: 'Usuário',
                selector: row => row.email,
                sortable: false,
                //omit: hideUser,
                width:"250px"
            },
            {
                name: 'Empresa',
                selector: row => row.empresa,
                sortable: false,
                omit: hideCompany,
                width:"170px"
            },
            {
                name: 'Status',
                selector: row => row.status,
                sortable: false,
                //omit: hideStatus,
                width:"90px",

            },
            {
                name: 'Data',
                selector: row => row.data,
                sortable: false,
                omit: hideData,
                width: "110px",
            }
        ],
        [hideCompany, hideData, hideStatus, hideUser],
    );

    const OpenTermsModal = (row : any) => 
    {
        return (
            <>
                <div onClick={() => openModal(row.htmltermo, row.titulo)} style={{cursor:"pointer", textDecoration:"underline", color:"#00799E"}}>
                  {row.titulo}
                </div> 
            </>
        )
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        fetchTerms(1, perPage, companyId, email)
      }
    return(
        <>
        <TemplateBackend>
            
            <div className="formTerms" style={{marginBottom:"5px", borderBottom:"solid 2px #ebebeb"}}>
                <h3 style={{marginTop:0}}>
                    Termos de Uso
                </h3>
            </div>

            {(perfil.toUpperCase().indexOf("GS1") >= 0) &&
                <div className="form-group">
                    <span className="form-label">Empresa</span>

                    <AsyncSelect
                        classNamePrefix="asyncSelect"
                        cacheOptions
                        defaultValue={{label:"Digite o código da empresa para buscar"}}
                        loadingMessage={() => "Carregando..."}
                        noOptionsMessage={(item) => item.inputValue.length > 3 ? "Nenhum item encontrado." : "Digite o código da empresa para buscar..."}
                        loadOptions={getCompaniesByName}
                        onChange={(item : any) => {
                            if(item) {
                                setCompanyId(item.value);
                                fetchTerms(1, perPage, item.value, email);
                            }
                        }
                        }
                        />
                </div>
            }

            {(perfil.toUpperCase().indexOf("GS1") >= 0 || perfil.toUpperCase().indexOf("ADMINISTRADOR") >= 0) &&
            (
                <>
            <form onSubmit={onFormSubmit}>
            <div className="form-group">
                <span className="form-label">Email</span>
                <input
                            value={email}
                            type="text"
                            className="form-control"
                            onChange={({ target }) => setEmail(target.value)}
                            placeholder={"Digite um email"}/>

            </div>
            <div className="col-12">
                <button className="findButton"  style={{float:'right'}}>
                    Buscar
                </button>
            </div>
            </form>
            </>
            )
            }



            <div className="tableTerms">

              
                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationServer
                    progressComponent={<Loading/>}
                    noDataComponent="Não há registros para exibir"
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationComponent={BootyPagination}
                />
                

            </div>

            
            <Modal
                    isOpen={stateModal}
                    onRequestClose={close}
                    ariaHideApp={false}
                    contentLabel="Selected Option"
                    style={{
                        overlay: {
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "#262626CC",
                            zIndex:1000
                        },
                        content: {
                            width: "45vw",
                            height: "80%",
                            marginTop: "5%",
                            marginBottom: 0,
                            marginLeft: "auto",
                            marginRight: "auto",

                            border: "1px solid #ccc",
                            background: "#fff",
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch",
                            borderRadius: "4px",
                            outline: "none",
                            padding: "20px",
                        },
                    }}
                >
                    <>
                        <div className="formUser" style={{marginBottom:"0px"}}>

                            <div className="headerform" style={{marginBottom:"20px"}}>
                                <span
                                    style={{color:"#767676", fontSize:"20px"}}
                                >
                                    {modalTitle}
                                </span>
                                <span
                                    onClick={() => setStateModal(false)}
                                    style={{ position: "absolute", right: "1%", top: "1%", fontSize:"16px" }}
                                >
                                    <IoMdClose />
                                </span>
                            </div>

                            <div className="line">
                                <span dangerouslySetInnerHTML={{__html:modalContent}}></span>
                            </div>

                        </div>
                    </>
                </Modal>

        </TemplateBackend>
        </>
    )
}

export default Terms;
