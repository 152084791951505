import axios from "axios"
import { getConfig, getHeader  } from "../config/config";


export async function recoverUser(id: string, token: string) : Promise<any>{

  try 
  {
    const response = await axios.get(
      getConfig().API_URL + `/recuperaUsuario/${id}`,
      getHeader(true,`Bearer ${token}`)
    )

    return response.data;

  } catch (error: any) 
  {
      console.log(error);
  }
  return null;

}