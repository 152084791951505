import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Root } from './native-state.dev';

let store={ 
    searchDone:false
};
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Root initial={store} /> 
    <App />
  </React.StrictMode>
);
