export function getCountryName(isoCode:string) : string {

    let c = countries.find( (e) => {
        return e.code === isoCode.toLocaleUpperCase();
    });

    if (c !== undefined)
        return c.name;

    return "";
}

interface ICountry {
    code: string;
    name: string;
}

const countries : Array<ICountry> = [
{code:"AF", name:"Afeganistão"},
{code:"ZA", name:"África do Sul"},
{code:"AX", name:"Ilhas Alanda"},
{code:"AL", name:"Albânia"},
{code:"DE", name:"Alemanha"},
{code:"AD", name:"Andorra"},
{code:"AO", name:"Angola"},
{code:"AI", name:"Anguilla"},
{code:"AQ", name:"Antárctida"},
{code:"AG", name:"Antígua e Barbuda"},
{code:"AN", name:"Antilhas Holandesas"},
{code:"SA", name:"Arábia Saudita"},
{code:"DZ", name:"Argélia"},
{code:"AR", name:"Argentina"},
{code:"AM", name:"Arménia"},
{code:"AW", name:"Aruba"},
{code:"AU", name:"Austrália"},
{code:"AT", name:"Austria"},
{code:"AZ", name:"Azerbaijão"},
{code:"BS", name:"Bahamas"},
{code:"BH", name:"Bahrein"},
{code:"BD", name:"Bangladesh"},
{code:"BB", name:"Barbados"},
{code:"BE", name:"Bélgica"},
{code:"BZ", name:"Belize"},
{code:"BJ", name:"Benim"},
{code:"BM", name:"Bermudas"},
{code:"BY", name:"Bielorrússia"},
{code:"BT", name:"Butão"},
{code:"BO", name:"Bolívia"},
{code:"BA", name:"Bósnia e Herzegovina"},
{code:"BW", name:"Botsuana"},
{code:"BV", name:"Ilha Bouvet"},
{code:"BR", name:"Brasil"},
{code:"BN", name:"Brunei"},
{code:"BG", name:"Bulgária"},
{code:"BF", name:"Burkina Faso"},
{code:"BI", name:"Burundi"},
{code:"KH", name:"Camboja"},
{code:"CM", name:"Camarões"},
{code:"CA", name:"Canadá"},
{code:"CV", name:"Cabo Verde"},
{code:"KY", name:"Ilhas Cayman"},
{code:"KZ", name:"Cazaquistão"},
{code:"TD", name:"Chade"},
{code:"CL", name:"Chile"},
{code:"CN", name:"China"},
{code:"CY", name:"Chipre"},
{code:"CX", name:"Ilha Christmas"},
{code:"CC", name:"Ilhas Cocos"},
{code:"CO", name:"Colômbia"},
{code:"KM", name:"Comores"},
{code:"CG", name:"Congo"},
{code:"CK", name:"Ilhas Cook"},
{code:"KP", name:"Coreia do Norte"},
{code:"KR", name:"Coreia do Sul"},
{code:"CR", name:"Costa Rica"},
{code:"CI", name:"Costa do Marfim"},
{code:"HR", name:"Croácia"},
{code:"CU", name:"Cuba"},
{code:"DK", name:"Dinamarca"},
{code:"DJ", name:"Djibouti"},
{code:"DM", name:"Dominica"},
{code:"EC", name:"Equador"},
{code:"EG", name:"Egito"},
{code:"SV", name:"El Salvador"},
{code:"AE", name:"Emirados Árabes Unidos"},
{code:"ER", name:"Eritreia"},
{code:"GB-SCT", name:"Escócia"},
{code:"SK", name:"Eslováquia"},
{code:"SI", name:"Eslovênia"},
{code:"ES", name:"Espanha"},
{code:"EN", name:"Estados Unidos"},
{code:"US", name:"Estados Unidos"},
{code:"UM", name:"Ilhas Menores Distantes dos Estados Unidos"},
{code:"EE", name:"Estônia"},
{code:"ET", name:"Etiópia"},
{code:"FO", name:"Ilhas Feroé"},
{code:"FJ", name:"Fiji"},
{code:"PH", name:"Filipinas"},
{code:"FI", name:"Finlândia"},
{code:"FR", name:"França"},
{code:"TF", name:"Terras Austrais e Antárticas Francesas"},
{code:"GA", name:"Gabão"},
{code:"GH", name:"Gana"},
{code:"GM", name:"Gâmbia"},
{code:"GE", name:"Geórgia"},
{code:"GS", name:"Ilhas Geórgia do Sul e Sandwich do Sul"},
{code:"GI", name:"Gibraltar"},
{code:"GD", name:"Granada"},
{code:"GR", name:"Grécia"},
{code:"GL", name:"Gronelândia"},
{code:"GP", name:"Guadalupe"},
{code:"GU", name:"Guam"},
{code:"GT", name:"Guatemala"},
{code:"GG", name:"Guernsey"},
{code:"GN", name:"Guiné"},
{code:"GW", name:"Guiné-Bissau"},
{code:"GQ", name:"Guiné Equatorial"},
{code:"GY", name:"Guiana"},
{code:"GF", name:"Guiana Francesa"},
{code:"HT", name:"Haiti"},
{code:"HM", name:"Ilha Heard e Ilhas McDonald"},
{code:"HN", name:"Honduras"},
{code:"HK", name:"Hong Kong"},
{code:"HU", name:"Hungria"},
{code:"YE", name:"Iémen"},
{code:"IN", name:"Índia"},
{code:"ID", name:"Indonésia"},
{code:"GB-ENG", name:"Inglaterra"},
{code:"IR", name:"Irã"},
{code:"IQ", name:"Iraque"},
{code:"IE", name:"Irlanda"},
{code:"GB-NIR", name:"Irlanda do Norte"},
{code:"IM", name:"Ilha de Man"},
{code:"IS", name:"Islândia"},
{code:"IL", name:"Israel"},
{code:"IT", name:"Itália"},
{code:"JM", name:"Jamaica"},
{code:"JP", name:"Japão"},
{code:"JE", name:"Jersey"},
{code:"JO", name:"Jordânia"},
{code:"KI", name:"Kiribati"},
{code:"XK", name:"Kosovo"},
{code:"KW", name:"Kuwait"},
{code:"LA", name:"Laos"},
{code:"LV", name:"Letônia"},
{code:"LS", name:"Lesoto"},
{code:"LB", name:"Líbano"},
{code:"LR", name:"Libéria"},
{code:"LY", name:"Líbia"},
{code:"LI", name:"Liechtenstein"},
{code:"LT", name:"Lituânia"},
{code:"LU", name:"Luxemburgo"},
{code:"MO", name:"Macau"},
{code:"MK", name:"Macedónia"},
{code:"MG", name:"Madagáscar"},
{code:"MY", name:"Malásia"},
{code:"MW", name:"Malaui"},
{code:"MV", name:"Maldivas"},
{code:"ML", name:"Mali"},
{code:"MT", name:"Malta"},
{code:"FK", name:"Ilhas Malvinas"},
{code:"MH", name:"Ilhas Marshall"},
{code:"MP", name:"Marianas Setentrionais"},
{code:"MA", name:"Marrocos"},
{code:"MQ", name:"Martinica"},
{code:"MR", name:"Mauritânia"},
{code:"MU", name:"Maurícia"},
{code:"YT", name:"Mayotte"},
{code:"MX", name:"México"},
{code:"MM", name:"Mianmar"},
{code:"FM", name:"Estados Federados da Micronésia"},
{code:"MD", name:"Moldávia"},
{code:"MC", name:"Mónaco"},
{code:"MN", name:"Mongólia"},
{code:"ME", name:"Montenegro"},
{code:"MS", name:"Montserrat"},
{code:"MZ", name:"Moçambique"},
{code:"NA", name:"Namíbia"},
{code:"NR", name:"Nauru"},
{code:"NP", name:"Nepal"},
{code:"NC", name:"Nova Caledônia"},
{code:"NZ", name:"Nova Zelândia"},
{code:"NI", name:"Nicarágua"},
{code:"NE", name:"Níger"},
{code:"NG", name:"Nigéria"},
{code:"NU", name:"Niue"},
{code:"NF", name:"Ilha Norfolk"},
{code:"NO", name:"Noruega"},
{code:"OM", name:"Omã"},
{code:"GB-WLS", name:"País de Gales"},
{code:"NL", name:"Países Baixos"},
{code:"PW", name:"Palau"},
{code:"PS", name:"Palestina"},
{code:"PA", name:"Panamá"},
{code:"PG", name:"Papua-Nova Guiné"},
{code:"PK", name:"Paquistão"},
{code:"PY", name:"Paraguai"},
{code:"PE", name:"Peru"},
{code:"PN", name:"Pitcairn"},
{code:"PF", name:"Polinésia Francesa"},
{code:"PL", name:"Polónia"},
{code:"PT", name:"Portugal"},
{code:"PR", name:"Porto Rico"},
{code:"QA", name:"Qatar"},
{code:"KE", name:"Quênia"},
{code:"KG", name:"Quirguistão"},
{code:"GB", name:"Reino Unido"},
{code:"IO", name:"Território Britânico do Oceano Índico"},
{code:"VG", name:"Ilhas Virgens Britânicas"},
{code:"CF", name:"República Centro-Africana"},
{code:"CZ", name:"República Checa"},
{code:"CD", name:"República Democrática do Congo"},
{code:"DO", name:"República Dominicana"},
{code:"TW", name:"Taiwan"},
{code:"RE", name:"Reunião"},
{code:"RO", name:"Romênia"},
{code:"RU", name:"Rússia"},
{code:"RW", name:"Ruanda"},
{code:"EH", name:"Saara Ocidental"},
{code:"SB", name:"Ilhas Salomão"},
{code:"WS", name:"Samoa"},
{code:"AS", name:"Samoa Americana"},
{code:"BL", name:"Saint-Barthélemy"},
{code:"SH", name:"Santa Helena (território)"},
{code:"LC", name:"Santa Lúcia"},
{code:"MF", name:"Saint Martin"},
{code:"KN", name:"São Cristóvão e Névis"},
{code:"PM", name:"São Pedro e Miquelão"},
{code:"VC", name:"São Vicente e Granadinas"},
{code:"SM", name:"São Marinho"},
{code:"ST", name:"São Tomé e Príncipe"},
{code:"SN", name:"Senegal"},
{code:"SL", name:"Serra Leoa"},
{code:"RS", name:"Sérvia"},
{code:"SC", name:"Seychelles"},
{code:"SG", name:"Singapura"},
{code:"SY", name:"Síria"},
{code:"SO", name:"Somália"},
{code:"LK", name:"Sri Lanka"},
{code:"SD", name:"Sudão"},
{code:"SS", name:"Sudão do Sul"},
{code:"SE", name:"Suécia"},
{code:"SZ", name:"Suazilândia"},
{code:"CH", name:"Suíça"},
{code:"SR", name:"Suriname"},
{code:"SJ", name:"Svalbard"},
{code:"TJ", name:"Tadjiquistão"},
{code:"TH", name:"Tailândia"},
{code:"TZ", name:"Tanzânia"},
{code:"TL", name:"Timor-Leste"},
{code:"TG", name:"Togo"},
{code:"TK", name:"Toquelau"},
{code:"TO", name:"Tonga"},
{code:"TT", name:"Trinidad e Tobago"},
{code:"TN", name:"Tunísia"},
{code:"TM", name:"Turcomenistão"},
{code:"TR", name:"Turquia"},
{code:"TC", name:"Turcas e Caicos"},
{code:"TV", name:"Tuvalu"},
{code:"UA", name:"Ucrânia"},
{code:"UG", name:"Uganda"},
{code:"VI", name:"Ilhas Virgens Americanas"},
{code:"UY", name:"Uruguai"},
{code:"UZ", name:"Uzbequistão"},
{code:"VU", name:"Vanuatu"},
{code:"VA", name:"Vaticano"},
{code:"VE", name:"Venezuela"},
{code:"VN", name:"Vietname"},
{code:"WF", name:"Wallis e Futuna"},
{code:"ZM", name:"Zâmbia"},
{code:"ZW", name:"Zimbábue"},
{code:"UK", name:"Reino Unido"},
]