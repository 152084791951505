/* eslint-disable @typescript-eslint/no-unused-expressions */

import { FormEvent, useState, useContext, useEffect, useCallback } from "react";
import Modal from "react-modal";
import { ModalContestationProps } from "./types";
import { attributes, me, problems } from "./util";
import { IoMdClose, IoIosCloseCircle } from "react-icons/io";

import { setContestation } from "../../services/setContestation";
import { LoginContext } from '../../contexts/login_context';
import {showError, showWait, dismissToast, showSucess} from '../../services/toast';


interface contestation {
  attribute: string
  problem: string
}

const getWindowDimensions = () =>
{
  const { innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height
  }
}

export function ModalContestation({
  handleCloseModalContestation,
  isOpenModalContestation,
  dadosGtin,
}: ModalContestationProps) {
  const [messageAttribute, setMessageAttribute] = useState<string[]>([""]);
  const [messageProblem, setMessageProblem] = useState<string[]>([""]);
  const [messageMe, setMessageMe] = useState("");
  const [messageComment, setMessageComment] = useState("");
  const [modalConfirmDimensions, setModalConfirmDimensions] = useState({w:'450px', h:'17vh'});
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [countChars, setCountChars] = useState(500);
  const [stateModal, setStateModal] = useState(false);
  const { jwt } = useContext(LoginContext);
  const [attributeList, setAttributeList] = useState<string[]>();

  
  function add(e: FormEvent) 
  {
    e.preventDefault();
    let tempMessage  = messageAttribute.filter( () => {return true});
    tempMessage.push("");
    setMessageAttribute(tempMessage);

    let tempMessage2  = messageProblem.filter( () => {return true});
    tempMessage2.push("");
    setMessageProblem(tempMessage2);
  }
  
  function remove(n:number)
  {
    let tempMessage2  = messageProblem.filter( () => {return true});
    tempMessage2.splice(n,1);
    setMessageProblem(tempMessage2);
    
    let tempMessage  = messageAttribute.filter( () => {return true});
    tempMessage.splice(n,1);
    setMessageAttribute(tempMessage);

  }


  useEffect( () => 
  {
    if (isOpenModalContestation) 
    {

      let attributeListTemp:string[] = [];
      
      attributes.forEach(element => 
      {
        if (element === "Marca adicional") 
        {
          if (dadosGtin.validations.marcaAdicional && dadosGtin.validations.idiomaMarcaAdicional) 
            attributeListTemp.push(element);
        } 
        else if (element === "Descrição adicional") 
        {
          if (dadosGtin.validations.descricaoAdicional && dadosGtin.validations.idiomaDescAdicional) 
            attributeListTemp.push(element);
        } 
        else if (element === "Url da imagem adicional") 
        {
          if (dadosGtin.validations.idiomaImagemAdicional && dadosGtin.validations.imagemAdicional) 
            attributeListTemp.push(element);
        } 
        else 
        {
          attributeListTemp.push(element);
        }
      });

      setAttributeList(attributeListTemp);
    }

    function handleResize()
    {
      setWindowDimensions(getWindowDimensions)
    }

    window.addEventListener("resize", handleResize);


    if(windowDimensions.width > 280 && windowDimensions.width < 420)
    {
      setModalConfirmDimensions({w:'100%', h:'35vh'});
    }
    else if(windowDimensions.width < 576 && windowDimensions.width > 420)
    {
      setModalConfirmDimensions({w:'80%', h:'27vh'});
    }
    else if(windowDimensions.width < 768 && windowDimensions.width > 576)
    {
      setModalConfirmDimensions({w:'450px', h:'27vh'});
    }
    else
    {
      setModalConfirmDimensions({w:'550px', h:'27vh'});
    }
    
    return () => window.removeEventListener("resize", handleResize);

    
  }, [isOpenModalContestation, windowDimensions.height, windowDimensions.width])



  function close() 
  {
    setStateModal(false)
  }


  function updateMessageAttribute(attr:string, index:number) 
  {

    if(attr === 'Qual o atributo?')
    {
      showError("Por favor, escolha um atributo");
      return;
    }

    let tempMessage  = messageAttribute.filter( () => {return true});
  
    if (tempMessage) 
    {
      if (tempMessage.length-1 < index ) 
      {
        tempMessage.push(attr);
      } 
      else 
      {
        tempMessage[index] = attr;
      }

      setMessageAttribute(tempMessage);
    }
  }

  function updateMessageProblem(attr:string, index:number) 
  {
    if(attr === 'Qual o problema?')
    {
      showError("Por favor, conte-nos sobre o problema");
      return;
    }

    let tempMessage  = messageProblem.filter( () => {return true});

    if (tempMessage) 
    {
      if (tempMessage.length-1 < index ) 
      {
        tempMessage.push(attr);
      } 
      else 
      {
        tempMessage[index] = attr;
      }
      setMessageProblem(tempMessage);
    }
  }

  
  async function handleSendContestation(e: { preventDefault: () => void }) 
  {
    e.preventDefault();  

    for (let i = 0; i < messageAttribute.length; i++)
    {
      if(messageAttribute[i] === '')
      {
        showError("Por favor, escolha um atributo");
        return;
      }

      if(messageProblem[i] === '')
      {
        showError("Por favor, conte-nos sobre o problema");
        return;
      }
    }
    

    if (
      messageAttribute.length < 1 ||
      messageProblem.length < 1 ||
      messageMe.length < 1
    ) {

      if(messageAttribute.length < 1)
      {
        showError("Por favor, escolha um atributo");
      }

      if(messageProblem.length < 1)
      {
        showError("Por favor, conte-nos sobre o problema");
      }
      if(messageMe.length < 1)
      {
        showError("Por favor, conte-nos sobre você");
      }
      
      return
    }

    
    let arr:Array<object> = [];
    for (let i = 0; i < messageAttribute.length; i++)
    {
      arr.push(
        {
          atributo:messageAttribute[i], 
          problema: messageProblem[i]
        }
      )
    }

    let data = 
    {
      gtin: dadosGtin.gtin,
      problemas: arr,
      setor: messageMe,
      comentario: messageComment
    }

    let toastId = showWait('Aguarde, enviando contestação...');

    const response: any = await setContestation(data, jwt)

    dismissToast(toastId);

    if(response.status === 201)
    {
      setMessageMe('');
      setMessageComment('');
      setMessageAttribute([]);
      setMessageProblem([]);
      handleCloseModalContestation;
      setStateModal(true)
    }

  }

  return (
    <Modal
      isOpen={isOpenModalContestation}
      onRequestClose={handleCloseModalContestation}
      ariaHideApp={false}
      contentLabel="Selected Option"
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "#262626CC",
          zIndex:1000
        },
        content: {
          maxWidth: "50vh",
          minWidth: "40vw",
          maxHeight: "70vw",
          minHeight: "70vh",
          //position: "absolute",
          /* top: "40px",
          left: "40px",
          right: "40px",
          bottom: "40px", */
          marginTop: 0,
          marginBottom: 0,
          marginLeft: "auto",
          marginRight: "auto",
          zIndex:1000,
          border: "1px solid #ccc",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
        },
      }}
    >
      <div className="boxModal">
        <span className="iconClose">
          <IoMdClose onClick={handleCloseModalContestation} />
        </span>
        <p
         style={{
          fontSize: "22px",
          padding: "0px",
          margin: "-5px 0 34px 10px"
        }}
        >
          Contestar os Dados do Produto
        </p>
        <form className="containerModal">
          <label className="iGtin">
          <span style={{margin:"0 0 -2px 0", color:"rgba(65, 67, 69, 1)", fontSize:"15px"}}>Qual GTIN está sendo contestado?</span>
            <input readOnly value={dadosGtin.gtin} style={{marginBottom:"10px", width:"100%"}} className="form-control"/>
          </label>
          <div className="children">
            <div className="c1">
              <span style={{margin:"0 0 -5px 10px", color:"rgba(136, 139, 141, 1)", fontSize:"15px"}}>Por favor, conte-nos sobre o problema...</span>

              {[...Array(messageAttribute.length)].map((x, i) => {
                return (
                  <>
                    <div className="m" key={Math.random().toString()}>
                      {i !== 0 ? (
                        <span
                          key={i}
                          onClick={() => remove(i)}
                          className="remove"
                        >
                          <IoIosCloseCircle/>
                        </span>
                      ) : (
                        ""
                      )}


                      {attributeList &&
                      <>
                      <select 
                        className="form-control"
                        onChange={(e) => updateMessageAttribute(e.target.value, i)}
                        defaultValue={'Qual o atributo?'}
                        value={messageAttribute[i]}
                        style={{
                          width: "98%",
                        }}
                      >
                        <option value="Qual o atributo?">
                          Qual o atributo?
                        </option>

                        {attributeList.map((op) => (
                          <option value={op} key={op+Math.random().toString()}>
                            {op}
                          </option>
                        ))}
                        
                      </select>
                      

                      <select 
                        className="form-control"
                        onChange={(e) => updateMessageProblem(e.target.value, i)}
                        defaultValue={'Qual o problema?'}
                        value={messageProblem[i]}
                        style={{
                          width: "98%",
                        }}
                      >
                        <option value="Qual o problema?">
                          Qual o problema?
                        </option>
                        {problems.map((op) => (
                          <option value={op} key={op+Math.random()}>
                            {op}
                          </option>
                        ))}
                      </select>
                      </>
                      }

                    </div>
                  </>
                )
              })}
              <div className="addItem">
                <button
                  className="btn btn-outline-primary"
                  onClick={add}
                  style={{margin:"0 0 10px 10px"}}
                >
                  Contestar outros atributos
                </button>
              </div>
            </div>
            <label 
              style={{margin:"-10px 0 10px 10px"}}
            >
             <span style={{margin:"0 0 5px 0", color:"rgba(136, 139, 141, 1)", fontSize:"15px", lineHeight:3}}>
               Conte-nos sobre você
             </span> 
              
              <select 
                className="form-control"
                onChange={(e) => setMessageMe(e.target.value)}
                defaultValue={"Eu sou..."}
                value={messageMe}
                style={{
                  width: "99%",
                }}
              >
                <option disabled value="Eu sou...">
                  Eu sou...
                </option>
                {me.map((op) => (
                  <option value={op} key={op+Math.random().toString()}>
                    {op}
                  </option>
                ))}
              </select>

            </label>

            <label
              style={{margin:"-15px 7px 10px 10px"}}
            >
              <span style={{margin:"0 0 5px 0", color:"rgba(65, 67, 69, 1)", fontSize:"15px", lineHeight:3}}>
                Comentários (opcional)
              </span>
              
              <textarea
                style={{width: "100%"}}
                onChange={(e) => setMessageComment(e.target.value)}
                //defaultValue={undefined}
                maxLength={500}
                rows={4}
                placeholder="Descreva seu problema"
                data-ls-module="charCounter" 
                className="form-control"
              ></textarea>
            </label>
            <span style={{fontSize:"13px", margin:"-25px 0 0 10px", color:"rgba(105, 109, 115, 1)"}}>Máximo de {countChars} caracteres</span>
          </div>
          <div className="childButton">
            <button
              onClick={handleSendContestation}
            >
              Enviar
            </button>
          </div>
        </form>
      </div>

      <Modal
        isOpen={stateModal}
        onRequestClose={close}
        ariaHideApp={false}
        contentLabel="Selected Option"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#262626CC",
            zIndex:1000
          },
          content: {
            maxWidth: modalConfirmDimensions.w,
            height: modalConfirmDimensions.h,
            marginTop: "15%",
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            zIndex:1000,
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
          <div style={{ padding: "0.5rem" }}>
            <span
              onClick={() => close()}
              style={{ position: "absolute", right: "7px", top: "7px" }}
            >
              <IoMdClose />
            </span>
            <div
              style={{ display: "flex", flexDirection: "column", rowGap: "1.3rem" }}
            >
              <span
                style={{
                  color: "#002C6C",
                  fontSize: "1.375rem",
                  marginBottom: "-15px",
                }}
              >
                Contestação enviada com Sucesso
              </span>
              <p
              style={{
                color: "#0c48a1",
                fontSize: "15px",
                marginBottom: "2px",
                fontWeight: "200",
              }}>
                Muito obrigado pelo seu envio. Nosso time analisará seu feedback.
              </p>

            </div>
          </div>

      </Modal> 

    </Modal>
  )
}
