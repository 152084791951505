import axios from "axios"
import { getConfig , getHeader } from "../config/config";


export async function termsOfUse(token: string, page:number, per_page:number, companyId : string, _email: string) : Promise<any>
{

  try 
  {
    const response = await axios.post(
      getConfig().API_URL + "/listagemTermoUso",
      {
        pagina: page,
        qtdRegistros:per_page,
        empresaID: companyId,
        email: _email
      },
      getHeader(true,`Bearer ${token}`)
    )

    return {success: true, message:'', result: response};

  } catch (error: any) 
  {
    if(error)
      return {success: false, message: error.message};
  }

  return {success:false, message: 'Erro desconhecido. Tente novamente mais tarde.', result: ''};

}