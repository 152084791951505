import React, { useContext, useEffect, useState } from "react";
import DefinePassword from "../../components/DefinePassword";
import TemplateBackend from "../../components/TemplateBackend";
import { LoginContext } from '../../contexts/login_context';
import { getAllProfiles } from '../../services/getAllProfiles'
import { getUserCompanies } from "../../services/getUserCompanies";

import Modal from "react-modal";

import { showError, showWait, dismissToast, showSucess } from '../../services/toast';

import { IoMdClose } from "react-icons/io";
import { Navigate } from "react-router-dom";

interface IUserData {
    nome: string,
    perfil: string,
    empresa: string,
    email: string,
}


const Profile = () => {
    const { name, jwt, perfil, empresa, email } = useContext(LoginContext);
    const [userCompanies, setUserCompanies] = useState<any[]>();
    const [profiles, setProfiles] = useState<any[]>();
    const [stateModal, setStateModal] = useState(false);


    function close() {
        setStateModal(false)
    }



    useEffect(() => 
    {

        if (userCompanies)
            return;

        getUserCompanies(jwt).then(
            (responseUserCompanies: any) => {
                let companies: any = [];

                if (responseUserCompanies.success) {
                    responseUserCompanies.result.forEach((element: any, index: any) => {
                        companies.push({ id: element.id, nome: element.nome });
                    })

                    setUserCompanies(companies);
                }
                else {
                    showError(responseUserCompanies.message);
                }

            }
        )

        getAllProfiles(jwt).then(
            (responseProfiles: any) => {
                //let profiles: any = [];

                if(responseProfiles.success)
                {
                    setProfiles(responseProfiles.result);
                }
            }
        )


    }, [jwt, userCompanies, profiles]);



    return (
        <>
            <TemplateBackend>


                <div className="formProfile">

                    <h3 style={{marginTop:0}}>Dados do usuário</h3>

                    <div className="form-group" style={{ width: '70%' }}>
                        <span className="form-label">Nome</span>
                        <input
                            value={name}
                            type="text"
                            className="form-control"
                            disabled />
                    </div>

                    <div className="form-group" style={{ width: '70%' }}>
                        <span className="form-label">E-mail</span>
                        <input
                            value={email}
                            type="text"
                            className="form-control"
                            disabled />
                    </div>

                    <div className="form-group" style={{ width: '70%' }}>
                        <span className="form-label">Perfil</span>
                        <input
                            value={perfil}
                            type="text"
                            className="form-control"
                            disabled />
                    </div>

                    {(perfil.indexOf("GS1") < 0) &&
                        <div className="form-group" style={{ width: '70%' }}>
                            <span className="form-label">Empresa</span>
                            <input
                            value={empresa}
                            type="text"
                            className="form-control"
                            disabled />
                        </div>
                    }

                    <div style={{ borderTop: 'solid 1px #ccc', padding: '30px 0 0 0', margin: '35px 0 2rem 0' }}>
                        <h3>
                            Alterar Senha
                        </h3>

                    </div>
                    <div>
                        <button onClick={() => setStateModal(true)}>
                            Alterar senha
                        </button>
                    </div>

                </div>




                <Modal
                    isOpen={stateModal}
                    onRequestClose={close}
                    ariaHideApp={false}
                    contentLabel="Selected Option"
                    style={{
                        overlay: {
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "#262626CC",
                            overflowY: "auto",
                            zIndex:1000
                        },
                        content: {
                            maxWidth: "320px",
                            minWidth: '320px',
                            width: '100%',
                            height: "500px",
                            marginTop: "2%",
                            marginBottom: 0,
                            marginLeft: "auto",
                            marginRight: "auto",
                            border: "1px solid #ccc",
                            background: "white",
                            opacity: 1,
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch",
                            borderRadius: "4px",
                            outline: "none",
                        },
                    }}
                >

                    <div className="formProfile" style={{ marginBottom: "0px" }}>

                        <div className="" style={{ marginBottom: "20px" }}>
                            <span
                                style={{ fontSize: "26px", color: "#002C6C" }}
                            >
                                <h3 style={{marginTop:0}}>Alterar senha</h3>
                            </span>
                            <span
                                onClick={() => setStateModal(false)}
                                style={{ position: "absolute", right: "1%", top: "1%", fontSize: "16px" }}
                            >
                                <IoMdClose />
                            </span>
                        </div>

                        <div className="">
                            <DefinePassword msgTitulo="" mode="perfil" token={jwt} cancel={() => setStateModal(false)} />
                        </div>


                    </div>

                </Modal>


            </TemplateBackend>
        </>
    )
}

export default Profile;

function rgb(arg0: number, arg1: number, arg2: number, arg3: number): import("csstype").Property.BackgroundColor | undefined {
    throw new Error("Function not implemented.");
}
