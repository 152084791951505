import { useCallback, useEffect, useState } from "react";
import { PaginationComponent } from "react-data-table-component/dist/src/DataTable/types";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import "./style.scss";


interface IPageComboProperties  {
    pageCount : number,
    onChangePage(event:any) : void, // available but not used here
}


const BootyPagination: PaginationComponent = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage, // available but not used here
    currentPage}) => { 



    //const [selfRowsPerPage, setSelfRowsPerPage] = useState(rowsPerPage);
    const pages = Math.ceil(rowCount/rowsPerPage);


    const pageItems= toPages(pages);

    const handleBackButtonClick = () => {
      onChangePage(currentPage - 1, rowCount);
    };
  
    const handleNextButtonClick = () => {
      onChangePage(currentPage + 1, rowCount);
    };
  
    const handlePageNumber = (e : any) => {
      onChangePage(Number(e.target.value), rowCount);
    };


  

    const PageCombo :  React.FC<IPageComboProperties> = ({ pageCount, onChangePage  }) => {
        const [isDisplay, setDisplay] = useState(false);
      
        let pages = [];
        for (let i = 1; i <= pageCount; i++) pages.push(i);
      
        return (
          <>
            <button
              className="page-link"
              onClick={() => setDisplay(!isDisplay)}
              value="comboSelectPage"
            >
              ...
            </button>
            {isDisplay && (
              <select
                className="select"
                onChange={(e) => {
                  setDisplay(false);
                  onChangePage(e);
                }}
              >
                {pages.map((item) => {
                  return <option value={item}>{item}</option>;
                })}
              </select>
            )}
          </>
        );
      };
      
    
      
    
    function toPages(pages : number) {
        const results = [];
    
        for (let i = 1; i <= pages; i++) {
        results.push(i);
        }
    
        return results;
    }
  

  
    return (
      <>
        {rowCount > rowsPerPage &&

          <nav className="datatablepagination">

            <div className="pageresults">

              <div className="shownumberperpage">
                Mostrar:
                <select onChange={ (e) => onChangeRowsPerPage(Number(e.target.value), 1)} className="select" defaultValue={rowsPerPage}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>

              </div>

              {rowCount} resultados

            </div>



            <ul className="custompagination">
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={handleBackButtonClick}
                  disabled={currentPage === 1}
                  aria-disabled={currentPage === 1}
                  aria-label="previous page"
                >
                  <FiArrowLeft style={{fontSize:20}}/>
                </button>
              </li>

              {pageItems.map((page) => {
                const className =
                  page === currentPage ? "page-item active" : "page-item";
      
                return (
                  <>
                    {page < 4 && (
                      <li key={ page + Math.random().toString() } className={className}>
                        <button
                          className="page-link"
                          onClick={handlePageNumber}
                          value={page}>{page}</button>
                      </li>
                    )}
      
                    {page === Math.round(pageItems.length / 2) &&
                      pageItems.length > 6 &&
                      currentPage >= Math.round(pageItems.length / 2) && (
                        <li key={ "combocomboPagina" + Math.random().toString() } className="page-item">
                          <PageCombo
                            pageCount={pageItems.length}
                            onChangePage={handlePageNumber}
                          />
                        </li>
                      )
                    }
      
                    {page === currentPage &&
                      page >= 4 &&
                      page <= pageItems.length - 3 && (
                        <li key={ page + Math.random().toString() } className={className}>
                          <button
                            className="page-link"
                            onClick={handlePageNumber}
                            value={page}>{page}</button>
                        </li>
                      )
                    }
      
                    {page === Math.round(pageItems.length / 2) &&
                      pageItems.length > 6 &&
                      currentPage < Math.round(pageItems.length / 2) && (
                        <li key={ "combocomboPagina" + Math.random().toString() } className="page-item">
                          <PageCombo
                            pageCount={pageItems.length}
                            onChangePage={handlePageNumber}
                          />
                        </li>
                      )
                    }
      
                    {page >= 4 && page > pageItems.length - 3 && (
                      <li key={ page+Math.random().toString() } className={className}>
                        <button
                          className="page-link"
                          onClick={handlePageNumber}
                          value={page}
                        >{page}</button>
                      </li>
                    )}
                  </>
                );
              })}

              <li className="page-item">

                <button
                  className="page-link page-end"
                  onClick={handleNextButtonClick}
                  disabled={currentPage === pageItems.length}
                  aria-disabled={currentPage === pageItems.length}
                  aria-label="next page">
                  <FiArrowRight style={{fontSize:20}}/>
                </button>

              </li>

            </ul>

          </nav>

        }
      </>
    );
  };

export default BootyPagination;