import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import About from '../../components/About';
import DefinePassword from '../../components/DefinePassword';

const ResetPassword = () => {

  const [showLogin, setShowLogin] = useState(true)

  function switchForm() {
    setShowLogin(!showLogin);
  }

  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");




  return (
    <>
      <div className="access">
        <div className="c_access">
          <div className="login_stage">
            <div className="content_c">
              <form className="boxForget">
                <h3>Recuperar Senha</h3>

                {token &&
                  <DefinePassword msgTitulo='Esqueci minha senha' mode="reset" token={token} cancel={() => { }} />
                }
              </form>
            </div>
          </div>
        </div>
      </div>

      <About />
    </>
  );
};

export default ResetPassword;