import axios from "axios"
import { getConfig } from "../config/config";


export async function requestAccess(email: string) {

  try 
  {
    const response = await axios.post(
      getConfig().API_URL + "/solicitaAcesso",
      {email:email},
    )

    return {sucesso:!response.data.erro, mensagem:response.data.msg};

  } catch (error: any) 
  {
    if  (error && error.response && error.response.data && error.response.data.erro)
      return {sucesso: false, mensagem: error.response.data.erro}

  }
  return {sucesso: false, mensagem: 'Erro desconhecido, tente novamente mais tarde.'};

}
