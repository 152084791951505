import { useContext, useState } from "react";
import { LoginContext } from '../../contexts/login_context';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ChooseSearch from '../search_select';
import Modal from "react-modal";
import { IoMdClose } from 'react-icons/io';
import { BrowserRouter } from 'react-router-dom';


const SearchOneApi = () => {
  const router = BrowserRouter
  const [activeTab, setActiveTab] = useState("tab1");
  const {name, jwt, perfil, empresa, email, empresaId, pesquisaRespondida, popupVbg} = useContext(LoginContext) 

  const { setLogin } = useContext(LoginContext);
  const [modalAtivo, setModalAtivo] = useState(popupVbg!!?popupVbg.modalAtivo:false);

  const handleTab = (tab: string) => {
    setActiveTab(tab);
  };

  const location = useLocation();

  useEffect(() => {
    console.log(location);
  }, [location]);

  
  const [isOpenModalFaq, setIsOpenModalFaq] = useState((!pesquisaRespondida)&& modalAtivo);
 
  function alreadyAnswered() {
    setLogin(name,perfil,jwt,empresa,email,empresaId, true, popupVbg);   
    closeModalFaq()
  }

  function openLink(){
    const win = window.open(popupVbg.linkPesquisa, '_blank');
    if (win != null) {
      win.focus();
    }
      
  }
  function closeModalFaq() {
    setIsOpenModalFaq(false);
  }

  return (

    <>
      <div className="more">
        <div className="more_group">
          <div className="container" style={{ paddingLeft: "0px", paddingRight: "0px", maxWidth: "1250px" }}>
            <div className="row">
              <div className="col-12 col-md-8 col-sm-12 item-content" style={{ paddingLeft: "115px", paddingRight: "0px" }}>
                <h3 style={{ color: "white", marginTop: "40px", marginBottom: "0px", fontSize: "32px" }}>Verified by GS1</h3>
                <h4 style={{ color: "white", marginTop: "12px", fontSize: "18px" }}>Verifique a identidade de produtos com base em informações confiáveis de donos de marcas</h4>
              </div>

              <div className="col-12 col-md-4 col-sm-12 item-content" style={{ textAlign: "right", paddingLeft: "0px", paddingRight: "0px" }}>
                <img src={require('../../../src/banner3.png')} style={{ maxWidth: "600px", maxHeight: "210px" }} className="banner2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="maxContentApi pb0">
        <div className="banner">
          <div className="container">
            <ChooseSearch handleTab={handleTab} activeTab={activeTab} multiple={false} />
            
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpenModalFaq}
        onRequestClose={closeModalFaq}
        ariaHideApp={false}
        contentLabel="Selected Option"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#262626CC",
            zIndex: 1000,
          },
          content: {
            width: "750px",
            height: "410px",
            marginTop: "5%",
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 1000,
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "20px",
            outline: "none",
            padding: "10px",
          },
        }}
      >
        <div style={{ padding: "0.5rem" }}>
          <span
            onClick={() => closeModalFaq()}
            style={{ position: "absolute", right: "7px", top: "7px" }}
          >
            <IoMdClose />
          </span>
          <div
            style={{ display: "flex", flexDirection: "column", rowGap: "2rem" }}
          >
           

              <div style={{
                color: "rgb(105, 109, 115)",
                marginBottom: "-15px",
              }}>
                <div className=' section '>
                  <div className='col-12 col-md-12 ' style={{ display: "flex", fontSize: "17px"}}>
                    <div className='col-6 col-md-12 table-responsive ' style={{textAlign:"left" , lineHeight:"1.3"}}>


                      {!!popupVbg &&
                        <div dangerouslySetInnerHTML={{ __html: popupVbg.textoTela }}/>
                      }

                      <div style={{textAlign:"right", marginTop:"30px"}}>
                        <button style={{width:"230px", marginRight:"36px"}}
                          className='btn btn-outline-primary '
                          onClick={alreadyAnswered}
                        >
                          Já respondi a pesquisa
                        </button>
                        <button style={{width:"230px"}}
                          className='btn btn-primary '
                          onClick={openLink}
                        >
                          Quero responder
                        </button>
                      </div>    
                    </div>
    
                  </div>
                </div>
              </div>
            {/* <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              className='pt-3'
            >

              <Table />

            </div> */}
          </div>
        </div>

      </Modal>     
    </>

  );


};

export default SearchOneApi;



