import axios from "axios"
import { getConfig } from "../config/config";

export async function recoverPassword(email: string) : Promise<any> {
  
  try 
  {
    const response = await axios.post(
      getConfig().API_URL + "/esqueciSenha",
      {email:email},
    )

    return {erro : response.data.erro, mensagem:response.data.msg};

  } catch (error: any) 
  {

  }
  return {erro: true, mensagem:"Erro desconhecido. Tente novamente mais tarde."}
}
