import { toast } from 'react-toastify';


export function showError(mensagem:string) {
    toast.error(mensagem, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
        });
}

export function showInfo(mensagem:string) {
    toast.info(mensagem, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
        });
}

export function showWait(mensagem:string = 'Aguarde, por favor...') {
    return toast.loading(mensagem, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        theme: "light"
    });

}

export function dismissToast(id : any) {
    toast.dismiss(id);

}


export function showSucess(mensagem:string) {
    toast.success(mensagem, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
        });
}
