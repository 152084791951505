import React from "react";
import './style.scss';

const Loading = () =>
{
    return(
        <div className="center">
            {/*<div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>*/  }
            <img src="./spinner.svg" alt="loading" width={30}/>
        </div>
    );

}

export default Loading