import axios from "axios"
import { getConfig } from "../config/config";


export async function changePass(data: any, token: string) 
{

  try 
  {
    const response = await axios.post(
      getConfig().API_URL + "/alteraSenha",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return {error:false, msg:'Senha alterada com sucesso'};

  } catch (error: any) 
  {
     if(error)
     {
        return {error: true, msg: error.response.data.msg}
     } 
  }

  return {error: true, msg: 'Erro desconhecido, tente novamente mais tarde.'};

}