import axios from "axios"
import { getConfig } from "../config/config";

export async function getProduct(code: any, token?: string) 
{
  
  try 
  {
    const response = await axios.post(
      getConfig().API_URL + "/consultaCodigo",
      code,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response;

  } 
  catch (error: any) 
  {

    let code = error.response.status;
    let erro = ""
    if (error.response.data.erro)
      erro = error.response.data.erro;
    else if (error.response.data.limiteConsulta)
      erro = error.response.data.limiteConsulta;

    
    let obj = 
      {
        code: code,
        msg: erro
      }

      return obj;
  }
  //return null;

}
