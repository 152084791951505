import React, { useCallback, useContext, useEffect, useState } from "react";
import TemplateBackend from "../../components/TemplateBackend";
import { LoginContext } from '../../contexts/login_context';
import { getUserCompanies } from "../../services/getUserCompanies";
import { activeUsers } from "../../services/activeUsers";
import { hitsPerDay } from "../../services/hitsPerDay";
import { consumptionPerDay } from "../../services/consumptionPerDay";
import { associatedCompanies } from "../../services/associatedCompanies";
import { hitsCompanyPerDay } from "../../services/hitsCompanyPerDay";
import { consumptionCompanyPerDay } from "../../services/consumptionCompanyPerDay";
import { companyActiveUsers } from "../../services/companyActiveUsers";
import Loading from "../../components/Loading";

import { showError, showWait, dismissToast, showSucess } from '../../services/toast';

import { newUsersPerDay } from "../../services/newUsersPerDay";
import { FaGalacticSenate } from "react-icons/fa";
import AsyncSelect from 'react-select/async';
import { getUserGS1Companies } from "../../services/getUserGS1Companies";
import { useSelector } from "../../native-state.dev";




const Dashboard = () => {
    const [modalObje,setState] = useSelector((s: { searchDone: any; })=>s.searchDone);    

    setState({searchDone:false});
    
    
    const { jwt, perfil, empresaId } = useContext(LoginContext);
    const [activeusers, setActiveusers] = useState<number>(-1);
    const [hitsperday, setHitsperday] = useState<number>(-1);
    const [newusersperday, setNewusersperday] = useState<number>(-1);
    const [consumptionday, setConsumptionday] = useState<number>(-1);
    const [associated, setAssociated] = useState<number>(-1);
    const [companyhitsday, setCompanyhitsday] = useState<number>(-1);
    const [consumptioncompanyday, setConsumptioncompanyday] = useState({ used: 0, limit: 0 });
    const [companyusers, setCompanyusers] = useState<number>(-1);
    const [showloading, setShowLoading] = useState(false);
    const [filterDate, setFilterDate] = useState(new Date().toISOString().substring(0, 10))
    const [filterDateCompany, setFilterDateCompany] = useState(new Date().toISOString().substring(0, 10))
    const [filterCompany, setFilterCompany] = useState("0")

    function loadDataCompany(empresa: string, dataPesquisa: string) {
        if (empresa !== '0') {
            setShowLoading(true);
            setCompanyhitsday(-1);
            setConsumptioncompanyday({ used: 0, limit: 0 });
            setCompanyusers(-1);

            //const rescompanyday = await hitsCompanyPerDay(event, jwt);
            //const consumption = await consumptionCompanyPerDay(event, jwt);
            //const resuserscompany = await companyActiveUsers(event, jwt);


            hitsCompanyPerDay(jwt, empresa, dataPesquisa).then((rescompanyday: any) => {
                if (rescompanyday.success) {
                    setCompanyhitsday(rescompanyday.result);
                }
                else
                    showError(rescompanyday.message);
            });


            consumptionCompanyPerDay(jwt, empresa, dataPesquisa).then((consumption: any) => {
                if (consumption) {
                    setConsumptioncompanyday({ used: consumption.result.consumoDiaPorEmpresa, limit: consumption.result.limiteEmpresa });
                    setShowLoading(false);
                }
                else
                    showError(consumption.message);

            });

            companyActiveUsers(jwt, empresa).then((resuserscompany) => {
                if (resuserscompany) {
                    setCompanyusers(resuserscompany.result);
                }
                else
                    showError(resuserscompany.message);

            })

        }
        else {
            setCompanyhitsday(0);
            setConsumptioncompanyday({ used: 0, limit: 0 });
            setCompanyusers(0);
            setShowLoading(false);
        }

    }


    function loadData(dataPesquisa: string) {
        setConsumptionday(-1)
        setHitsperday(-1)
        setNewusersperday(-1)



        consumptionPerDay(jwt, dataPesquisa).then(
            (consumption: any) => {
                if (consumption.success)
                    setConsumptionday(consumption.result);
                else
                    showError(consumption.message);
            }
        )


        hitsPerDay(jwt, dataPesquisa).then(
            (hits: any) => {
                if (hits.success)
                    setHitsperday(hits.result);
                else
                    showError(hits.message);
            }
        )

        newUsersPerDay(jwt, dataPesquisa).then(
            (hits: any) => {
                if (hits.success)
                    setNewusersperday(hits.result);
                else
                    showError(hits.message);
            }
        )




    }


    useEffect(() => {

        if (perfil.indexOf("GS1") >= 0) {
            setAssociated(-1);
            setActiveusers(-1);

            /*
            associatedCompanies(jwt).then(
                (associatedCompany: any) => {
                    if (associatedCompany.success)
                        setAssociated(associatedCompany.result);
                    else
                        showError(associatedCompany.message);
                }
            )

            
            activeUsers(jwt).then(
                (users: any) => {
                    if (users.success)
                        setActiveusers(users.result);
                    else
                        showError(users.message);
                }
            )
            */

        } else {
            setFilterCompany(empresaId);
        }

       

    }, [])


    useEffect(() => {
        if (perfil.indexOf("GS1") >= 0) {
            loadData(filterDate);
        }

    }, [filterDate]);


    useEffect(() => {
        loadDataCompany(filterCompany, filterDateCompany);

    }, [filterDateCompany, filterCompany]);


    const getCompaniesByName = (name : string, callback: (options : []) => void) => {
        if (name == null || name.length < 3)
            callback([]);


        getUserGS1Companies(jwt, name).then(
            (responseUserGS1Companies: any) => {
                let companies: any = [];

                if (responseUserGS1Companies.success) {
                    responseUserGS1Companies.result.forEach((element: any, index: any) => {
                        companies.push({ value: element.id, label: element.nome });
                    })

                    callback(companies);

                }
                else {
                    showError(responseUserGS1Companies.message);
                }
            }
        )
    }



    return (
        <>
            <TemplateBackend>

                {perfil.indexOf("GS1") >= 0 &&
                    <>
                        <div className="row">

                            <div className="col-12 col-md-8 col-lg-8 col-sm-12 mb-4 mb-sm-4">
                                <h3 style={{marginTop:0}}>Dados consolidados do dia</h3>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4 col-sm-12 mb-4 mb-sm-2 float-md-right">
                                <input onChange={(event) => setFilterDate(event.target.value)} lang="pt-BR" id="data" name="data" className="datepicker" defaultValue={filterDate} type="date" style={{float:'right'}}/>
                            </div>

                            
                            <div className="col-12 col-md-6 mb-4">
                                <div className="ms-webpart-zone ms-fullWidth">
                                    <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                                        <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                                            <div className="ms-WPBody noindex ">
                                                <div className="ms-rte-embedcode ms-rte-embedwp">
                                                    <div className="statistic-tile">
                                                        <div className="number-statistic gs1-color-sky">
                                                            {newusersperday < 0 &&
                                                                <>
                                                                    <Loading />
                                                                </>
                                                            }
                                                            {newusersperday >= 0 &&
                                                                newusersperday
                                                            }
                                                        </div>
                                                        <div className="description-statistic">Usuários</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-12 col-md-5 col-lg-4 mb-4">
                                <div className="ms-webpart-zone ms-fullWidth">
                                    <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                                        <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                                            <div className="ms-WPBody noindex ">
                                                <div className="ms-rte-embedcode ms-rte-embedwp">
                                                    <div className="statistic-tile">
                                                        <div className="number-statistic gs1-color-sky">
                                                            {hitsperday < 0 &&
                                                                <>
                                                                    <Loading />
                                                                </>
                                                            }

                                                            {hitsperday >= 0 &&
                                                                `${hitsperday}`
                                                            }
                                                        </div>
                                                        <div className="description-statistic">Total de acessos no dia</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-4 mb-4">
                                <div className="ms-webpart-zone ms-fullWidth">
                                    <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                                        <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                                            <div className="ms-WPBody noindex ">
                                                <div className="ms-rte-embedcode ms-rte-embedwp">
                                                    <div className="statistic-tile">
                                                        <div className="number-statistic gs1-color-sky">
                                                            {consumptionday < 0 &&
                                                                <>
                                                                    <Loading />
                                                                </>
                                                            }
                                                            {consumptionday >= 0 &&
                                                                `${consumptionday}`
                                                            }
                                                        </div>
                                                        <div className="description-statistic">Total de consumo no dia</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        {false &&

                        <div className="row mb-4 pb-5" style={{ borderBottom: 'solid 1px #ebebeb' }}>

                            <div className="col-12">
                                <h4>Dados de cadastros</h4>
                            </div>

                            <div className="col-12 col-md-5 mb-4">
                                <div className="ms-webpart-zone ms-fullWidth">
                                    <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                                        <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                                            <div className="ms-WPBody noindex ">
                                                <div className="ms-rte-embedcode ms-rte-embedwp">
                                                    <div className="statistic-tile">
                                                        <div className="number-statistic gs1-color-sky">
                                                            {associated < 0 &&
                                                                <>
                                                                    <Loading />
                                                                </>
                                                            }
                                                            {associated >= 0 &&
                                                                associated
                                                            }
                                                        </div>
                                                        <div className="description-statistic">Empresas associadas</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-5 mb-4">
                                <div className="ms-webpart-zone ms-fullWidth">
                                    <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                                        <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                                            <div className="ms-WPBody noindex ">
                                                <div className="ms-rte-embedcode ms-rte-embedwp">
                                                    <div className="statistic-tile">
                                                        <div className="number-statistic gs1-color-sky">
                                                            {activeusers < 0 &&
                                                                <>
                                                                    <Loading />
                                                                </>
                                                            }
                                                            {activeusers >= 0 &&
                                                                activeusers
                                                            }
                                                        </div>
                                                        <div className="description-statistic"> Usuários ativos</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        }
                    </>

                }

                <div className="row pt-3">

                    <div className="col-12 col-md-8 col-lg-8 py-2 py-sm-2">
                        <h4 style={{marginTop:0}}>
                            Dados Consolidados por Empresas Associadas
                        </h4>
                    </div>

                    <div className="col-12 col-md-4 col-lg-4 py-2 float-md-right">
                        <input onChange={(event) => setFilterDateCompany(event.target.value)} type="date" id="data" name="data" className="datepicker" defaultValue={filterDateCompany} style={{float:'right'}}/>
                    </div>

                    {perfil.indexOf("GS1") >= 0 &&
                        <div className="col-12 mb-5 mt-4 pt-3" style={{width:'100%'}}>
                        <AsyncSelect
                                            classNamePrefix="asyncSelect"
                                            cacheOptions
                                            defaultValue={{label:"Digite o código da empresa para buscar"}}
                                            loadingMessage={() => "Carregando..."}
                                            noOptionsMessage={(item) => item.inputValue.length > 3 ? "Nenhum item encontrado." : "Digite o código da empresa para buscar..."}
                                            loadOptions={getCompaniesByName}
                                            onChange={(item : any) => {
                                                if(item) {
                                                    setFilterCompany(item.value);
                                                }
                                            }
                                            }
                                            />
                        </div>

                    }

                        <div className="col-12 col-md-5 mb-4">
                            <div className="ms-webpart-zone ms-fullWidth">
                                <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                                    <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                                        <div className="ms-WPBody noindex ">
                                            <div className="ms-rte-embedcode ms-rte-embedwp">
                                                <div className="statistic-tile">
                                                    <div className="number-statistic gs1-color-sky">
                                                        {(companyhitsday < 0) &&
                                                            <>
                                                                <Loading /> / <Loading />
                                                            </>
                                                        }
                                                        {(companyhitsday >= 0) &&

                                                            `${companyhitsday}`
                                                        }
                                                    </div>
                                                    <div className="description-statistic">Total de acessos no dia</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-5 mb-4">
                            <div className="ms-webpart-zone ms-fullWidth">
                                <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                                    <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                                        <div className="ms-WPBody noindex ">
                                            <div className="ms-rte-embedcode ms-rte-embedwp">
                                                <div className="statistic-tile">
                                                    <div className="number-statistic gs1-color-sky">
                                                        {(showloading) &&
                                                            <>
                                                                <Loading /> / <Loading />
                                                            </>

                                                        }
                                                        {(!showloading) &&
                                                            `${consumptioncompanyday.used} / ${consumptioncompanyday.limit}`
                                                        }
                                                    </div>
                                                    <div className="description-statistic">Total de consumo no dia</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-5 mb-4">
                            <div className="ms-webpart-zone ms-fullWidth">
                                <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                                    <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                                        <div className="ms-WPBody noindex ">
                                            <div className="ms-rte-embedcode ms-rte-embedwp">
                                                <div className="statistic-tile">
                                                    <div className="number-statistic gs1-color-sky">
                                                        {(companyusers < 0) &&
                                                            <>
                                                                <Loading />
                                                            </>
                                                        }
                                                        {(companyusers >= 0) &&
                                                            companyusers
                                                        }
                                                    </div>
                                                    <div className="description-statistic">Usuários ativos</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                </div>

            </TemplateBackend>
        </>
    )
}

export default Dashboard;