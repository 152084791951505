import React, { useContext, useEffect, useState, useCallback } from "react";
import TemplateBackend from "../../components/TemplateBackend";
import DataTable, { TableColumn } from 'react-data-table-component';
import Modal from "react-modal";
import { LoginContext } from '../../contexts/login_context';
import { getUserType } from "../../services/getUserType";
import { getUserCompanies } from "../../services/getUserCompanies";
import { getUserGS1Companies, getUserGS1CompaniesById } from "../../services/getUserGS1Companies";
import { getUsers } from "../../services/getUsers";
import { insertUser } from "../../services/insertUser";
import { recoverUser } from "../../services/recoverUser";
import { updateUser } from "../../services/updateUser";
import { deleteUser } from "../../services/deleteUser";
import { getAllProfiles } from "../../services/getAllProfiles";
import { getProfile } from "../../services/getProfile";
import { getGS1CompaniesUsers } from "../../services/getGS1CompaniesUsers";
import { maskCpfCnpj, validateCNPJ, validateCPF } from "../../helpers/maskcpfcnpj";
import { showError, showWait, dismissToast, showSucess } from '../../services/toast';
import Loading from "../../components/Loading";
import AsyncSelect from 'react-select/async';

import { IoMdClose } from "react-icons/io";
import BootyPagination from "../../components/Pagination";
import { ClearIndicator } from "react-select/dist/declarations/src/components/indicators";
import { reduceEachLeadingCommentRange } from "typescript";
import { StylesConfig } from "react-select";


interface IDataUser {
    id: string;
    nome: string;
    email: string;
    empresa: string;
    tipo: string;
    perfil: string;
    status: string;
    acao: string;
}

interface IDataProfile {
    nome: string;
    descricao: string;
    perfil: string;
    qtd: number;
}

interface IFormError {
    semail?: string,
    sname?: string,
    scpf?: string,
    sdocumento?: string,
    sdepartamento?: string,
    scargo?: string
}


const User = () => {
    const [activetab, setActiveTab] = useState(1);
    const { jwt, perfil } = useContext(LoginContext);
    const [profile, setProfile] = useState<any[]>();
    const [userType, setUserType] = useState<any[]>();
    const [userCompanies, setUserCompanies] = useState<any[]>();
    
    const [listUsers, setListUsers] = useState<any[]>();
    const [listUsersSearch, setListUsersSearch] = useState<any[]>();

    const [listAllProfiles, setListAllProfiles] = useState<any[]>();
    const [stateModal, setStateModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [getId, setGetId] = useState('');
    const [showButtonConfirm, setShowButtonConfirm] = useState(false);
    const [userLoading, setUserLoading] = useState(false);
    const [profileLoading, setProfileLoading] = useState(false);

    //Cadastro
    const [nome, setNome] = useState('');
    const [documento, setDocumento] = useState('');
    const [departamento, setDepartamento] = useState('');
    const [cargo, setCargo] = useState('');
    const [email, setEmail] = useState('');
    const [perfilregister, setPerfilRegister] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [empresaNome, setEmpresaNome] = useState('');
    const [status, setStatus] = useState('');

    const [doubleValidation, setDoubleValidation] = useState(false);
    const [isChange, setIsChange] = useState(false);
    const [getStatusValue, setGetStatusValue] = useState('');
    const [currentUserStatus, setCurrentUserStatus] = useState('');
    const [companyid, setCompanyId] = useState('');
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);

    //Busca
    const [sname, setSname] = useState('');
    const [semail, setSemail] = useState('');
    const [sstatus, setSstatus] = useState('');
    const [stype, setStype] = useState('');
    const [sprofile, setSprofile] = useState('');
    const [scompany, setScompany] = useState('');

    // Validação
    const [sdepartamento, setSdepatarmento] = useState('');
    const [scargo, setScargo] = useState('');
    const [sdocumento, setSdocumento] = useState('');


    const [formError, setFormError] = useState<IFormError>({});


    const [userid, setUserid] = useState('');
    const [hideColumnCompany, setHideColumnCompany] = useState(false);
    const [hideColumnType, setHideColumnType] = useState(false);

    const line_width = (userid) ? '100%' : '50%';
    const button_text = (userid) ? 'Salvar' : 'Cadastrar';
    const modal_header_text = (userid) ? 'Edição de Usuário' : 'Cadastro de Usuário';
    const lock_combo_company = (userid) ? true : false;



    const findErrors = () => 
    {
        const e = {} as IFormError
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(semail)) e.semail = 'error'
        if (!sname) {
            e.sname = 'error'
        } else {
            e.sname = ''
        }
        if (!sdocumento) e.sdocumento = 'error'
        if (!scargo) e.scargo = 'error'
        if (!sdepartamento) e.sdepartamento = 'error'

        setFormError(e)
    }


    const findUser = () => {
        let retorno = [];

        if (listUsersSearch) {
            retorno = listUsersSearch.filter(d =>
                d.nome.toUpperCase().indexOf(sname.toUpperCase()) >= 0 &&
                d.email.toUpperCase().indexOf(semail.toUpperCase()) >= 0 &&
                ((sstatus !== 'Todos') ? d.status.indexOf(sstatus) >= 0 : d.status.indexOf(!'Todos')) &&
                ((stype !== 'Todos') ? d.tipo.indexOf(stype) >= 0 : d.tipo.indexOf(!'Todos')) &&
                ((sprofile !== 'Todos') ? d.perfil.indexOf(sprofile) >= 0 : d.perfil.indexOf(!'Todos')) &&
                ((scompany !== 'Todos') ? d.empresa.indexOf(scompany) >= 0 : d.empresa.indexOf(!'Todos'))
            );


            setListUsers(retorno);
        }
    }


    const cancelAlert = (): void => {
        setShowAlert(false);
        setShowButtonConfirm(false);

        if (isChange) {
            (getStatusValue === currentUserStatus) ? setStatus(currentUserStatus) : setStatus(getStatusValue);
        }
    }


    const openAlert = (msg: string, id: string | null = null, has_double_validation: boolean = false, is_change: boolean = false): void => {
        setAlertMessage(msg);
        setIsChange(is_change);
        setDoubleValidation(has_double_validation);
        setShowAlert(true);

        if (id !== null) {
            setGetId(id);
        }
    }


    const continueExclusion = (msg: string): void => {
        setAlertMessage(msg);
        setShowButtonConfirm(true);
        setIsChange(false);
    }


    const confirmExclusion = (): void => {
        cancelAlert();
        delUser(getId);
        setIsChange(false);
    }


    const confirmEnd = (): void => {
        cancelAlert();

        if (!isChange) {
            close();
        }
        else {
            setStatus(getStatusValue);
        }
    }


    const changeUserStatus = (msg: string, value: string) => {
        openAlert(msg, null, false);
        setIsChange(true);
        setGetStatusValue(value);
    }


    const delUser = async (id: string) => {
        let toastId = showWait('Aguarde, excluindo usuário.');

        const response = await deleteUser(jwt, id);

        dismissToast(toastId);

        if (response.success) {
            showSucess(response.message);
            getAllUsers(companyid);

            /*let retorno = [];

            if(listUsersSearch)
            {
                retorno = listUsersSearch.filter(d => 
                    d.id !== id
                );

                setListUsers(retorno);
            } */

        }
        else {
            showError(response.message);
        }
    }

    const clearFormData = () => {
        setNome('');
        setDepartamento('');
        setDocumento('');
        setCargo('');
        setEmail('');
        setPerfilRegister('');
        setEmpresa('');
        setUserid('');
    }


    const getAllUsers = useCallback((companyId: string = '') => {
        setListUsers([]);
        setListUsersSearch([]);
        setUserLoading(true);

        if (companyId === '') {
            setCompanyId('');
            getUsers(jwt, null, null, null, null, null, null).then(
                (responseUsers: any) => {
                    let users: any = [];

                    if (responseUsers.success) {
                        responseUsers.result.forEach((element: any, index: any) => {
                            users.push(
                                {
                                    id: element.id,
                                    email: element.email,
                                    empresa: element.empresa,
                                    nome: element.nome,
                                    perfil: element.perfil,
                                    status: element.status,
                                    tipo: element.tipo
                                });
                        });
                        setUserLoading(false);
                        setListUsers(users);
                        setListUsersSearch(users);
                    }
                    else {
                        showError(responseUsers.message);
                        setListUsers([]);
                        setUserLoading(false);
                    }
                }
            )
        }
        else {
            if (companyId !== '') {

                setCompanyId(companyId);
                getGS1CompaniesUsers(jwt, companyId).then(
                    (responseUsers: any) => {
                        let users: any = [];

                        if (responseUsers.success) {
                            responseUsers.result.forEach((element: any, index: any) => {
                                users.push(
                                    {
                                        id: element.id,
                                        email: element.email,
                                        empresa: element.empresa,
                                        nome: element.nome,
                                        perfil: element.perfil,
                                        status: element.status,
                                        tipo: element.tipo
                                    });
                            });

                            setUserLoading(false);
                            setListUsers(users);
                            setListUsersSearch(users);
                        }
                        else {
                            showError(responseUsers.message);
                            setUserLoading(false);
                            setListUsers([]);
                        }
                    }
                )
            }

        }

    }, [jwt]);


    const handleSubmit = async () => {

        setDisableSubmitButton(true);

        if (email) setEmail(email.trim());
        if (nome) setNome(nome.trim());
        if (documento) setDocumento(documento.trim());
        if (cargo) setCargo(cargo.trim());
        if (departamento) setDepartamento(departamento.trim());

        findErrors()
        if (nome.trim() === '') {
            showError('O campo NOME é obrigatório');
            setDisableSubmitButton(false);
            return;
        }

        if (documento.trim() === '') {
            showError('O campo CPF/CNPJ é obrigatório');
            setDisableSubmitButton(false);
            return;
        }
        else if (documento.trim().length > 14 && validateCNPJ(documento.trim()) === false) {
            showError('O campo CPF/CNPJ possui um CNPJ inválido');
            setDisableSubmitButton(false);
            return;
        }
        else if (documento.trim().length <= 14 && validateCPF(documento.trim()) === false) {
            showError('O campo CPF/CNPJ possui um CPF inválido');
            setDisableSubmitButton(false);
            return;
        }

        if (email.trim() === '') {
            showError('O campo E-MAIL é obrigatório');
            setDisableSubmitButton(false);
            return;
        }
        else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.trim())) {
            showError('E-mail inválido');
            setDisableSubmitButton(false);
            return;
        }

        if (perfilregister === '') {
            showError('O campo PERFIL é obrigatório');
            setDisableSubmitButton(false);
            return
        }

        let toastId = showWait('Aguarde, enviando dados.');

        if (!userid) {

            let data =
            {
                nome: nome.trim(),
                senha: '',
                documento: documento.trim(),
                tipoUsuario: 'associado',
                cargo: (cargo) ? cargo.trim() : '',
                departamento: (departamento) ? departamento.trim() : '',
                perfilID: perfilregister,
                email: email.trim(),
                empresaID: empresa
            }

            const response: any = await insertUser(data, jwt);
            dismissToast(toastId);
            setDisableSubmitButton(false);

            if (response.success) {
                showSucess(response.message);
                clearFormData();
                getAllUsers(companyid);
                setStateModal(false);
            }
            else {
                showError(response.message.emailExistente);
            }
        }
        else {
            let data = {};

            if (perfil.toUpperCase().indexOf("ADMINISTRADOR") >= 0) {
                data =
                {
                    id: userid,
                    nome: nome.trim(),
                    documento: documento.trim(),
                    departamento: (departamento) ? departamento.trim() : '',
                    cargo: (cargo) ? cargo.trim() : '',
                    email: email.trim(),
                    perfilID: perfilregister,
                    status: (status) ? status : '',
                }
            }
            else {
                data =
                {
                    id: userid,
                    nome: nome.trim(),
                    documento: documento.trim(),
                    departamento: (departamento) ? departamento.trim() : '',
                    cargo: (cargo) ? cargo.trim() : '',
                    email: email.trim(),
                    perfilID: perfilregister,
                }
            }

            const response = await updateUser(data, jwt);
            dismissToast(toastId);
            setDisableSubmitButton(false);

            if (response.success) {

                getAllUsers(companyid);
                setStateModal(false);
                showSucess(response.message);
            }
            else {
                showError(response.message);
            }

        }
        setDisableSubmitButton(false);

    }


    const editUser = async (id: string) => {
        setDisableSubmitButton(false);
        let waitId = showWait('Aguarde, enquanto realizamos a consulta.');

        const response = await recoverUser(id, jwt);

        let userdata = response[0];
        setUserid(id);


        setNome(userdata.nome);
        setDocumento(maskCpfCnpj(userdata.documento));
        setDepartamento(userdata.departamento);
        setCargo(userdata.cargo);
        setEmail(userdata.email);
        setPerfilRegister(userdata.perfilId);
        setStatus(userdata.status);
        setCurrentUserStatus(userdata.status);
        setEmpresa(userdata.empresaID);
        setEmpresaNome(userdata.empresa);

        dismissToast(waitId);

        setStateModal(true);
    }




    const close = () => {
        setStateModal(false)
    }


    const openModal = () => {
        setDisableSubmitButton(false);
        clearFormData();
        setStateModal(true);
    }



    useEffect(() => {

        if (!userType) {
            setUserType([])
            getUserType(jwt).then(
                (responseUserType: any) => {
                    let uType: any = [];

                    if (responseUserType.success) {
                        responseUserType.result.forEach((element: any, index: any) => {
                            uType.push({ id: element, nome: element });
                        })

                        setUserType(uType);
                    }
                    else {
                        showError(responseUserType.message);
                    }

                }
            )
        }

        if (!userCompanies && perfil.toUpperCase().indexOf("ADMINISTRADOR") >= 0 && perfil.toUpperCase().indexOf("GS1") < 0) {
            setUserCompanies([]);
            getUserCompanies(jwt).then(
                (responseUserCompanies: any) => {
                    let companies: any = [];

                    if (responseUserCompanies.success) {
                        responseUserCompanies.result.forEach((element: any, index: any) => {
                            companies.push({ id: element.id, nome: element.nome });
                        })

                        setUserCompanies(companies);
                    }
                    else {
                        showError(responseUserCompanies.message);
                    }
                }
            )
        }


        if(!listUsers && perfil.toUpperCase().indexOf("ADMINISTRADOR") >= 0 && perfil.toUpperCase().indexOf("GS1") < 0) 
        {
            getAllUsers(companyid);
        }

        if (!profile) {
            setProfile([]);
            setProfileLoading(true);

            getProfile(jwt).then(
                (responseProfile: any) => {
                    let temp: any = [];

                    if (responseProfile.success) {
                        responseProfile.result.forEach((element: any, index: any) => {
                            temp.push({ id: element.id, nome: element.nome });
                        });

                        setProfile(temp);
                    }
                    else {
                        showError(responseProfile.message);
                    }

                    setProfileLoading(false);
                }
            );
        }

        if (!listAllProfiles) {
            setListAllProfiles([]);
            setProfileLoading(true);

            getAllProfiles(jwt).then(
                (responseProfiles: any) => {
                    if (responseProfiles.success) {
                        setListAllProfiles(responseProfiles.result);
                    }
                    else {
                        showError(responseProfiles.message);
                    }

                    setProfileLoading(false);
                }
            )
        }

        if (perfil.indexOf("GS1") >= 0) {
            setHideColumnCompany(false);
            setHideColumnType(false);
        }
        else {
            setHideColumnCompany(true);
            setHideColumnType(true);
        }

    }, [jwt, profile, userType, userCompanies, listUsers, listAllProfiles, getAllUsers, perfil,  companyid]);
    

    const customStyles = {
        rows: {
            style: {
                minHeight: '42px', // override the row height
                fontSize: "12px",
                fontFamily: "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
            },
        },
        headCells: {
            style: {
                paddingLeft: '6px', // override the cell padding for head cells
                paddingRight: '6px',
                fontFamily: "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
                fontSize: "0.92rem",
                color: "#002c6c"
            },
        },
        cells: {
            style: {
                paddingLeft: '6px', // override the cell padding for data cells
                paddingRight: '6px',
            },
        },
    };



    const columns: TableColumn<IDataUser>[] = React.useMemo(
        () => [
            {
                name: 'Nome',
                selector: (row: { nome: any; }) => row.nome,
                sortable: true,
                grow: 2,
                wrap: true,
            },
            {
                name: 'E-mail',
                selector: (row: { email: any; }) => row.email,
                sortable: true,
                grow: 2,
                wrap: true,
                width: '195px',
            },
            {
                name: 'Empresa',
                selector: (row: { empresa: any; }) => row.empresa,
                sortable: true,
                width: '95px',
                center: true,
                omit: hideColumnCompany,
            },
            {
                name: 'Tipo',
                selector: (row: { tipo: any; }) => row.tipo,
                sortable: true,
                width:'80px',
                center:true,
                omit: hideColumnType,
            },
            {
                name: 'Perfil',
                selector: (row: { perfil: any; }) => row.perfil,
                sortable: true,
                grow:2,
                width:'151px',
                wrap:true,
            },
            {
                name: 'Status',
                selector: (row: { status: any; }) => row.status,
                sortable: true,
                width: '72px',
                wrap: true
            },
            {
                name: '',
                selector: (row: { id: any; }) => row.id,
                cell: (row: any) => <ActionButtons {...row} />,
                width: '50px',
                center: true,
            },
		],
        [hideColumnCompany, hideColumnType],
    );

    const getCompaniesByName = (name : string, callback: (options : []) => void) => {
        if (name == null || name.length < 3)
            callback([]);

        getUserGS1Companies(jwt, name).then(
            (responseUserGS1Companies: any) => {
                let companies: any = [];

                if (responseUserGS1Companies.success) {
                    responseUserGS1Companies.result.forEach((element: any, index: any) => {
                        companies.push({ value: element.id, label: element.nome });
                    })

                    callback(companies);

                }
                else {
                    showError(responseUserGS1Companies.message);
                }
            }
        )
    }


    const getCompaniesByNameEdit = (name : string, callback: (options : []) => void) => {


        if (name != null && name.length >= 3) {
            getUserGS1Companies(jwt, name).then(
                (responseUserGS1Companies: any) => {
                    let companies: any = [];

                    if (responseUserGS1Companies.success) {
                        responseUserGS1Companies.result.forEach((element: any, index: any) => {
                            companies.push({ value: element.id, label: element.nome });
                        })

                        callback(companies);

                    }
                    else {
                        showError(responseUserGS1Companies.message);
                    }
                }
            )
        } else if (companyid) {
            getUserGS1CompaniesById(jwt, companyid).then(
                (responseUserGS1Companies: any) => {
                    let companies: any = [];

                    if (responseUserGS1Companies.success) {
                        responseUserGS1Companies.result.forEach((element: any, index: any) => {
                            companies.push({ value: element.id, label: element.nome });
                        })

                        callback(companies);

                    }
                    else {
                        showError(responseUserGS1Companies.message);
                    }
                }
            )
        }
    }


    const ActionButtons = (row: any) => {
        return (
            <>
                <div onClick={() => editUser(row.id)} >
                    <img src="/icones/pencil.svg" alt="editar" style={{ cursor: 'pointer' }} />
                </div> &nbsp;&nbsp;&nbsp;&nbsp;
                {(perfil.toUpperCase().indexOf("ADMINISTRADOR") >= 0 && perfil.toUpperCase().indexOf("GS1") >= 0) &&
                    <div onClick={() => openAlert('Excluir Usuário, deseja realmente excluir esse registro?', row.id, true)}>
                        <img src="/icones/trash.svg" alt="trash" style={{ cursor: 'pointer' }} />
                    </div>
                }
            </>
        )
    }


    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        findUser()
      }

    const profilecolumns: TableColumn<IDataProfile>[] = [
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true,
            grow: 1,
            wrap: true
        },
        {
            name: 'Descrição',
            selector: row => row.descricao,
            sortable: true,
            grow: 2,
            wrap: true
        },
        {
            name: 'Perfil',
            selector: row => row.perfil,
            sortable: true,
            grow: 1,
            wrap: true
        },
        {
            name: 'Quantidate',
            selector: row => row.qtd,
            sortable: true,
            grow: 1,
            wrap: true
        },
    ];



    return (
        <>
            <TemplateBackend>


                

            <form  onSubmit={onFormSubmit}>
                <div className="row pb-5">

                    <div className="col-12 col-md-6 mb-4">
                        <h3 style={{marginTop:0}}>
                            Usuários
                        </h3>
                    </div>

                    <div className="col-12 col-md-6 mb-4">

                        {(perfil.toUpperCase().indexOf("ADMINISTRADOR") >= 0) &&
                            <button
                                style={{ height: '2.875rem', float:'right' }}
                                onClick={() => openModal()}
                                className="btn-primary" type="button"
                            >
                                Novo Usuário
                            </button>
                        }

                    </div>

                    {(perfil.toUpperCase().indexOf("GS1") >= 0) &&


                    <div className="col-12">
                    <div className="form-group" style={{width:'100%'}}>
                    <span className="form-label">Empresa</span>

                        <AsyncSelect
                            classNamePrefix="asyncSelect"
                            cacheOptions
                            defaultValue={{label:"Digite o código da empresa para buscar"}}
                            loadingMessage={() => "Carregando..."}
                            noOptionsMessage={(item) => item.inputValue.length > 3 ? "Nenhum item encontrado." : "Digite o código da empresa para buscar"}
                            loadOptions={getCompaniesByName}
                            onChange={(item : any) => {
                                if(item) 
                                    getAllUsers(item.value);
                            }
                            }
                            />




                    </div>
                    </div>

                    }


                    <div className="col-12 col-md-6 mb-4">
                        <div className="form-group">
                            <span className="form-label">Nome</span>
                            <input
                                value={sname}
                                type="text"
                                className="form-control"
                                onChange={({ target }) => setSname(target.value)}
                                placeholder="Digite um nome
                            "/>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 mb-4">
                        <div className="form-group">
                            <span className="form-label">E-mail</span>
                            <input
                                value={semail}
                                type="text"
                                className="form-control"
                                onChange={({ target }) => setSemail(target.value)}
                                placeholder="Digite um e-mail
                            "/>
                        </div>
                    </div>

                    <div className={(perfil.indexOf("GS1") >= 0)  ? "col-12 col-md-4 mb-4" : "col-12 col-md-6 mb-4"}>
                        <div className="form-group">
                            <span className="form-label">Status</span>
                            <select
                                onChange={(event) => setSstatus(event.target.value)}
                                className="form-control"
                                defaultValue={'Todos'}
                            >
                                <option value="Todos">Todos</option>
                                <option value="Ativo">Ativo</option>
                                <option value="Inativo">Inativo</option>
                            </select>
                        </div>
                    </div>
                    
                    {(perfil.indexOf("GS1") >= 0) &&
                        <div className="col-12 col-md-4 mb-4">
                        
                            <div className="form-group">
                                <span className="form-label">Tipo de Usuário</span>
                                <select
                                    className="form-control"
                                    onChange={(event) => setStype(event.target.value)}
                                    defaultValue={'Todos'}
                                >
                                    {!userType &&
                                        <option value="Todos" disabled>Aguarde, carregando...</option>
                                    }

                                    {userType &&
                                        <option value="Todos">Todos</option>
                                    }

                                    {userType &&
                                        userType.map((element: any, key) => (
                                            <option key={key} className="option" value={element.nome}>
                                                {element.nome}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        
                        </div>
                    }

                    <div className={(perfil.indexOf("GS1") >= 0)  ? "col-12 col-md-4 mb-4" : "col-12 col-md-6 mb-4"}>
                        <div className="form-group">
                            <span className="form-label">Perfil</span>
                            <select
                                onChange={(event) => setSprofile(event.target.value)}
                                className="form-control"
                                defaultValue={'Todos'}
                            >
                                {!profile &&
                                    <option value="Todos">Aguarde, carregando...</option>
                                }
                                {profile &&
                                    <option value="Todos">Todos</option>
                                }
                                {profile &&
                                    profile.map((element: any, key) => (
                                        <option key={element.nome} className="option" value={element.nome}>
                                            {element.nome}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                    <div className="col-12">
                        <button className="btn-primary" type="submit" style={{float:'right'}}>
                            Buscar
                        </button>
                    </div>

                </div>

                    </form>
                <div className="row px-4">

                    <div className="tableUser">

                        <div className="tabNavigation">

                            {(perfil.indexOf("GS1") >= 0) &&
                                <div className={(activetab === 1) ? 'tab active' : 'tab'} onClick={() => setActiveTab(1)}>
                                    Todos os Usuários
                                </div>
                            }
                            {perfil.indexOf("GS1") >= 0 &&
                                <div className={(activetab === 2) ? 'tab active' : 'tab'} onClick={() => setActiveTab(2)}>
                                    Todos os Perfis
                                </div>
                            }

                        </div>



                        {((activetab === 1 && listUsers && (perfil.toUpperCase().indexOf("ADMINISTRADOR") >= 0 || perfil.toUpperCase().indexOf("GS1") >= 0))) && (
                            <DataTable
                                columns={columns}
                                data={listUsers}
                                pagination
                                responsive
                                highlightOnHover
                                noDataComponent="Sem registros para exibir"
                                progressPending={userLoading}
                                progressComponent={<Loading />}
                                customStyles={customStyles}
                                paginationComponent={BootyPagination}
                            />
                        )}


                        {(activetab === 2 && listAllProfiles && perfil.indexOf("GS1") >= 0) && (
                            <DataTable
                                columns={profilecolumns}
                                data={listAllProfiles}
                                pagination
                                responsive
                                highlightOnHover
                                noDataComponent="Sem registros para exibir"
                                progressPending={profileLoading}
                                progressComponent={<Loading />}
                                customStyles={customStyles}
                                paginationComponent={BootyPagination}
                            />
                        )}

                    </div>

                </div>


                <Modal
                    isOpen={stateModal}
                    onRequestClose={close}
                    ariaHideApp={false}
                    contentLabel="Selected Option"
                    style={{
                        overlay: {
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "#262626CC",
                            zIndex:1001
                        },
                        content: {
                            width: "50%",
                            height: "92%",
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: "auto",
                            marginRight: "auto",
                            border: "1px solid #ccc",
                            background: "#fff",
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch",
                            borderRadius: "4px",
                            outline: "none",
                            padding: "20px",
                            zIndex:1001
                        },
                    }}
                >

                    <div className="headerform" style={{ marginBottom: "20px" }}>
                        <span
                            style={{ color: "#767676", fontSize: "20px" }}
                        >
                            {modal_header_text}
                        </span>
                        <span
                            onClick={() => setStateModal(false)}
                            style={{ position: "absolute", right: "1%", top: "1%", fontSize: "16px" }}
                        >
                            <IoMdClose />
                        </span>
                    </div>

                    <div className="row px-3 py-2" style={{ color: "#767676" }}>Dados Pessoais</div>

                    <div className="row">

                        <div className="col-12 col-md-6">

                            <div className="form-group">
                                <span className="form-label">Nome</span>
                                <input
                                    value={nome}
                                    type="text"
                                    className={`form-control ${formError.sname}`}
                                    onChange={({ target }) => setNome(target.value)}
                                />
                            </div>

                        </div>

                        <div className="col-12 col-md-6">

                            <div className="form-group">
                                <span className="form-label">CPF / CNPJ</span>
                                <input
                                    value={documento}
                                    className={`form-control ${formError.sdocumento}`}
                                    name="documento"
                                    maxLength={18}
                                    onChange={({ target }) => setDocumento(maskCpfCnpj(target.value))}
                                    onBlur={({ target }) => maskCpfCnpj(target.value)}
                                />
                            </div>

                        </div>

                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <span className="form-label">Departamento</span>
                                <input
                                    className="form-control"
                                    name="departamento"
                                    onChange={({ target }) => setDepartamento(target.value)}
                                    value={departamento}
                                />
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <span className="form-label">Cargo</span>
                                <input
                                    className="form-control"
                                    name="cargo"
                                    onChange={({ target }) => setCargo(target.value)}
                                    value={cargo}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="line" style={{ color: "#767676", borderTop: "solid 1px #ddd", margin: "20px 0 0 0", padding: "20px 0" }}>Dados de Usuário</div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <span className="form-label">E-mail</span>
                                <input
                                    className={`form-control ${formError.semail}`}
                                    placeholder="exemplo@gs1.org"
                                    name="email"
                                    onChange={({ target }) => setEmail(target.value)}
                                    value={email}
                                />
                            </div>
                        </div>

                        <div className="col-12 col-md-6"></div>

                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <span className="form-label">Perfil</span>
                                <select
                                    onChange={(event) => setPerfilRegister(event.target.value)}
                                    className="form-control"
                                    name="perfilID"
                                    defaultValue={perfilregister}
                                >
                                    {!profile &&
                                        <option value="" disabled>aguarde, carregando</option>
                                    }

                                    {profile &&
                                        <option value="" disabled>escolha uma opção</option>
                                    }

                                    {profile &&
                                        profile.map((element: any, key) => (
                                            <option key={key + element.id} className="option" value={element.id}>
                                                {element.nome}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>


                        {(userid && perfil.toUpperCase().indexOf("ADMINISTRADOR") >= 0) &&
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <span className="form-label">Status</span>
                                    <select
                                        onChange={(event) => changeUserStatus('Alterar status do usuário, deseja realmente alterar o status desse usuário?', event.target.value)}
                                        className="form-control"
                                        name="statusUser"
                                        defaultValue={status.toLowerCase()}
                                    >
                                        <option value="" disabled>escolha uma opção</option>
                                        <option className="option" value='ativo'>Ativo</option>
                                        <option className="option" value='inativo'>Inativo</option>

                                    </select>
                                </div>
                            </div>

                        }
                    </div>
                    

                    <div className="row pt-4 pb-2 mt-3 px-3" style={{ color: "#767676", borderTop: "solid 1px #ddd"}}>Dados de Usuário</div>

                    <div className="row px-3">

                        <div className="form-group" style={{width:'100%'}}>

                            <span className="form-label" >Empresa de vínculo</span>



                                {(userCompanies && perfil.toUpperCase().indexOf("GS1") < 0 && perfil.toUpperCase().indexOf("ADMINISTRADOR") >= 0) &&
                                (
                                <select
                                onChange={(event) => setEmpresa(event.target.value)}
                                className="form-control"
                                name="empresaID"
                                defaultValue={''}
                                value={empresa}
                                disabled={lock_combo_company}>
                                <option value="" disabled>Escolha uma opção</option>
                                {
                                userCompanies.map((element: any, key) => (
                                    <option key={element.id+'zv'} className="option" value={element.id}>
                                        {element.nome}
                                    </option>
                                ))
                                }
                                </select>
                                )
                                }





                                {(perfil.toUpperCase().indexOf("GS1") >= 0) &&
                                    <AsyncSelect
                                    classNamePrefix="asyncSelect"
                                    cacheOptions
                                    loadingMessage={() => "Carregando..."}
                                    noOptionsMessage={(item) => item.inputValue.length > 3 ? "Nenhum item encontrado." : "Digite ao menos 3 letras..."}
                                    defaultInputValue={empresaNome}
                                    defaultValue={empresa}
                                    defaultOptions={true}
                                    loadOptions={getCompaniesByNameEdit}
                                    onChange={(item : any) => {
                                        if(item) 
                                            setEmpresa(item.value);
                                    }
                                    }
                                    />
                                    }

                        </div>

                    </div>
                    

                    <div className="row px-3" style={{ display: 'flex', justifyContent: 'flex-end', gap: "1.2rem", marginTop: "20px" }}>

                        {(perfil.toUpperCase().indexOf("ADMINISTRADOR") >= 0) &&
                            <button disabled={disableSubmitButton} onClick={() => handleSubmit()} className="bnt-primary">
                                {button_text}
                            </button>
                        }
                        <button disabled={disableSubmitButton}
                            className="btn-outline-primary"
                            onClick={() => openAlert('Você tem certeza que deseja cancelar?', null)}
                        >
                            Cancelar
                        </button>

                    </div>


                    {showAlert &&
                    <div className="bgalert">

                        <div className="alert">
                            <div className="containeralert">
                                <div className="contentalert">
                                    {alertMessage}
                                </div>
                                <div className="footeralert">
                                    {(!doubleValidation) &&
                                        <button onClick={() => confirmEnd()}>Sim</button>
                                    }
                                    {(showButtonConfirm && doubleValidation) &&
                                        <button onClick={() => confirmExclusion()}>Sim</button>
                                    }
                                    {(!showButtonConfirm && doubleValidation) &&
                                        <button className="btn-primary" onClick={() => continueExclusion('Esse registro não poderá ser recuperado após essa ação, confirma?')}>Sim</button>
                                    }
                                    <button className="btn-outline-primary" onClick={() => cancelAlert()}>Não</button>
                                </div>
                            </div>
                        </div>

                    </div>
                }


                </Modal>



            </TemplateBackend >
        </>
    )
}

export default User;
