export function getLanguageName(isoCode:string) : string {

    let c = languages.find( (e) => {
        return e.code.toLocaleUpperCase() === isoCode.toLocaleUpperCase();
    });

    if (c !== undefined)
        return c.name;

    return "";
}

interface ILanguage {
    code: string;
    name: string;
}

const languages : Array<ILanguage> = [
{code:"abk", name:"Abecásio"},
{code:"akk", name:"Acádio"},
{code:"ace", name:"Achinês"},
{code:"afr", name:"Africâner"},
{code:"af", name:"Africano"},
{code:"af-ZA", name:"Africano (África do Sul)"},
{code:"afh", name:"Afrihili"},
{code:"aym", name:"Aimará"},
{code:"sq", name:"Albanês"},
{code:"sq-AL", name:"Albanês (Albânia)"},
{code:"alb", name:"Albaniano"},
{code:"de", name:"Alemão"},
{code:"de-DE", name:"Alemão (Alemanha)"},
{code:"de-AT", name:"Alemão (Áustria)"},
{code:"de-LI", name:"Alemão (Liechtenstein)"},
{code:"de-LU", name:"Alemão (Luxemburgo)"},
{code:"de-CH", name:"Alemão (Suíça)"},
{code:"nds", name:"Alemão baixo; Saxão baixo; Alemão, baixo; Saxão, baixo"},
{code:"gsw", name:"Alemão suíço; Alemânico; Alsaciano"},
{code:"goh", name:"Alemão, antigo alto (aprox. 750-1050)"},
{code:"gmh", name:"Alemão, médio alto (aprox. 1050-1500)"},
{code:"ale", name:"Alêutico"},
{code:"alt", name:"Altai do sul"},
{code:"amh", name:"Amárico"},
{code:"ar", name:"Árabe"},
{code:"ar-SA", name:"Árabe (Arábia Saudita)"},
{code:"ar-DZ", name:"Árabe (Argélia)"},
{code:"ar-BH", name:"Árabe (Bahrein)"},
{code:"ar-QA", name:"Árabe (Catar)"},
{code:"ar-EG", name:"Árabe (Egito)"},
{code:"ar-YE", name:"Árabe (Iêmen)"},
{code:"ar-IQ", name:"Árabe (Iraque)"},
{code:"ar-JO", name:"Árabe (Jordânia)"},
{code:"ar-KW", name:"Árabe (Kuwait)"},
{code:"ar-LB", name:"Árabe (Líbano)"},
{code:"ar-LY", name:"Árabe (Líbia)"},
{code:"ar-MA", name:"Árabe (Marrocos)"},
{code:"ar-OM", name:"Árabe (Omã)"},
{code:"ar-SY", name:"Árabe (Síria)"},
{code:"ar-TN", name:"Árabe (Tunísia)"},
{code:"ar-AE", name:"Árabe (U.A.E.)"},
{code:"arg", name:"Aragonês"},
{code:"arc", name:"Aramaico oficial (700-300 AEC); Aramaico imperial (700-300 AEC)"},
{code:"sam", name:"Aramaico Samaritano"},
{code:"hy", name:"Armênio"},
{code:"hy-AM", name:"Armênio (Armênia)"},
{code:"arw", name:"Aruaque"},
{code:"asm", name:"Assamês"},
{code:"ast", name:"Asturiano; Bable; Leonês; Asturoleonês"},
{code:"ava", name:"Avárico"},
{code:"ave", name:"Avestano"},
{code:"aze", name:"Azerbaijano"},
{code:"az-AZ", name:"Azeri (cirílico) (Azerbaijão)"},
{code:"az-AZ", name:"Azeri (latim) (Azerbaijão)"},
{code:"az", name:"Azeri (Latino)"},
{code:"dsb", name:"Baixo Sorábio"},
{code:"ban", name:"Balinês"},
{code:"eu", name:"Basco"},
{code:"eu-ES", name:"Basco (Espanha)"},
{code:"ben", name:"Bengalês"},
{code:"bik", name:"Bicolano"},
{code:"be-BY", name:"Bielorrusso (Bielorrússia)"},
{code:"bel", name:"Bielorusso"},
{code:"be", name:"Bielo-russo"},
{code:"bs-BA", name:"Bósnia (Bósnia e Herzegovina)"},
{code:"bos", name:"Bósnio"},
{code:"bre", name:"Bretão"},
{code:"bug", name:"Buginês"},
{code:"bg", name:"Búlgaro"},
{code:"bg-BG", name:"Búlgaro (Bulgária)"},
{code:"bur", name:"Burmês"},
{code:"xal", name:"Calmuco; Oirat"},
{code:"csb", name:"Cassubiano"},
{code:"ca", name:"Catalão"},
{code:"ca-ES", name:"Catalão (Espanha)"},
{code:"kk", name:"Cazaque"},
{code:"kk-KZ", name:"Cazaque (Cazaquistão)"},
{code:"ceb", name:"Cebuano"},
{code:"che", name:"Checheno"},
{code:"chy", name:"Cheyenne"},
{code:"zh", name:"Chinês"},
{code:"zh-SG", name:"Chinês (Cingapura)"},
{code:"zh-HK", name:"Chinês (Hong Kong)"},
{code:"zh-MO", name:"Chinês (Macau)"},
{code:"zh-CN", name:"Chinês (S)"},
{code:"zh-TW", name:"Chinês (T)"},
{code:"chk", name:"Chuuquês"},
{code:"sin", name:"Cingalês"},
{code:"kok", name:"concani"},
{code:"cop", name:"Copta"},
{code:"ko", name:"Coreano"},
{code:"ko-KR", name:"Coreano (Coreia)"},
{code:"cor", name:"Córnico"},
{code:"cos", name:"Corso"},
{code:"crp", name:"Crioulo e pidgins"},
{code:"cpf", name:"Crioulo e pidgins, base em francês"},
{code:"cpe", name:"Crioulo e pidgins, base em inglês"},
{code:"cpp", name:"Crioulo e pidgins, base em português"},
{code:"hat", name:"Crioulo haitiano"},
{code:"hr", name:"Croata"},
{code:"hr-BA", name:"Croata (Bósnia e Herzegovina)"},
{code:"hr-HR", name:"Croata (Croácia)"},
{code:"kur", name:"Curdo"},
{code:"dar", name:"Dargínico"},
{code:"da", name:"Dinamarquês"},
{code:"da-DK", name:"Dinamarquês (Dinamarca)"},
{code:"dv", name:"Divehi"},
{code:"dv-MV", name:"Divehi (Maldivas)"},
{code:"egy", name:"Egípcio (antigo)"},
{code:"elx", name:"Elamita"},
{code:"chu", name:"Eslavo eclesiástico"},
{code:"sk", name:"Eslovaco"},
{code:"sk-SK", name:"Eslovaco (Eslováquia)"},
{code:"sl", name:"Esloveno"},
{code:"sl-SI", name:"Esloveno (Eslovênia)"},
{code:"es", name:"Espanhol"},
{code:"es-AR", name:"Espanhol (Argentina)"},
{code:"es-BO", name:"Espanhol (Bolívia)"},
{code:"es-ES", name:"Espanhol (castiliano)"},
{code:"es-CL", name:"Espanhol (Chile)"},
{code:"es-CO", name:"Espanhol (Colômbia)"},
{code:"es-CR", name:"Espanhol (Costa Rica)"},
{code:"es-SV", name:"Espanhol (El Salvador)"},
{code:"es-EC", name:"Espanhol (Equador)"},
{code:"es-ES", name:"Espanhol (Espanha)"},
{code:"es-GT", name:"Espanhol (Guatemala)"},
{code:"es-HN", name:"Espanhol (Honduras)"},
{code:"es-MX", name:"Espanhol (México)"},
{code:"es-NI", name:"Espanhol (Nicarágua)"},
{code:"es-PA", name:"Espanhol (Panamá)"},
{code:"es-PY", name:"Espanhol (Paraguai)"},
{code:"es-PE", name:"Espanhol (Peru)"},
{code:"es-PR", name:"Espanhol (Porto Rico)"},
{code:"es-DO", name:"Espanhol (República Dominicana)"},
{code:"es-UY", name:"Espanhol (Uruguai)"},
{code:"es-VE", name:"Espanhol (Venezuela)"},
{code:"spa", name:"Espanhol; Castelhano"},
{code:"eo", name:"Esperanto"},
{code:"et-EE", name:"Estônia (Estônia)"},
{code:"et", name:"Estoniano"},
{code:"fo", name:"Faroês"},
{code:"fo-FO", name:"Faroês (Ilhas Faroé)"},
{code:"fa-IR", name:"Farsi (Irã)"},
{code:"phn", name:"Fenício"},
{code:"fij", name:"Fidjiano"},
{code:"fil", name:"Filipino; Pilipino"},
{code:"fi", name:"Finlandês"},
{code:"fi-FI", name:"Finlandês (Finlândia)"},
{code:"fr", name:"Francês"},
{code:"fr-BE", name:"Francês (Bélgica)"},
{code:"fr-CA", name:"Francês (Canadá)"},
{code:"fr-FR", name:"Francês (França)"},
{code:"fr-LU", name:"Francês (Luxemburgo)"},
{code:"fr-MC", name:"Francês (Principado de Mônaco)"},
{code:"fr-CH", name:"Francês (Suíça)"},
{code:"fro", name:"Francês, antigo (842-aprox.1400)"},
{code:"frm", name:"Francês, médio (aprox. 1400-1600)"},
{code:"frs", name:"Frísio do leste"},
{code:"frr", name:"Frisio do norte"},
{code:"fry", name:"Frísio do oeste"},
{code:"fur", name:"Friulano"},
{code:"mk-MK", name:"FYRO Macedônia (Ex-República Iugoslava da Macedônia)"},
{code:"gla", name:"Gaélico escocês"},
{code:"gl", name:"Galego"},
{code:"gl-ES", name:"Galego (Espanha)"},
{code:"cy", name:"Galês"},
{code:"cy-GB", name:"Galês (Reino Unido)"},
{code:"ka", name:"Georgiano"},
{code:"ka-GE", name:"Georgiano (Geórgia)"},
{code:"gil", name:"Gilbertês"},
{code:"got", name:"Gótico"},
{code:"el", name:"Grego"},
{code:"el-GR", name:"Grego (Grécia)"},
{code:"grc", name:"Grego, antigo (até 1453)"},
{code:"grn", name:"Guarani"},
{code:"gu-IN", name:"Gujarati (Índia)"},
{code:"gu", name:"Guzerate"},
{code:"haw", name:"Havaiano"},
{code:"he", name:"Hebraico"},
{code:"he-IL", name:"Hebraico (Israel)"},
{code:"heb", name:"Hebreu"},
{code:"hi-IN", name:"Hindi (Índia)"},
{code:"hit", name:"Hitita"},
{code:"nl", name:"Holandês"},
{code:"nl-BE", name:"Holandês (Bélgica)"},
{code:"nl-NL", name:"Holandês (Holanda)"},
{code:"dum", name:"Holandês, médio (aprox. 1050-1350)"},
{code:"dut", name:"Holandês; Flamengo"},
{code:"hu", name:"Húngaro"},
{code:"hu-HU", name:"Húngaro (Hungria)"},
{code:"yap", name:"Iapês"},
{code:"yid", name:"Ídiche"},
{code:"oji", name:"Idima Ojibwa"},
{code:"ach", name:"Idioma Acoli"},
{code:"ady", name:"Idioma Adigue"},
{code:"aar", name:"Idioma Afar"},
{code:"ain", name:"Idioma Ainu"},
{code:"aka", name:"Idioma Akan"},
{code:"anp", name:"Idioma Angica"},
{code:"arp", name:"Idioma Arapaho"},
{code:"rup", name:"Idioma Arromeno; Arrumeno; Macedo-Romeno"},
{code:"awa", name:"Idioma Avadi"},
{code:"bal", name:"Idioma Baluchi"},
{code:"bam", name:"Idioma Bambara"},
{code:"bak", name:"Idioma Basquir"},
{code:"bej", name:"Idioma Beja; Bedawi"},
{code:"bem", name:"Idioma Bemba"},
{code:"bho", name:"Idioma Bhojpuri"},
{code:"bin", name:"Idioma Bini; Edo"},
{code:"bis", name:"Idioma Bislama"},
{code:"byn", name:"Idioma Blin; Bilin"},
{code:"bra", name:"Idioma Braj"},
{code:"bua", name:"Idioma Buriat"},
{code:"dzo", name:"Idioma butanês"},
{code:"cad", name:"Idioma Caddo"},
{code:"kan", name:"Idioma canarês"},
{code:"kau", name:"Idioma Canúri"},
{code:"kaa", name:"Idioma caracalpaque"},
{code:"krc", name:"Idioma carachaio-bálcara"},
{code:"krl", name:"Idioma Carélio"},
{code:"kas", name:"Idioma cashemir"},
{code:"kaz", name:"Idioma cazaque"},
{code:"chg", name:"Idioma Chagatai"},
{code:"cha", name:"Idioma Chamorro"},
{code:"chr", name:"Idioma Cherokee"},
{code:"chb", name:"Idioma Chibcha"},
{code:"chp", name:"Idioma Chipewyan; Dene Suline"},
{code:"cho", name:"Idioma Choctaw"},
{code:"chv", name:"Idioma Chuvashe"},
{code:"kok", name:"Idioma Concani"},
{code:"kho", name:"Idioma cotanês; Saca"},
{code:"cre", name:"Idioma Cree"},
{code:"mus", name:"Idioma Creek"},
{code:"kua", name:"Idioma Cuanhama; Cuanhama"},
{code:"kum", name:"Idioma Cumique"},
{code:"dak", name:"Idioma Dakota"},
{code:"ada", name:"Idioma Dangme"},
{code:"del", name:"Idioma Delaware"},
{code:"din", name:"Idioma Dinca"},
{code:"dyu", name:"Idioma Diúla"},
{code:"div", name:"Idioma Divehi"},
{code:"doi", name:"Idioma Dogri"},
{code:"dgr", name:"Idioma Dogrib"},
{code:"dua", name:"Idioma Duala"},
{code:"efi", name:"Idioma Efik"},
{code:"eka", name:"Idioma Ekajuk"},
{code:"myv", name:"Idioma Erzio"},
{code:"ewo", name:"Idioma Euondo"},
{code:"ewe", name:"Idioma Ewe"},
{code:"fan", name:"Idioma Fangue"},
{code:"fat", name:"Idioma Fanti"},
{code:"fao", name:"Idioma Feroês"},
{code:"fon", name:"Idioma Fon"},
{code:"ful", name:"Idioma Fula"},
{code:"gaa", name:"Idioma Ga"},
{code:"car", name:"Idioma Galibi do Caribe"},
{code:"gay", name:"Idioma Gayo"},
{code:"gba", name:"Idioma Gbaya"},
{code:"gez", name:"Idioma Ge'ez"},
{code:"gon", name:"Idioma Gondi"},
{code:"gor", name:"Idioma Gorontalo"},
{code:"grb", name:"Idioma Grebo"},
{code:"kal", name:"Idioma groelandês"},
{code:"guj", name:"Idioma Guzerati"},
{code:"gwi", name:"Idioma Gwich'in"},
{code:"hai", name:"Idioma Haida"},
{code:"hau", name:"Idioma Hauçá"},
{code:"her", name:"Idioma Herero"},
{code:"hil", name:"Idioma Hiligaynon"},
{code:"hin", name:"Idioma Hindi"},
{code:"hmo", name:"Idioma Hiri Motu"},
{code:"hmn", name:"Idioma Hmong; Mong"},
{code:"hup", name:"Idioma Hupa"},
{code:"sah", name:"Idioma Iacuta"},
{code:"iba", name:"Idioma Iban"},
{code:"ido", name:"Idioma Ido"},
{code:"ibo", name:"Idioma Igbo"},
{code:"ilo", name:"Idioma Ilocano"},
{code:"smn", name:"Idioma Inari Sami"},
{code:"inh", name:"Idioma Ingushe"},
{code:"iku", name:"Idioma Inuíte"},
{code:"ipk", name:"Idioma Inupiaq"},
{code:"kbd", name:"Idioma Kabardian"},
{code:"kac", name:"Idioma Kachin; Jingpho"},
{code:"kaw", name:"Idioma Kawi"},
{code:"kha", name:"Idioma Khasi"},
{code:"khm", name:"Idioma Khmer Central"},
{code:"kmb", name:"Idioma Kimbundu"},
{code:"run", name:"Idioma Kirundi"},
{code:"tlh", name:"Idioma Klingon; tlhIngan-Hol"},
{code:"kom", name:"Idioma Komi"},
{code:"kon", name:"Idioma Kongo"},
{code:"kos", name:"Idioma kosraeano"},
{code:"kpe", name:"Idioma Kpelle"},
{code:"kru", name:"Idioma Kurux"},
{code:"kut", name:"Idioma Kutenai"},
{code:"lad", name:"Idioma Ladino"},
{code:"lah", name:"Idioma Lahnda"},
{code:"lam", name:"Idioma Lamba"},
{code:"lao", name:"Idioma laociano"},
{code:"lav", name:"Idioma Letâo"},
{code:"lez", name:"Idioma Lezgui"},
{code:"lim", name:"Idioma limburguês"},
{code:"lin", name:"Idioma Lingala"},
{code:"jbo", name:"Idioma Lojban"},
{code:"loz", name:"Idioma Lozi"},
{code:"lub", name:"Idioma Luba-Katanga"},
{code:"lug", name:"Idioma Luganda"},
{code:"lui", name:"Idioma Luisenho"},
{code:"smj", name:"Idioma Lule Sami"},
{code:"lun", name:"Idioma Lunda"},
{code:"luo", name:"Idioma Luo (Quênia e Tanzânia)"},
{code:"lus", name:"Idioma Lushai"},
{code:"mag", name:"Idioma Magahi"},
{code:"mai", name:"Idioma Maithili"},
{code:"mak", name:"Idioma Makassarês"},
{code:"mal", name:"Idioma Malaialo"},
{code:"mlg", name:"Idioma Malgaxe"},
{code:"mnc", name:"Idioma Manchu"},
{code:"mdr", name:"Idioma Mandar"},
{code:"man", name:"Idioma Mandingo"},
{code:"glv", name:"Idioma Manês"},
{code:"mni", name:"Idioma Manipuri"},
{code:"mao", name:"Idioma Maori"},
{code:"arn", name:"Idioma Mapudungun; Mapuche"},
{code:"mar", name:"Idioma Marata"},
{code:"chm", name:"Idioma Mari"},
{code:"mah", name:"Idioma Marshalês"},
{code:"mwr", name:"Idioma Marwari"},
{code:"men", name:"Idioma Mende"},
{code:"mic", name:"Idioma Mi'kmaq; Micmac"},
{code:"min", name:"Idioma Minangkabau"},
{code:"moh", name:"Idioma Mohawk"},
{code:"mdf", name:"Idioma Moksha"},
{code:"mol", name:"Idioma Moldavo; Moldovo"},
{code:"lol", name:"Idioma Mongo"},
{code:"mon", name:"Idioma Mongol"},
{code:"mos", name:"Idioma More"},
{code:"nau", name:"Idioma Nauru"},
{code:"nav", name:"Idioma Navajo; Navaho"},
{code:"nde", name:"Idioma Ndebele do norte"},
{code:"nbl", name:"Idioma Ndebele do sul"},
{code:"ndo", name:"Idioma Ndonga"},
{code:"new", name:"Idioma Nepal Bhasa; Neuari"},
{code:"nia", name:"Idioma Ni]as"},
{code:"nya", name:"Idioma Nianja"},
{code:"niu", name:"Idioma Niueano"},
{code:"nqo", name:"Idioma N'Ko"},
{code:"nog", name:"Idioma Nogai"},
{code:"iii", name:"Idioma Nuosu"},
{code:"nym", name:"Idioma Nyamwezi"},
{code:"nyo", name:"Idioma Nyoro"},
{code:"nzi", name:"Idioma Nzima"},
{code:"oci", name:"Idioma Occitano"},
{code:"ori", name:"Idioma Oriya"},
{code:"orm", name:"Idioma Oromo"},
{code:"osa", name:"Idioma Osage"},
{code:"oss", name:"Idioma Osseta; Ossético"},
{code:"pli", name:"Idioma Pali"},
{code:"pam", name:"Idioma Pampanga; Kapampangan"},
{code:"pag", name:"Idioma Pangasinês"},
{code:"pan", name:"Idioma Panjabi; Punjabi"},
{code:"nso", name:"Idioma Pedi; Sepedi; Sotho do norte"},
{code:"pon", name:"Idioma Pohnpeiano"},
{code:"pus", name:"Idioma Puchto; Pachto"},
{code:"kik", name:"Idioma Quicuio; Gicuio"},
{code:"kin", name:"Idioma quiniaruanda"},
{code:"kir", name:"Idioma quirguiz"},
{code:"raj", name:"Idioma Rajastani"},
{code:"rap", name:"Idioma Rapanui"},
{code:"rar", name:"Idioma Rarotongan; Maori das Ilhas Cook"},
{code:"roh", name:"Idioma Romanche"},
{code:"rom", name:"Idioma Romani"},
{code:"nyn", name:"Idioma Runyankole"},
{code:"sme", name:"Idioma Sami do norte"},
{code:"sma", name:"Idioma Sami do sul"},
{code:"sad", name:"Idioma Sandawe"},
{code:"sag", name:"Idioma Sango"},
{code:"sat", name:"Idioma Santali"},
{code:"sas", name:"Idioma Sasak"},
{code:"sco", name:"Idioma Scots"},
{code:"sel", name:"Idioma Selkup"},
{code:"srr", name:"Idioma Serer"},
{code:"shn", name:"Idioma Shan"},
{code:"sna", name:"Idioma Shona"},
{code:"sid", name:"Idioma Sidamo"},
{code:"bla", name:"Idioma Siksika"},
{code:"snd", name:"Idioma Sindhi"},
{code:"sms", name:"Idioma Skolt Sami"},
{code:"den", name:"Idioma Slavey (Atabascano)"},
{code:"suk", name:"Idioma Sukuma"},
{code:"sus", name:"Idioma Susu"},
{code:"ssw", name:"Idioma Swati"},
{code:"tmh", name:"Idioma Tamasheq"},
{code:"tel", name:"Idioma Telugu"},
{code:"ter", name:"Idioma Tereno"},
{code:"tet", name:"Idioma Tetum"},
{code:"tig", name:"Idioma Tigre"},
{code:"tir", name:"Idioma Tigrínia"},
{code:"tem", name:"Idioma Timne"},
{code:"tiv", name:"Idioma Tiv"},
{code:"tli", name:"Idioma Tlingit"},
{code:"tpi", name:"Idioma Tok Pisin"},
{code:"tkl", name:"Idioma Toquelau"},
{code:"lua", name:"Idioma Tshiluba"},
{code:"tsi", name:"Idioma Tsimshiano"},
{code:"tso", name:"Idioma Tsonga"},
{code:"tsn", name:"Idioma Tswana"},
{code:"tum", name:"Idioma Tumbuka"},
{code:"tvl", name:"Idioma Tuvalu"},
{code:"twi", name:"Idioma Twi"},
{code:"war", name:"Idioma Waray"},
{code:"was", name:"Idioma Washo"},
{code:"wal", name:"Idioma Wolaitta"},
{code:"zen", name:"Idioma Zenaga"},
{code:"zha", name:"Idioma Zhuang; Chuang"},
{code:"afa", name:"Idiomas Afro-Asiáticos"},
{code:"alg", name:"Idiomas Algonquianos"},
{code:"tut", name:"Idiomas altáicos"},
{code:"apa", name:"Idiomas Apache"},
{code:"art", name:"Idiomas artificiais"},
{code:"ath", name:"Idiomas atabascanos"},
{code:"aus", name:"Idiomas australianos"},
{code:"map", name:"Idiomas Austronésios"},
{code:"bat", name:"Idiomas bálticos"},
{code:"bai", name:"Idiomas Bamileque"},
{code:"bad", name:"Idiomas Banda"},
{code:"bnt", name:"Idiomas Banto"},
{code:"bas", name:"Idiomas Basa"},
{code:"btk", name:"Idiomas Batak"},
{code:"ber", name:"Idiomas bérberes"},
{code:"bih", name:"Idiomas Bihari"},
{code:"cau", name:"Idiomas caucasianos"},
{code:"cel", name:"Idiomas celtas"},
{code:"cmc", name:"Idiomas châmicos"},
{code:"cus", name:"Idiomas Cushíticos"},
{code:"dra", name:"Idiomas Dravidianos"},
{code:"sla", name:"Idiomas eslavos"},
{code:"phi", name:"Idiomas Filipinos"},
{code:"fiu", name:"Idiomas Fino-Úgricos"},
{code:"gem", name:"Idiomas germânicos"},
{code:"him", name:"Idiomas Himachali; Idiomas Pahari do oeste"},
{code:"ijo", name:"Idiomas Ijo"},
{code:"inc", name:"Idiomas índicos"},
{code:"cai", name:"Idiomas indígenas da America Central"},
{code:"sai", name:"Idiomas indígenas da América do Sul"},
{code:"nai", name:"Idiomas indígenas Norte Americanos"},
{code:"ine", name:"Idiomas Indo-Europeus"},
{code:"ira", name:"Idiomas Iranianos"},
{code:"iro", name:"Idiomas Iroquezianos"},
{code:"kar", name:"Idiomas Karen"},
{code:"khi", name:"Idiomas Khoisan"},
{code:"kro", name:"Idiomas Kru"},
{code:"day", name:"Idiomas Land Dayak"},
{code:"myn", name:"Idiomas Maia"},
{code:"mno", name:"Idiomas Manobo"},
{code:"mkh", name:"Idiomas Mon-Khmer"},
{code:"mul", name:"Idiomas múltiplos"},
{code:"mun", name:"Idiomas Munda"},
{code:"mis", name:"Idiomas não codificados"},
{code:"nah", name:"Idiomas Nauátle"},
{code:"nwc", name:"Idiomas Neuari clássico; Neuari antigo; Nepal Bhasa clássico"},
{code:"nic", name:"Idiomas Niger-Kordofanianos"},
{code:"nub", name:"Idiomas Núbios"},
{code:"oto", name:"Idiomas Otomanos"},
{code:"paa", name:"Idiomas Papua"},
{code:"pra", name:"Idiomas Prácritos"},
{code:"roa", name:"Idiomas Românicos"},
{code:"sal", name:"Idiomas Salishanos"},
{code:"smi", name:"Idiomas Sami"},
{code:"sem", name:"Idiomas semíticos"},
{code:"sit", name:"Idiomas Sino-Tibetanos"},
{code:"sio", name:"Idiomas Siouanos"},
{code:"son", name:"Idiomas Songai"},
{code:"wen", name:"Idiomas sorábios"},
{code:"tai", name:"Idiomas Tailandeses"},
{code:"tup", name:"Idiomas Tupi"},
{code:"wak", name:"Idiomas Wakash"},
{code:"ypk", name:"Idiomas Yupik"},
{code:"znd", name:"Idiomas Zande"},
{code:"kam", name:"Idiona Quicamba"},
{code:"kab", name:"Idoma Cabila"},
{code:"id", name:"Indonésio"},
{code:"en", name:"Inglês"},
{code:"en-ZA", name:"Inglês (África do Sul)"},
{code:"en-AU", name:"Inglês (Austrália)"},
{code:"en-BZ", name:"Inglês (Belize)"},
{code:"en-CA", name:"Inglês (Canadá)"},
{code:"en-CB", name:"Inglês (Caribe)"},
{code:"en-US", name:"Inglês (Estados Unidos)"},
{code:"id-ID", name:"Inglês (Inglês)"},
{code:"en-IE", name:"Inglês (Irlanda)"},
{code:"en-JM", name:"Inglês (Jamaica)"},
{code:"en-NZ", name:"Inglês (Nova Zelândia)"},
{code:"en-GB", name:"Inglês (Reino Unido)"},
{code:"en-PH", name:"Inglês (República das Filipinas)"},
{code:"en-TT", name:"Inglês (Trinidad e Tobago)"},
{code:"en-ZW", name:"Inglês (Zimbábue)"},
{code:"ang", name:"Inglês, antigo (aprox. 450-1100)"},
{code:"enm", name:"Inglês, médio (1100-1500)"},
{code:"ile", name:"Interlíngua"},
{code:"ina", name:"Interlíngua (Associação Internacional Auxiliar de Idiomas)"},
{code:"yor", name:"Iorubá"},
{code:"gle", name:"Irlandês"},
{code:"sga", name:"Irlandês, antigo (até 900)"},
{code:"mga", name:"Irlandês, médio (900-1200)"},
{code:"mas", name:"Isioma Masai"},
{code:"is", name:"Islandês"},
{code:"is-IS", name:"Islandês (Islândia)"},
{code:"it", name:"Italiano"},
{code:"it-IT", name:"Italiano (Itália)"},
{code:"it-CH", name:"Italiano (Suíça)"},
{code:"ja", name:"Japonês"},
{code:"ja-JP", name:"Japonês (Japão)"},
{code:"chn", name:"Jargão Chinook"},
{code:"jav", name:"Javanês"},
{code:"jrb", name:"Judeu-Arábico"},
{code:"jpr", name:"Judeu-Persa"},
{code:"kn", name:"Kannada"},
{code:"kn-IN", name:"Kannada (Índia)"},
{code:"kok-IN", name:"Konkani (Índia)"},
{code:"lat", name:"Latim"},
{code:"lv", name:"Letão"},
{code:"lv-LV", name:"Letônia (Letônia)"},
{code:"tl-PH", name:"Língua (Filipinas)"},
{code:"sgn", name:"Linguagens de sinal"},
{code:"lt", name:"Lituano"},
{code:"lt-LT", name:"Lituano (Lituânia)"},
{code:"ltz", name:"Luxemburguês"},
{code:"mk", name:"Macedônio"},
{code:"mad", name:"Madurês"},
{code:"ms", name:"Malaio"},
{code:"ms-BN", name:"Malaio (Brunei Darussalam)"},
{code:"ms-MY", name:"Malaio (Malásia)"},
{code:"mt", name:"Maltês"},
{code:"mt-MT", name:"Maltês (Malta)"},
{code:"mi", name:"Maori"},
{code:"mi-NZ", name:"Maori (Nova Zelândia)"},
{code:"mr", name:"Marata"},
{code:"mr-IN", name:"Marathi (Índia)"},
{code:"mwl", name:"Mirandês"},
{code:"mn", name:"Mongol"},
{code:"mn-MN", name:"Mongólia (Mongólia)"},
{code:"hi", name:"não"},
{code:"und", name:"Não determinado"},
{code:"nap", name:"Napolitano"},
{code:"nep", name:"Nepalês"},
{code:"ssa", name:"Nilo-Saariano"},
{code:"non", name:"Nórdico, antigo"},
{code:"nb", name:"Norueguês"},
{code:"nob", name:"Norueguês  Bokmål"},
{code:"nb-NO", name:"Norueguês (Noruega)"},
{code:"nn-NO", name:"Norueguês (Nynorsk) (Noruega)"},
{code:"nno", name:"Norueguês Nynorsk"},
{code:"pau", name:"Palauano"},
{code:"pap", name:"Papiamento"},
{code:"fa", name:"Para fazer"},
{code:"ps-AR", name:"Pashto (Afeganistão)"},
{code:"ps", name:"pastó"},
{code:"per", name:"Persa"},
{code:"pal", name:"Persa médio"},
{code:"peo", name:"Persa, antigo (aprox. 600-400 B.C.)"},
{code:"pl", name:"Polonês"},
{code:"pl-PL", name:"Polonês (Polônia)"},
{code:"pt-BR", name:"Português (Brasil)"},
{code:"pt-PT", name:"Português (Portugal)"},
{code:"pro", name:"Provençal, antigo (até 1500)"},
{code:"pa", name:"Punjabi"},
{code:"pa-IN", name:"Punjabi (Índia)"},
{code:"qu-BO", name:"Quechua (Bolívia)"},
{code:"qu-EC", name:"Quechua (Equador)"},
{code:"qu-PE", name:"Quechua (Peru)"},
{code:"qu", name:"Quíchua"},
{code:"ky-KG", name:"Quirguistão (Quirguistão)"},
{code:"ky", name:"Quirguiz"},
{code:"qaa-qtz", name:"Reservado para uso local"},
{code:"ro", name:"Romeno"},
{code:"ro-RO", name:"Romeno (Romênia)"},
{code:"ru", name:"Russo"},
{code:"ru-RU", name:"Russo (Rússia)"},
{code:"se", name:"Sami "},
{code:"se-FI", name:"Sami (Finlândia)"},
{code:"se-NO", name:"Sami (Noruega)"},
{code:"se-SE", name:"Sami (Suécia)"},
{code:"smo", name:"Samoano"},
{code:"sa", name:"Sânscrito"},
{code:"sa-IN", name:"Sânscrito (Índia)"},
{code:"srd", name:"Sardo"},
{code:"zxx", name:"Sem conteúdo linguístico; Não aplicável"},
{code:"scc", name:"Sérvio"},
{code:"sr-BA", name:"Sérvio (Bósnia e Herzegovina)"},
{code:"sr-SP", name:"Sérvio (Sérvia e Montenegro)"},
{code:"sot", name:"Sesoto, do sul"},
{code:"scn", name:"Siciliano"},
{code:"zbl", name:"Símbolos Bliss; Bliss"},
{code:"syr", name:"siríaco"},
{code:"syc", name:"Siríaco clássico"},
{code:"syr-SY", name:"Sírio (Síria)"},
{code:"sog", name:"Sogdiano"},
{code:"som", name:"Somali"},
{code:"snk", name:"Soninquê"},
{code:"hsb", name:"Sorbiano superior"},
{code:"ns", name:"Sotho do norte"},
{code:"ns-ZA", name:"Sotho do Norte (África do Sul)"},
{code:"sw", name:"Suaíle"},
{code:"swa", name:"Suaíli"},
{code:"sw-KE", name:"Suaíli (Quênia)"},
{code:"sv", name:"Sueco"},
{code:"sv-FI", name:"Sueco (Finlândia)"},
{code:"sv-SE", name:"Sueco (Suécia)"},
{code:"sux", name:"Sumério"},
{code:"sun", name:"Sundanês"},
{code:"srn", name:"Surinamês Tongo"},
{code:"tgk", name:"Tadjique"},
{code:"tl", name:"tagalo"},
{code:"tgl", name:"Tagalog"},
{code:"th", name:"Tailandês"},
{code:"th-TH", name:"Tailândia (Tailândia)"},
{code:"tah", name:"Taitiano"},
{code:"tam", name:"Tamil"},
{code:"ta", name:"Tâmil"},
{code:"ta-IN", name:"Tâmil (Índia)"},
{code:"tat", name:"Tartaristanês"},
{code:"tt", name:"Tártaro"},
{code:"tt-RU", name:"Tártaro (Rússia)"},
{code:"crh", name:"Tártaro crimeano; Turco crimeano"},
{code:"cs", name:"Tcheco"},
{code:"cs-CZ", name:"Tcheco (República Tcheca)"},
{code:"te", name:"Telugu"},
{code:"te-IN", name:"Telugu (Índia)"},
{code:"tib", name:"Tibetano"},
{code:"ton", name:"Tonganês"},
{code:"tog", name:"Tonganês (Nyasa)"},
{code:"ts", name:"Tsonga"},
{code:"tn", name:"Tswana"},
{code:"tn-ZA", name:"Tswana (África do Sul)"},
{code:"tr", name:"Turco"},
{code:"tr-TR", name:"Turco (Turquia)"},
{code:"ota", name:"Turco, Otomano (1500-1928)"},
{code:"tuk", name:"Turcomeno"},
{code:"tyv", name:"Tuviniano"},
{code:"uk", name:"Ucraniano"},
{code:"uk-UA", name:"Ucraniano (Ucrânia)"},
{code:"udm", name:"Udmurte"},
{code:"uga", name:"Ugarítico"},
{code:"uig", name:"Uigur"},
{code:"umb", name:"Umbundu"},
{code:"wol", name:"Uolofe"},
{code:"ur", name:"Urdu"},
{code:"ur-PK", name:"Urdu (República Islâmica do Paquistão)"},
{code:"uz", name:"Uzbeque"},
{code:"uz-UZ", name:"Uzbeque (Uzbequistão)"},
{code:"vai", name:"Vai"},
{code:"wln", name:"Valão"},
{code:"ven", name:"Venda"},
{code:"vie", name:"Vietnamês"},
{code:"vi", name:"Vietnamita"},
{code:"vi-VN", name:"Vietnamita (Vietnã)"},
{code:"vol", name:"Volapuque"},
{code:"vot", name:"Vótico"},
{code:"xh", name:"Xhosa"},
{code:"xh-ZA", name:"Xhosa (África do Sul)"},
{code:"yao", name:"Yao"},
{code:"zap", name:"Zapoteca"},
{code:"zza", name:"Zaza; Dimili; Dimli; Kirdki; Kirmanjki; Zazaki"},
{code:"zu", name:"Zulu"},
{code:"zu-ZA", name:"Zulu (África do Sul)"},
{code:"zun", name:"Zuni"},
]